import { VendiaFormApi } from 'src/components/fields/form'
import MonacoEditorField from 'src/components/fields/monaco-editor.field'
import Icon from 'src/components/icons/icon'

import { getRoleJsonSchema } from '../schema/org-schema'

interface CustomRoleBuilderProps {
  form: VendiaFormApi<any>
}

export const CustomRoleBuilder = ({ form }: CustomRoleBuilderProps) => {
  return (
    <div>
      <div className='mb-6'>
        <label htmlFor='customRoleCapabilities' className='mb-6 text-sm font-semibold'>
          Custom role builder
        </label>
        <div className='text-neutral-8 text-xs'>
          Configure all of the specific capabilities for this role. For each capability, an action is paired with one or
          more resources. For detailed information on the available actions and resource formats,{' '}
          <a href='https://www.vendia.com/docs/share/rbac' target='_blank' rel='noopener noreferrer'>
            <div className='text-link inline-flex items-center gap-1'>
              <span>please reference the documentation.</span>
              <Icon size={'xxs'} name='external' />
            </div>
          </a>
        </div>
      </div>

      <MonacoEditorField
        form={form}
        id='customRoleCapabilities'
        name='role.capabilities'
        language='json'
        validators={{
          onChange: ({ value }: any) => {
            try {
              if (typeof value === 'string') {
                JSON.parse(value)
              }
            } catch (e) {
              return 'Invalid JSON'
            }
          },
        }}
        // override default onChange which sets as string because org-role-edit.page.tsx has logic that relies on this
        onChange={(value: any) => {
          if (form.getFieldMeta('role.capabilities')?.errors.length) {
            return
          }
          form.setFieldValue('role.capabilities', JSON.parse(value))
        }}
        minHeight={600}
        options={{
          lineNumbers: 'on' as any,
        }}
        beforeMount={(monaco) => {
          monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            schemas: [
              {
                uri: 'http://vendia.com/role.json', // Not a real URL, doesn't matter
                fileMatch: ['*'], // associate with our model
                schema: getRoleJsonSchema(),
              },
            ],
          })
        }}
      />
    </div>
  )
}
