import { useMutation } from '@tanstack/react-query'
import debug from 'debug'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { isRequiredValidator } from 'src/utils/form/validation'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'
import notify from 'src/utils/notify'

import { StepId } from '../config'

const logger = debug('app:Cloudera')

type LakehouseClouderaConnectionInput = {
  jdbcUrl: string
  atlasUrl: string
  username: string
  password: string
  database: string
}

export const StepConnectCloudera: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const api = useApi()
  const connection = form.useStore((s) => s.values.source?.cloudera)
  const connectionType = form.useStore((s) => s.values.source?.connection)

  const getTablesMutation = useMutation({
    mutationFn: () => api.getClouderaTables({ connectionInput: { ...connection } }),
    onError: (error) => notify.error(`Error testing Cloudera connection: ${error}`),
    onSuccess: (response) => {
      logger('cloudera connection response', response)
      if (!response.getClouderaTables || response.errors) {
        notify.error('Error fetching Cloudera tables')
        return
      }
      form.setFieldValue('source.availableTables', response.getClouderaTables.tables as string[])
      form.setFieldValue('source.sourceRegion', 'us-east-1')
      context.goToStep(StepId.SelectTable)
    },
  })

  const name = 'source.cloudera'
  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Connect to Cloudera'
        description={'Let’s authenticate your Cloudera account'}
      />
      <ScrollableStepContent inset>
        <div className='mt-4 w-full max-w-4xl'>
          <div className='flex w-full flex-col gap-4'>
            <TextField
              label='JDBC URL'
              description='The Hive JDBC URL for your Cloudera CDP warehouse'
              name={`${name}.jdbcUrl`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === 'cloudera'),
              })}
              useNestedLabel
            />
            <TextField
              label='Atlas URL'
              description='The Atlas URL for your Cloudera CDP warehouse'
              name={`${name}.atlasUrl`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === 'cloudera'),
              })}
              useNestedLabel
            />
            <TextField
              label='Username'
              description='The username for the Cloudera account you want to connect with'
              name={`${name}.username`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === 'cloudera'),
              })}
              useNestedLabel
            />
            <TextField
              label='Password'
              description='The password for the Cloudera account you want to connect with'
              name={`${name}.password`}
              type='password'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === 'cloudera'),
              })}
              useNestedLabel
            />
            <TextField
              label='Database'
              description='The database containing the tables you want to share'
              name={`${name}.database`}
              type='text'
              form={form}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.ConnectCloudera,
                listenTo: ['source.connection'],
                validator: isRequiredValidator(null, connectionType === 'cloudera'),
              })}
              useNestedLabel
            />
          </div>
        </div>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isPristine]}>
        {([canSubmit, isPristine]) => (
          <StepButtons
            context={context}
            onPrevious={() => context.goToStep(StepId.ChooseSource)}
            isSubmitting={getTablesMutation.isPending}
            nextBlocked={getTablesMutation.isPending || !canSubmit || isPristine}
            onNext={() => getTablesMutation.mutate()}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
