import clsx from 'clsx'
import NavBar from 'src/components/navigation/nav-bar'

export type DefaultLayoutProps = {
  children: React.ReactNode
  className?: string
  showNavBar?: boolean
  showUniDropdown?: boolean
  [key: string]: any
}

const DefaultLayout = ({
  children,
  showNavBar = true,
  showUniDropdown = true,
  className,
  ...rest
}: DefaultLayoutProps) => (
  <div className={clsx(className, 'flex flex-grow flex-col')} {...rest}>
    {showNavBar && <NavBar showUniDropdown={showUniDropdown} />}
    {children}
  </div>
)

export default DefaultLayout
