import { Dialog, DialogBackdrop, Transition, TransitionChild } from '@headlessui/react'
import { useRef } from 'react'
import useOnClickOutside from 'src/utils/hooks/use-on-click-outside'

type DrawerProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  [key: string]: any
}

const Drawer = ({ open, onClose, children, ...rest }: DrawerProps) => {
  const ref = useRef<HTMLDivElement>()
  useOnClickOutside(ref, () => onClose())

  return (
    <Transition<'div'> show={open} className='transition duration-300 ease-in data-[closed]:opacity-0'>
      <Dialog as='div' style={{ zIndex: 100 }} className='fixed inset-0 overflow-hidden' onClose={onClose}>
        <div className='absolute inset-0 grid overflow-hidden'>
          <DialogBackdrop className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          <div className='fixed inset-y-0 right-0 flex max-w-full pl-10' ref={ref as any}>
            <TransitionChild<'div'> className='translate-x-0 transform transition duration-300 ease-in-out data-[closed]:translate-x-full'>
              <div className='w-screen max-w-md' {...rest}>
                <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                  <div className='relative flex-1'>
                    <div className='absolute inset-0 flex flex-col'>{children}</div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Drawer
