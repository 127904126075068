import { useQuery } from '@tanstack/react-query'
import useApi from 'src/utils/hooks/use-api'

import { JobsByDataProductKey } from './data-product-card'

export const useLakehouseJobs = ({ uniName, enabled }: { uniName: string; enabled?: boolean }) => {
  const api = useApi()
  return useQuery({
    queryKey: ['getLakehouseJobs', { uniName }],
    queryFn: async () => {
      return api.getLakehouseJobs({ uniName }) as Promise<{
        getLakehouseJobs: { jobs?: JobsByDataProductKey }
      }>
    },
    enabled,
  })
}
