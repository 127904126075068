import { Schema } from 'jsonschema'

export type SchemaPath = (string | number)[]

export type ScalarType = 'string' | 'number' | 'boolean' | 'integer'

export type ComplexType = 'object' | 'array'

export type SchemaType = ScalarType | ComplexType

export const VendiaUniqueKey = 'x-vendia-unique'

export const VendiaIndexesKey = 'x-vendia-indexes'

export const VendiaAclsKey = 'x-vendia-acls'

// Fix some issues with this Schema type...
// We don't support "Schema | Schema[]" for items
// We don't support boolean values for required
export interface JsonSchema extends Schema {
  items?: JsonSchema
  definitions?: {
    [name: string]: JsonSchema
  }
  properties?: {
    [name: string]: JsonSchema
  }
  required?: string[]
  [VendiaUniqueKey]?: string[]
}

// We ensure these always have indexes and properties
export interface VendiaSchema extends JsonSchema {
  [VendiaIndexesKey]?: Record<
    string,
    {
      type: string
      property: string
    }[]
  >
  [VendiaAclsKey]?: Record<
    string,
    {
      type: string
    }
  >
  properties: {
    [name: string]: ArrayEntitySchema | JsonSchema
  }
  type: string
}

// Extending with these properties tells TS they're not optional
export interface ArrayEntitySchema extends JsonSchema {
  items: ObjectSchema
  type: string
}

// Extending with these properties tells TS they're not optional
export interface ObjectSchema extends JsonSchema {
  properties: {
    [name: string]: JsonSchema
  }
  required: string[]
  [VendiaUniqueKey]: string[]
  type: string
}

export type Dataset = {
  [key: string]: string | number | boolean | null
}[]
