import clsx from 'clsx'
import React from 'react'

const pillKinds = ['none', 'success', 'info', 'pending', 'warning'] as const
export type PillKind = (typeof pillKinds)[number]

export type PillProps = {
  children?: React.ReactNode
  kind?: PillKind
  className?: string
  text?: string
} & React.HTMLAttributes<HTMLSpanElement>

const Pill = ({ children, className, kind = 'none', text, ...spanProps }: PillProps) => {
  return (
    <span
      {...(spanProps as any)}
      className={clsx(
        'inline-flex items-center justify-center rounded-xl px-2 py-1 text-xs font-bold',
        kind === 'none' && 'bg-neutral-2 text-neutral-8',
        kind === 'success' && 'bg-success-1 text-success-12',
        kind === 'info' && 'bg-information-1 text-information-10',
        kind === 'pending' && 'bg-warning-1 text-warning-9',
        kind === 'warning' && 'bg-error-1 text-error-13',
        className,
      )}
    >
      {text || children}
    </span>
  )
}

export const PILL_KINDS = pillKinds.reduce((a: Record<string, string>, c) => ({ ...a, [c]: c }), {})

export default Pill
