// This component is used to wrap components that should only be rendered in development mode.
// - children only render when the NODE_ENV is set to "development" (obviously).
// - children only render when localStorage.debug is set to "app:*", "app:whatever", etc.
// - If you have DevOnly tools that you only want to show up for rare use-cases, you can
//   pass a scope like <DevOnly scope="special-tools">. Then you can set localStorage.debug
//   to "special-tools" to see them.

interface DevOnlyProps {
  scope?: string
  children: JSX.Element
}

export const DevOnly = ({ children, scope = 'app:' }: DevOnlyProps) => {
  const debugScope = localStorage.getItem('debug')
  const scopeWithoutAsterisk = debugScope?.replace(/\*/g, '') ?? 'NO_DEBUG_SCOPE'
  if (!scope.startsWith(scopeWithoutAsterisk)) {
    return null
  }
  if (!import.meta.env.DEV) {
    return null
  }

  return children
}
export default DevOnly
