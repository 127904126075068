import React from 'react'

export const isClassComponent = (component: any) =>
  typeof component === 'function' && component.prototype && !!component.prototype.isReactComponent

export const isFunctionComponent = (component: any) => {
  const str = String(component)
  return typeof component === 'function' && str && str.includes('children:')
}

export const isReactComponent = (component: any) => isClassComponent(component) || isFunctionComponent(component)

export const isElement = (element: any) => React.isValidElement(element)

export const isDOMTypeElement = (element: any) => isElement(element) && typeof element.type === 'string'

export const isCompositeTypeElement = (element: any) => isElement(element) && typeof element.type === 'function'

export const smartRender = (componentOrString: any, propsToPass: any): any => {
  if (!componentOrString) return null
  // console.log('componentOrString', componentOrString)
  // console.log('String(component)', String(componentOrString))

  if (typeof componentOrString === 'string' || isElement(componentOrString)) {
    // console.log('String or react element')
    return componentOrString
  }

  if (isClassComponent(componentOrString)) {
    // console.log('is uninstantiated class component')
    const RenderComponent = componentOrString
    return <RenderComponent {...propsToPass} />
  }

  if (isFunctionComponent(componentOrString)) {
    // console.log('is uninstantiated functional component')
    return componentOrString(propsToPass)
  }

  if (Array.isArray(componentOrString)) {
    return componentOrString.map((x) => smartRender(x, propsToPass))
  }

  // Possibly precompiled react component with React.createElement inside
  if (typeof componentOrString === 'function') {
    // console.log('is uninstantiated functional component')
    return componentOrString(propsToPass)
  }

  return componentOrString
}
