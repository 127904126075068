/**
 * Automatically prefix tracking events
 */
export default function eventPrefixerPlugin(allowedPrefixes, defaultPrefix) {
  const allowed = Array.isArray(allowedPrefixes) ? allowedPrefixes : [allowedPrefixes]
  const _defaultPrefix = defaultPrefix || allowed[0]
  const patterns = allowed.map((prefix) => new RegExp(`^${prefix}`))

  return {
    name: 'event-prefixer',
    trackStart: ({ payload }) => {
      const { event } = payload
      const allowedPattern = patterns.find((pattern) => event.match(pattern))
      const eventName = allowedPattern ? event : `${_defaultPrefix}${event}`
      /* Return event with prefixed event name */
      return {
        ...payload,
        ...{
          event: eventName,
        },
      }
    },
  }
}
