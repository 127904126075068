import { useEffect, useRef } from 'react'

export const useFirstFocus = <T extends HTMLElement = HTMLInputElement>(shouldFocus = true) => {
  const inputRef = useRef<T>(null)

  useEffect(() => {
    if (shouldFocus) {
      setTimeout(() => {
        inputRef?.current?.focus()
      }, 200)
    }
  }, [])

  return inputRef
}
