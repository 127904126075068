import gql from 'graphql-tag';
export const Vendia_Block_Result_FragmentFragmentDoc = gql `
    fragment Vendia_Block_Result_Fragment on Vendia_Block_Result_ {
  error
  result {
    _id
    blockSchemaVersion
    blockId
    redactedBlockHash
    previousBlockId
    previousRedactedBlockHash
    blockHash
    previousBlockHash
    status
    commitTime
    _owner
    transactions {
      _id
      hash
      redactedHash
      signature
      version
      submissionTime
      mutations
      _owner
    }
  }
}
    `;
export const Vendia_File_Result_FragmentFragmentDoc = gql `
    fragment Vendia_File_Result_Fragment on Vendia_File_Result_ {
  error
  result {
    _id
    sourceBucket
    sourceKey
    sourceRegion
    sourceVersion
    destinationKey
    copyStrategy
    read
    write
    etag
    createdTime
    updatedTime
    temporaryUrl
    fileVersion
    _owner
  }
}
    `;
export const Vendia_Folder_Result_FragmentFragmentDoc = gql `
    fragment Vendia_Folder_Result_Fragment on Vendia_Folder_Result_ {
  error
  result {
    _id
    name
    parent
    read
    write
    copyStrategy
    createdTime
    updatedTime
    _owner
  }
}
    `;
export const _blocks__getDocument = gql `
    query _blocks__get($id: ID!) {
  getVendia_Block(id: $id) {
    _id
    blockSchemaVersion
    blockId
    redactedBlockHash
    previousBlockId
    previousRedactedBlockHash
    blockHash
    previousBlockHash
    status
    commitTime
    _owner
    transactions {
      _id
      hash
      redactedHash
      signature
      version
      submissionTime
      mutations
      _owner
    }
  }
}
    `;
export const _blocks__listDocument = gql `
    query _blocks__list($order: [Vendia_Block_OrderInput_!], $filter: Vendia_Block_FilterInput_, $limit: Int, $nextToken: String) {
  listVendia_BlockItems(
    order: $order
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    nextToken
    Vendia_BlockItems {
      _id
      blockSchemaVersion
      blockId
      redactedBlockHash
      previousBlockId
      previousRedactedBlockHash
      blockHash
      previousBlockHash
      status
      commitTime
      _owner
      transactions {
        _id
        hash
        redactedHash
        signature
        version
        submissionTime
        mutations
        _owner
      }
    }
  }
}
    `;
export const _blocks__onAddDocument = gql `
    subscription _blocks__onAdd {
  onAddVendia_Block {
    ...Vendia_Block_Result_Fragment
  }
}
    ${Vendia_Block_Result_FragmentFragmentDoc}`;
export const _contracts__getDocument = gql `
    query _contracts__get($id: ID!, $version: Int) {
  getVendia_Contract(id: $id, version: $version) {
    ... on Vendia_Contract {
      description
      revisionId
      _acl {
        path
        operations
        principal {
          nodes
        }
      }
      _id
      _owner
      inputQuery
      name
      outputMutation
      resource {
        uri
        csp
        metadata {
          name
          value
        }
      }
    }
  }
}
    `;
export const _contracts__listDocument = gql `
    query _contracts__list($limit: Int, $nextToken: String, $order: [Vendia_Contract_OrderInput_!], $filter: Vendia_Contract_FilterInput_) {
  listVendia_ContractItems(
    order: $order
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    Vendia_ContractItems {
      ... on Vendia_Contract {
        description
        revisionId
        _acl {
          path
          operations
          principal {
            nodes
          }
        }
        _id
        _owner
        inputQuery
        name
        outputMutation
        resource {
          uri
          csp
          metadata {
            name
            value
          }
        }
      }
    }
    nextToken
  }
}
    `;
export const _contracts__listVersionsDocument = gql `
    query _contracts__listVersions($id: ID!, $limit: Int, $nextToken: String) {
  listVendia_ContractVersions(id: $id, limit: $limit, nextToken: $nextToken) {
    nextToken
    versions {
      ordinal
      block
      transactions {
        _id
        _owner
        submissionTime
      }
    }
  }
}
    `;
export const _contracts__addDocument = gql `
    mutation _contracts__add($aclInput: Vendia_Acls_Input_, $input: Vendia_Contract_Input_!, $syncMode: Vendia_SyncMode = NODE_COMMITTED) {
  addVendia_Contract(aclInput: $aclInput, input: $input, syncMode: $syncMode) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      ... on Vendia_Contract {
        description
        revisionId
        _acl {
          operations
          path
          principal {
            nodes
          }
        }
        _id
        _owner
        inputQuery
        name
        outputMutation
        resource {
          uri
          csp
          metadata {
            name
            value
          }
        }
      }
    }
  }
}
    `;
export const _contracts__updateDocument = gql `
    mutation _contracts__update($id: ID!, $aclInput: Vendia_Acls_Input_, $condition: Vendia_Contract_ConditionInput_, $input: Vendia_Contract_UpdateInput_ = {}, $syncMode: Vendia_SyncMode = NODE_COMMITTED) {
  updateVendia_Contract(
    input: $input
    aclInput: $aclInput
    condition: $condition
    id: $id
    syncMode: $syncMode
  ) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      ... on Vendia_Contract {
        description
        revisionId
        _acl {
          operations
          path
          principal {
            nodes
          }
        }
        _id
        _owner
        inputQuery
        name
        outputMutation
        resource {
          uri
          csp
          metadata {
            name
            value
          }
        }
      }
    }
  }
}
    `;
export const _contracts__invokeDocument = gql `
    mutation _contracts__invoke($input: Vendia_Contract_InvokeInput_, $revisionId: String, $id: ID!) {
  invokeVendia_Contract(
    id: $id
    input: $input
    revisionId: $revisionId
    syncMode: ASYNC
  ) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
  }
}
    `;
export const _contracts__removeDocument = gql `
    mutation _contracts__remove($id: ID!, $condition: Vendia_Contract_ConditionInput_, $syncMode: Vendia_SyncMode = NODE_COMMITTED) {
  removeVendia_Contract(id: $id, condition: $condition, syncMode: $syncMode) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
  }
}
    `;
export const _deployments__getDocument = gql `
    query _deployments__get($id: ID!) {
  getVendia_DeploymentInfo(id: $id) {
    _id
    deploymentTime
    consensusDefinitionHash
    versionTag
    _owner
  }
}
    `;
export const _deployments__listDocument = gql `
    query _deployments__list($order: [Vendia_DeploymentInfo_OrderInput_!], $filter: Vendia_DeploymentInfo_FilterInput_, $nextToken: String, $limit: Int) {
  listVendia_DeploymentInfoItems(
    order: $order
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    Vendia_DeploymentInfoItems {
      _id
      deploymentTime
      consensusDefinitionHash
      versionTag
      _owner
    }
    nextToken
  }
}
    `;
export const _files__getDocument = gql `
    query _files__get($id: ID!, $version: Int) {
  getVendia_File(id: $id, version: $version) {
    _id
    sourceBucket
    sourceKey
    sourceRegion
    sourceVersion
    destinationKey
    copyStrategy
    read
    write
    etag
    createdTime
    updatedTime
    temporaryUrl
    fileVersion
    _owner
  }
}
    `;
export const _files__listDocument = gql `
    query _files__list($order: [Vendia_File_OrderInput_!], $filter: Vendia_File_FilterInput_, $limit: Int, $nextToken: String) {
  listVendia_FileItems(
    order: $order
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    nextToken
    Vendia_FileItems {
      _id
      sourceBucket
      sourceKey
      sourceRegion
      sourceVersion
      destinationKey
      copyStrategy
      read
      write
      etag
      createdTime
      updatedTime
      temporaryUrl
      fileVersion
      _owner
    }
  }
}
    `;
export const _files__listVersionsDocument = gql `
    query _files__listVersions($id: ID!, $limit: Int, $nextToken: String) {
  listVendia_FileVersions(id: $id, limit: $limit, nextToken: $nextToken) {
    nextToken
    versions {
      ordinal
      block
      transactions {
        _id
        _owner
        submissionTime
      }
    }
  }
}
    `;
export const _files__addDocument = gql `
    mutation _files__add($input: Vendia_File_Input_!) {
  addVendia_File(input: $input, syncMode: ASYNC) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      _id
      sourceBucket
      sourceKey
      sourceRegion
      sourceVersion
      destinationKey
      copyStrategy
      read
      write
      etag
      createdTime
      updatedTime
      temporaryUrl
      fileVersion
      _owner
    }
  }
}
    `;
export const _files__updateDocument = gql `
    mutation _files__update($id: ID!, $condition: Vendia_File_ConditionInput_, $input: Vendia_File_UpdateInput_!) {
  updateVendia_File(
    id: $id
    input: $input
    condition: $condition
    syncMode: ASYNC
  ) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      _id
      sourceBucket
      sourceKey
      sourceRegion
      sourceVersion
      destinationKey
      copyStrategy
      read
      write
      etag
      createdTime
      updatedTime
      temporaryUrl
      fileVersion
      _owner
    }
  }
}
    `;
export const _files__removeDocument = gql `
    mutation _files__remove($condition: Vendia_File_ConditionInput_, $id: ID!, $syncMode: Vendia_SyncMode = NODE_COMMITTED) {
  removeVendia_File(id: $id, condition: $condition, syncMode: $syncMode) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
  }
}
    `;
export const _files__onAddDocument = gql `
    subscription _files__onAdd {
  onAddVendia_File {
    ...Vendia_File_Result_Fragment
  }
}
    ${Vendia_File_Result_FragmentFragmentDoc}`;
export const _files__onRemoveDocument = gql `
    subscription _files__onRemove {
  onRemoveVendia_File {
    ...Vendia_File_Result_Fragment
  }
}
    ${Vendia_File_Result_FragmentFragmentDoc}`;
export const _files__onUpdateDocument = gql `
    subscription _files__onUpdate {
  onUpdateVendia_File {
    ...Vendia_File_Result_Fragment
  }
}
    ${Vendia_File_Result_FragmentFragmentDoc}`;
export const _fileTasks__getDocument = gql `
    query _fileTasks__get($id: ID!) {
  getVendia_FileTask(id: $id) {
    ... on Vendia_FileTask {
      _id
      fileId
      startTime
      completionTime
      action
      status
      sourceBucket
      sourceKey
      sourceRegion
      sourceVersion
      sourceUri
      destinationKey
      copyStrategy
      read
      write
      error {
        errorType
        errorDetails
      }
      _acl {
        operations
        path
        principal {
          nodes
        }
      }
      _owner
    }
  }
}
    `;
export const _fileTasks__listDocument = gql `
    query _fileTasks__list($order: [Vendia_FileTask_OrderInput_!], $filter: Vendia_FileTask_FilterInput_, $limit: Int, $nextToken: String) {
  listVendia_FileTaskItems(
    order: $order
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    Vendia_FileTaskItems {
      ... on Vendia_FileTask {
        _id
        fileId
        startTime
        completionTime
        action
        status
        sourceBucket
        sourceKey
        sourceRegion
        sourceVersion
        sourceUri
        destinationKey
        copyStrategy
        read
        write
        error {
          errorType
          errorDetails
        }
        _acl {
          operations
          path
          principal {
            nodes
          }
        }
        _owner
      }
    }
    nextToken
  }
}
    `;
export const _folders__getDocument = gql `
    query _folders__get($id: ID!, $version: Int) {
  getVendia_Folder(id: $id, version: $version) {
    _id
    name
    parent
    read
    write
    copyStrategy
    createdTime
    updatedTime
    _owner
  }
}
    `;
export const _folders__listDocument = gql `
    query _folders__list($order: [Vendia_Folder_OrderInput_!], $filter: Vendia_Folder_FilterInput_, $limit: Int, $nextToken: String) {
  listVendia_FolderItems(
    order: $order
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    Vendia_FolderItems {
      _id
      name
      parent
      read
      write
      copyStrategy
      createdTime
      updatedTime
      _owner
    }
    nextToken
  }
}
    `;
export const _folders__listVersionsDocument = gql `
    query _folders__listVersions($id: ID!, $limit: Int, $nextToken: String) {
  listVendia_FolderVersions(id: $id, limit: $limit, nextToken: $nextToken) {
    nextToken
    versions {
      ordinal
      block
      transactions {
        _id
        _owner
        submissionTime
      }
    }
  }
}
    `;
export const _folders__addDocument = gql `
    mutation _folders__add($input: Vendia_Folder_Input_!, $syncMode: Vendia_SyncMode = NODE_COMMITTED) {
  addVendia_Folder(input: $input, syncMode: $syncMode) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      _id
      name
      parent
      read
      write
      copyStrategy
      createdTime
      updatedTime
      _owner
    }
  }
}
    `;
export const _folders__removeDocument = gql `
    mutation _folders__remove($id: ID!, $condition: Vendia_Folder_ConditionInput_, $syncMode: Vendia_SyncMode = NODE_COMMITTED) {
  removeVendia_Folder(id: $id, condition: $condition, syncMode: $syncMode) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
  }
}
    `;
export const _folders__onAddDocument = gql `
    subscription _folders__onAdd {
  onAddVendia_Folder {
    ...Vendia_Folder_Result_Fragment
  }
}
    ${Vendia_Folder_Result_FragmentFragmentDoc}`;
export const _folders__onRemoveDocument = gql `
    subscription _folders__onRemove {
  onRemoveVendia_Folder {
    ...Vendia_Folder_Result_Fragment
  }
}
    ${Vendia_Folder_Result_FragmentFragmentDoc}`;
export const _folders__onUpdateDocument = gql `
    subscription _folders__onUpdate {
  onUpdateVendia_Folder {
    ...Vendia_Folder_Result_Fragment
  }
}
    ${Vendia_Folder_Result_FragmentFragmentDoc}`;
export const _settings__getDocument = gql `
    query _settings__get($version: Int) {
  getVendia_Settings(version: $version) {
    blockReportWebhooks
    blockReportEmails
    deadLetterWebhooks
    deadLetterEmails
    azure {
      defaultSubscriptionId
      defaultTenantId
    }
    resourceMapKeys
    resourceMapValues
    apiSettings {
      apiKeys {
        value
        usagePlan {
          quotaSettings {
            limit
            offset
            period
          }
          throttleSettings {
            burstLimit
            rateLimit
          }
        }
      }
      auth {
        authorizerType
        authorizerArn
        allowedAccounts
      }
    }
    _owner
    aws {
      blockReportSQSQueues
      blockReportLambdas
      blockReportFirehoses
      deadLetterSQSQueues
      deadLetterLambdas
      s3ReadAccounts
      dataDogMonitoring {
        ddExternalId
        ddApiKey
        ddLogEndpoint
        ddSendLogs
      }
    }
  }
}
    `;
export const _settings__listVersionsDocument = gql `
    query _settings__listVersions($limit: Int, $nextToken: String) {
  listVendia_SettingsVersions(limit: $limit, nextToken: $nextToken) {
    versions {
      ordinal
      block
      transactions {
        _id
        _owner
        submissionTime
      }
    }
    nextToken
  }
}
    `;
export const _settings__updateDocument = gql `
    mutation _settings__update($condition: Vendia_Settings_ConditionInput_, $input: Vendia_Settings_UpdateInput_!, $syncMode: Vendia_SyncMode = NODE_COMMITTED) {
  updateVendia_Settings(condition: $condition, input: $input, syncMode: $syncMode) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      blockReportWebhooks
      blockReportEmails
      deadLetterWebhooks
      deadLetterEmails
      azure {
        blockReportFunctions {
          resourceGroupName
          functionAppName
          functionName
        }
        blockReportQueues {
          resourceGroupName
          storageAccountName
          queueName
        }
        deadLetterFunctions {
          resourceGroupName
          functionAppName
          functionName
        }
        deadLetterQueues {
          resourceGroupName
          storageAccountName
          queueName
        }
        defaultSubscriptionId
        defaultTenantId
      }
      resourceMapKeys
      resourceMapValues
      apiSettings {
        apiKeys {
          value
          usagePlan {
            quotaSettings {
              limit
              offset
              period
            }
            throttleSettings {
              burstLimit
              rateLimit
            }
          }
        }
      }
      _owner
      aws {
        blockReportSQSQueues
        blockReportLambdas
        blockReportFirehoses
        deadLetterSQSQueues
        deadLetterLambdas
        s3ReadAccounts
        dataDogMonitoring {
          ddExternalId
          ddApiKey
          ddLogEndpoint
          ddSendLogs
        }
      }
    }
  }
}
    `;
export const _uniInfo__getDocument = gql `
    query _uniInfo__get {
  getVendia_UniInfo {
    name
    sku
    schema
    schemaNamespace
    status
    createdTime
    updatedTime
    localNodeName
    _owner
    nodes {
      name
      userId
      userEmail
      description
      status
      csp
      region
      bucketName
      temporaryCredentials {
        uploadFile {
          accessKeyId
          expiration
          secretAccessKey
          sessionToken
        }
      }
      tier {
        share
      }
      vendiaAccount {
        csp
        accountId
        userId
        org
      }
    }
  }
}
    `;
export const _sharingPolicies__getDocument = gql `
    query _sharingPolicies__get($id: ID, $name: String, $version: Int) {
  getVendia_SharingPolicy(id: $id, name: $name, version: $version) {
    ... on Vendia_SharingPolicy {
      _acl {
        operations
        path
        principal {
          nodes
        }
      }
      _id
      _owner
      acl {
        operations
        path
        principal {
          nodes
        }
      }
      name
      description
      entity
      type
    }
  }
}
    `;
export const _sharingPolicies__listDocument = gql `
    query _sharingPolicies__list($limit: Int, $nextToken: String, $order: [Vendia_SharingPolicy_OrderInput_!], $filter: Vendia_SharingPolicy_FilterInput_) {
  listVendia_SharingPolicyItems(
    order: $order
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    Vendia_SharingPolicyItems {
      ... on Vendia_SharingPolicy {
        _acl {
          operations
          path
          principal {
            nodes
          }
        }
        _id
        _owner
        acl {
          operations
          path
          principal {
            nodes
          }
        }
        name
        description
        entity
        type
      }
    }
    nextToken
  }
}
    `;
export const _sharingPolicies__listVersionsDocument = gql `
    query _sharingPolicies__listVersions($id: ID!, $limit: Int, $nextToken: String) {
  listVendia_SharingPolicyVersions(id: $id, limit: $limit, nextToken: $nextToken) {
    nextToken
    versions {
      ordinal
      block
      transactions {
        _id
        _owner
        submissionTime
      }
    }
  }
}
    `;
export const _sharingPolicies__addDocument = gql `
    mutation _sharingPolicies__add($aclInput: Vendia_Acls_Input_, $input: Vendia_SharingPolicy_Input_!, $syncMode: Vendia_SyncMode = NODE_LEDGERED) {
  addVendia_SharingPolicy(aclInput: $aclInput, input: $input, syncMode: $syncMode) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      ... on Vendia_SharingPolicy {
        _acl {
          operations
          path
          principal {
            nodes
          }
        }
        _id
        _owner
        acl {
          operations
          path
          principal {
            nodes
          }
        }
        name
        description
        entity
        type
      }
    }
  }
}
    `;
export const _sharingPolicies__updateDocument = gql `
    mutation _sharingPolicies__update($id: ID, $name: String, $aclInput: Vendia_Acls_Input_, $condition: Vendia_SharingPolicy_ConditionInput_, $input: Vendia_SharingPolicy_UpdateInput_ = {}, $syncMode: Vendia_SyncMode = NODE_LEDGERED) {
  updateVendia_SharingPolicy(
    input: $input
    aclInput: $aclInput
    condition: $condition
    id: $id
    name: $name
    syncMode: $syncMode
  ) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
    result {
      ... on Vendia_SharingPolicy {
        _acl {
          operations
          path
          principal {
            nodes
          }
        }
        _id
        _owner
        acl {
          operations
          path
          principal {
            nodes
          }
        }
        name
        description
        entity
        type
      }
    }
  }
}
    `;
export const _sharingPolicies__removeDocument = gql `
    mutation _sharingPolicies__remove($id: ID, $name: String, $condition: Vendia_SharingPolicy_ConditionInput_, $syncMode: Vendia_SyncMode = NODE_LEDGERED) {
  removeVendia_SharingPolicy(
    id: $id
    name: $name
    condition: $condition
    syncMode: $syncMode
  ) {
    transaction {
      _id
      transactionId
      version
      submissionTime
      _owner
    }
  }
}
    `;
const defaultWrapper = (action, _operationName) => action();
export function getEntities({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {};
}
export function getBlocks({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_blocks__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_Block);
            }, 'getVendia_Block');
        },
        list(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_blocks__listDocument, variables, wrappedRequestHeaders)
                    .then(res => { const updatedRes = { ...res.listVendia_BlockItems, items: res.listVendia_BlockItems?.Vendia_BlockItems }; delete updatedRes.Vendia_BlockItems; return updatedRes; });
            }, 'listVendia_BlockItems');
        },
        onAdd(handler) {
            return _subscribe({ query: _blocks__onAddDocument }, ({ data }) => { return handler(data.onAddVendia_Block); });
        }
    };
}
export function getContracts({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_contracts__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_Contract);
            }, 'getVendia_Contract');
        },
        list(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_contracts__listDocument, variables, wrappedRequestHeaders)
                    .then(res => { const updatedRes = { ...res.listVendia_ContractItems, items: res.listVendia_ContractItems?.Vendia_ContractItems }; delete updatedRes.Vendia_ContractItems; return updatedRes; });
            }, 'listVendia_ContractItems');
        },
        listVersions(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_contracts__listVersionsDocument, variables, wrappedRequestHeaders)
                    .then(res => res.listVendia_ContractVersions);
            }, 'listVendia_ContractVersions');
        },
        add(input, options) {
            const variables = { ...options, input };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_contracts__addDocument, variables, wrappedRequestHeaders)
                    .then(res => res.addVendia_Contract);
            }, 'addVendia_Contract');
        },
        update(input, options) {
            const { _id, ...rest } = input;
            const variables = { ...options, id: _id, input: rest };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_contracts__updateDocument, variables, wrappedRequestHeaders)
                    .then(res => res.updateVendia_Contract);
            }, 'updateVendia_Contract');
        },
        invoke(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_contracts__invokeDocument, variables, wrappedRequestHeaders)
                    .then(res => res.invokeVendia_Contract);
            }, 'invokeVendia_Contract');
        },
        remove(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_contracts__removeDocument, variables, wrappedRequestHeaders)
                    .then(res => res.removeVendia_Contract);
            }, 'removeVendia_Contract');
        }
    };
}
export function getFiles({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_files__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_File);
            }, 'getVendia_File');
        },
        list(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_files__listDocument, variables, wrappedRequestHeaders)
                    .then(res => { const updatedRes = { ...res.listVendia_FileItems, items: res.listVendia_FileItems?.Vendia_FileItems }; delete updatedRes.Vendia_FileItems; return updatedRes; });
            }, 'listVendia_FileItems');
        },
        listVersions(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_files__listVersionsDocument, variables, wrappedRequestHeaders)
                    .then(res => res.listVendia_FileVersions);
            }, 'listVendia_FileVersions');
        },
        add(input) {
            const variables = { input };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_files__addDocument, variables, wrappedRequestHeaders)
                    .then(res => res.addVendia_File);
            }, 'addVendia_File');
        },
        update(input, options) {
            const { _id, ...rest } = input;
            const variables = { ...options, id: _id, input: rest };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_files__updateDocument, variables, wrappedRequestHeaders)
                    .then(res => res.updateVendia_File);
            }, 'updateVendia_File');
        },
        remove(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_files__removeDocument, variables, wrappedRequestHeaders)
                    .then(res => res.removeVendia_File);
            }, 'removeVendia_File');
        },
        onAdd(handler) {
            return _subscribe({ query: _files__onAddDocument }, ({ data }) => { return handler(data.onAddVendia_File); });
        },
        onRemove(handler) {
            return _subscribe({ query: _files__onRemoveDocument }, ({ data }) => { return handler(data.onRemoveVendia_File); });
        },
        onUpdate(handler) {
            return _subscribe({ query: _files__onUpdateDocument }, ({ data }) => { return handler(data.onUpdateVendia_File); });
        }
    };
}
export function getFileTasks({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_fileTasks__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_FileTask);
            }, 'getVendia_FileTask');
        },
        list(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_fileTasks__listDocument, variables, wrappedRequestHeaders)
                    .then(res => { const updatedRes = { ...res.listVendia_FileTaskItems, items: res.listVendia_FileTaskItems?.Vendia_FileTaskItems }; delete updatedRes.Vendia_FileTaskItems; return updatedRes; });
            }, 'listVendia_FileTaskItems');
        }
    };
}
export function getFolders({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_folders__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_Folder);
            }, 'getVendia_Folder');
        },
        list(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_folders__listDocument, variables, wrappedRequestHeaders)
                    .then(res => { const updatedRes = { ...res.listVendia_FolderItems, items: res.listVendia_FolderItems?.Vendia_FolderItems }; delete updatedRes.Vendia_FolderItems; return updatedRes; });
            }, 'listVendia_FolderItems');
        },
        listVersions(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_folders__listVersionsDocument, variables, wrappedRequestHeaders)
                    .then(res => res.listVendia_FolderVersions);
            }, 'listVendia_FolderVersions');
        },
        add(input, options) {
            const variables = { ...options, input };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_folders__addDocument, variables, wrappedRequestHeaders)
                    .then(res => res.addVendia_Folder);
            }, 'addVendia_Folder');
        },
        remove(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_folders__removeDocument, variables, wrappedRequestHeaders)
                    .then(res => res.removeVendia_Folder);
            }, 'removeVendia_Folder');
        },
        onAdd(handler) {
            return _subscribe({ query: _folders__onAddDocument }, ({ data }) => { return handler(data.onAddVendia_Folder); });
        },
        onRemove(handler) {
            return _subscribe({ query: _folders__onRemoveDocument }, ({ data }) => { return handler(data.onRemoveVendia_Folder); });
        },
        onUpdate(handler) {
            return _subscribe({ query: _folders__onUpdateDocument }, ({ data }) => { return handler(data.onUpdateVendia_Folder); });
        }
    };
}
export function getDeployments({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_deployments__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_DeploymentInfo);
            }, 'getVendia_DeploymentInfo');
        },
        list(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_deployments__listDocument, variables, wrappedRequestHeaders)
                    .then(res => { const updatedRes = { ...res.listVendia_DeploymentInfoItems, items: res.listVendia_DeploymentInfoItems?.Vendia_DeploymentInfoItems }; delete updatedRes.Vendia_DeploymentInfoItems; return updatedRes; });
            }, 'listVendia_DeploymentInfoItems');
        }
    };
}
export function getSettings({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_settings__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_Settings);
            }, 'getVendia_Settings');
        },
        listVersions(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_settings__listVersionsDocument, variables, wrappedRequestHeaders)
                    .then(res => res.listVendia_SettingsVersions);
            }, 'listVendia_SettingsVersions');
        },
        update(input, options) {
            const variables = { ...options, input };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_settings__updateDocument, variables, wrappedRequestHeaders)
                    .then(res => res.updateVendia_Settings);
            }, 'updateVendia_Settings');
        }
    };
}
export function getUniInfo({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get() {
            const variables = {};
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_uniInfo__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_UniInfo);
            }, 'getVendia_UniInfo');
        }
    };
}
export function getSharingPolicies({ _gqlClient, _subscribe, _requestWrapper = defaultWrapper }) {
    return {
        get(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_sharingPolicies__getDocument, variables, wrappedRequestHeaders)
                    .then(res => res.getVendia_SharingPolicy);
            }, 'getVendia_SharingPolicy');
        },
        list(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_sharingPolicies__listDocument, variables, wrappedRequestHeaders)
                    .then(res => { const updatedRes = { ...res.listVendia_SharingPolicyItems, items: res.listVendia_SharingPolicyItems?.Vendia_SharingPolicyItems }; delete updatedRes.Vendia_SharingPolicyItems; return updatedRes; });
            }, 'listVendia_SharingPolicyItems');
        },
        listVersions(options) {
            const variables = options;
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_sharingPolicies__listVersionsDocument, variables, wrappedRequestHeaders)
                    .then(res => res.listVendia_SharingPolicyVersions);
            }, 'listVendia_SharingPolicyVersions');
        },
        add(input, options) {
            const variables = { ...options, input };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_sharingPolicies__addDocument, variables, wrappedRequestHeaders)
                    .then(res => res.addVendia_SharingPolicy);
            }, 'addVendia_SharingPolicy');
        },
        update(input, options) {
            const { _id, ...rest } = input;
            const variables = { ...options, id: _id, input: rest };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_sharingPolicies__updateDocument, variables, wrappedRequestHeaders)
                    .then(res => res.updateVendia_SharingPolicy);
            }, 'updateVendia_SharingPolicy');
        },
        remove(idOrOptions, options) {
            const variables = typeof idOrOptions === 'string' ? { ...options, id: idOrOptions } : { ...options, ...idOrOptions };
            return _requestWrapper((wrappedRequestHeaders) => {
                return _gqlClient.request(_sharingPolicies__removeDocument, variables, wrappedRequestHeaders)
                    .then(res => res.removeVendia_SharingPolicy);
            }, 'removeVendia_SharingPolicy');
        }
    };
}
