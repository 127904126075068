import { QueryClient, useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { useRecoilValue } from 'recoil'

import { authContext } from '../auth/auth-context'
import { selectedRoleState } from '../state'
import useApi from './use-api'

const QUERY_KEY = 'getOrgDetails'
const FIVE_MINUTES = 300000

export type GetOrgDetails = NonNullable<ReturnType<typeof useGetOrgDetails>['data']>['getOrganization']

export const useGetOrgDetails = ({ ...options } = {}) => {
  const { auth } = useContext(authContext)
  const selectedRoleName = useRecoilValue(selectedRoleState).name

  const api = useApi()
  return useQuery({
    queryKey: [QUERY_KEY, selectedRoleName],
    queryFn: async () => {
      const data = await api.getUserOrgDetails()
      if (data.errors) {
        throw new Error(JSON.stringify(data.errors))
      }
      return data
    },
    staleTime: FIVE_MINUTES,
    enabled: auth.authenticated && !!selectedRoleName,
    ...options,
  })
}

export const refetchOrgDetails = ({ queryClient }: { queryClient: QueryClient }) =>
  queryClient.invalidateQueries({ queryKey: [QUERY_KEY], refetchType: 'inactive' })
