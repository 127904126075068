import { DeepKeys, DeepValue, FieldApi, Validator } from '@tanstack/react-form'
import { useMemo } from 'react'
import { FlowModel } from 'src/components/flows/types'
import RadioGroupInput from 'src/components/inputs/radio-group.input'
import { useGetOrg } from 'src/utils/hooks/use-get-org'

import { logger } from '../org-invite'
import { getRoleTemplates } from './role-templates'

type RoleTemplateRadioGroupProps<TFormData, TName, TFieldValidator, TFormValidator, TData> = {
  name: string
  field: FieldApi<TFormData, DeepKeys<TFormData>>
  label: string | JSX.Element
  labelDescription?: string | JSX.Element
}

export function RoleTemplateRadioGroup<
  TParentData,
  TName extends DeepKeys<TParentData & FlowModel<TParentData>>,
  TFieldValidator extends
    | Validator<DeepValue<TParentData & FlowModel<TParentData>, TName>, unknown>
    | undefined = undefined,
  TFormValidator extends Validator<TParentData & FlowModel<TParentData>, unknown> | undefined = undefined,
  TData extends DeepValue<TParentData & FlowModel<TParentData>, TName> = DeepValue<
    TParentData & FlowModel<TParentData>,
    TName
  >,
>({
  name,
  field,
  label,
  labelDescription,
}: RoleTemplateRadioGroupProps<TParentData, TName, TFieldValidator, TFormValidator, TData>) {
  const getOrg = useGetOrg()
  const org = getOrg?.data?.getOrganization

  const { errors, isDirty, isBlurred } = field.state.meta
  const hasErrors = errors?.length > 0 && (isDirty || isBlurred)

  // Get all the role templates
  const roleTemplates = useMemo(() => {
    if (!org?.orgId || !org?.domains) return []
    const templates = getRoleTemplates({ orgId: org?.orgId, orgDomains: org?.domains })
    logger('roleTemplates', templates)
    return templates
  }, [org?.orgId, org?.domains])

  // Map the role templates to the options for the radio group
  const roleOptions = roleTemplates.map((t) => ({
    value: t.name,
    label: t.name,
    description: t.description,
  }))

  return (
    <RadioGroupInput
      name={name}
      label={label}
      description={labelDescription}
      value={field.state.value as string}
      hasErrors={hasErrors}
      options={roleOptions}
      onChange={field.handleChange}
      dividerClassName='mx-0'
    />
  )
}
