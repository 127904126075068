import { Combobox, ComboboxOption, ComboboxOptions, Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { ForwardedRef, forwardRef } from 'react'
import Icon from 'src/components/icons/icon'

import { FormInputProps } from '../fields/types'
import { ListOption } from './listbox.input'

const logger = debug('app:MenuListboxInput')

export type MenuListboxInputProps<ValueType = string> = FormInputProps & {
  options: ListOption<ValueType>[]
  optionsClassName?: string
  onChange?: (value: ValueType) => void
  onClose?: () => void
  value?: ValueType
  icon?: string
}

function MenuListboxInput<ValueType = string>(
  {
    name,
    options,
    optionsClassName,
    onChange,
    onClose,
    value,
    icon = 'sidenav-settings-gear',
  }: MenuListboxInputProps<ValueType>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <div data-testid={`${name}-menu-listbox-input`}>
      <Popover className='relative inline-block text-left'>
        <PopoverButton
          ref={ref}
          id={`${name}-menu-listbox-input-button`}
          className='flex !h-8 !w-8 items-center justify-center rounded-md border border-[#00568f]'
        >
          <Icon name={icon} size={20} color='#00568f' />
        </PopoverButton>
        <PopoverPanel
          transition
          className='z-20 transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0'
        >
          {({ close }) => (
            <Combobox
              as='div'
              onChange={(value: ValueType) => {
                onChange?.(value)
                close()
              }}
              onClose={onClose}
            >
              <div className='border-neutral-4 absolute -right-0 z-20 mt-2 min-w-96 origin-top-right rounded-md border bg-white shadow-2xl'>
                <ComboboxOptions static>
                  {options.map((option, index) => (
                    <MenuListboxOption<ValueType>
                      key={JSON.stringify(option.value)}
                      option={option}
                      value={value}
                      className={optionsClassName}
                      index={index}
                    />
                  ))}
                </ComboboxOptions>
              </div>
            </Combobox>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  )
}

function MenuListboxOption<T>({
  index,
  option,
  value,
  className,
}: {
  index: number
  option: ListOption<T>
  value?: T
  className?: string
}) {
  return (
    <ComboboxOption
      value={option.value}
      className={clsx(
        className,
        'cursor-pointer items-center text-sm font-bold',
        'data-[focus]:bg-information-1 border-neutral-4 rounded-b-md',
        index > 0 && 'border-t',
      )}
    >
      <div className='flex justify-between gap-6 p-4'>
        <div className='flex flex-col gap-1'>
          <span className='block truncate text-sm'>{option.label}</span>
          <span className='text-neutral-8 block text-xs'>{option.description}</span>
        </div>
        {value === option.value ? (
          <span className='text-information-9 flex items-center pr-4'>
            <Icon name='success' size={18} aria-hidden='true' />
          </span>
        ) : null}
      </div>
    </ComboboxOption>
  )
}

export default forwardRef<HTMLButtonElement, MenuListboxInputProps<any>>(MenuListboxInput)
