interface OrgLogoBadgeProps {
  src: string
  alt?: string
  size?: number
}
export default function OrgLogoBadge({ src, size = 72, alt = 'Organization logo' }: OrgLogoBadgeProps) {
  return (
    <div
      className={`grid place-items-center overflow-hidden rounded-full border border-purple-100`}
      style={{ width: size, height: size }}
    >
      <img src={src} alt={alt} className='object-contain' />
    </div>
  )
}
