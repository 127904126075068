import { useQuery } from '@tanstack/react-query'
import { ShareApp, ShareAppStatus, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useMemo, useRef, useState } from 'react'
import Button from 'src/components/buttons/button'
import Card, { CardRule } from 'src/components/containers/card'
import { ContentWindow } from 'src/components/containers/content-window'
import ConnectionBanner from 'src/components/messages/connection-banner'
import PageHeader from 'src/components/page-header'
import StatusPill from 'src/components/pills/status-pill'
import { SOURCE_NODE_NAME } from 'src/pages/uni-create/config'
import { parseLakehouseShareAppConfig } from 'src/pages/uni-create/utils'
import { ShareAppName } from 'src/types/types'
import { assert } from 'src/utils/assert'
import useApi from 'src/utils/hooks/use-api'
import { useGetShareApps } from 'src/utils/hooks/use-get-share-apps'
import { useInterval } from 'src/utils/hooks/use-interval'

import { DataProductCard } from './data-product-card'
import { LakehouseDataLoader } from './lakehouse-loader'
import { ShareDataProductModal } from './share-data-product-modal'
import { useLakehouseJobs } from './use-lakehouse-jobs'

dayjs.extend(relativeTime)

enum Status {
  Idle,
  ShareModalOpen,
}

export const PageLakehouseOverview = () => <LakehouseDataLoader Comp={PageLakehouseOverviewContent} />

const PageLakehouseOverviewContent = ({ uni, shareApps }: { uni: Uni; shareApps: ShareApp[] }) => {
  const api = useApi()
  const [selectedProductToShare, setSelectedProductToShare] = useState<string | null>(null)

  // While deploying, poll for updates to we can refresh the UI
  const { refetch: refetchShareApps } = useGetShareApps({ uniName: uni.name })
  const anyShareAppsDeploying = shareApps.some((shareApp) =>
    [ShareAppStatus.Pending, ShareAppStatus.Deploying].includes(shareApp.shareAppStatus),
  )

  const lakehouseJobsQuery = useLakehouseJobs({ uniName: uni.name, enabled: !anyShareAppsDeploying })

  useInterval(() => {
    if (anyShareAppsDeploying) {
      refetchShareApps()
    }
  }, 30 * 1000)

  // Refetch lakehouse jobs when share apps are done deploying or they'll continue to display "loading..."
  const prevAnyShareAppsDeploying = useRef(anyShareAppsDeploying)
  useEffect(() => {
    if (prevAnyShareAppsDeploying.current && !anyShareAppsDeploying) {
      lakehouseJobsQuery.refetch()
    }
    prevAnyShareAppsDeploying.current = anyShareAppsDeploying
  }, [anyShareAppsDeploying])

  const uniAlias = uni.alias
  const ingestionShareApp = shareApps.find(
    (shareApp) =>
      shareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion ||
      shareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion,
  )
  assert(ingestionShareApp, 'Lakehouse share app not found')
  const shareAppConfig = useMemo(
    () => parseLakehouseShareAppConfig(ingestionShareApp.shareAppConfig),
    [ingestionShareApp.shareAppConfig],
  )

  // Single source for now & single table for now!
  const sourceConfig = shareAppConfig.sources[0]
  const sourceTableName = sourceConfig.tableDefinitions[0].tableName
  const sourceType = shareAppConfig.sources[0]?.type // SNOWFLAKE or CLOUDERA

  const sourceNode = uni.nodes?.find((node) => node.name === SOURCE_NODE_NAME)
  const sourceNodeRegion = sourceNode?.region
  const totalRegions = new Set(uni.nodes?.map((node) => node.region)).size

  const isLakehouseReady = uni.status === 'RUNNING' && !anyShareAppsDeploying

  const getSnowflakeConnectionStatus = useQuery({
    queryKey: ['getSnowflakeConnectionStatus', uni?.name, sourceNode?.name],
    queryFn: async () => {
      const response = await api.getSnowflakeConnectionStatus({
        uniName: uni?.name,
        nodeName: sourceNode?.name!,
      })
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      if (!response.getSnowflakeConnectionStatus) {
        throw new Error('Connection is not valid')
      }

      return response.getSnowflakeConnectionStatus
    },
    enabled: isLakehouseReady && sourceType === 'SNOWFLAKE', // Prevent firing off before the secret is created and for other sources
    staleTime: 1000 * 60 * 15, // 15 minutes
  })

  // TODO: Turn this into isConnectionValid when we support checking and updating other source connections
  const isSnowflakeConnectionValid =
    sourceType === 'SNOWFLAKE' && getSnowflakeConnectionStatus?.data === 'Snowflake connection is valid'

  const [status, setStatus] = useState(Status.Idle)

  const openShareDataProductModal = (index: number) => {
    setSelectedProductToShare(shareAppConfig.products[index].key)
    setStatus(Status.ShareModalOpen)
  }

  const closeShareDataProductModal = () => {
    setSelectedProductToShare(null)
    setStatus(Status.Idle)
  }

  let statusPill =
    uni.status && uni.status !== 'RUNNING' ? <StatusPill status={uni.status} data-testid='uni-status' /> : null

  if (!statusPill && anyShareAppsDeploying) {
    statusPill = <StatusPill status={ShareAppStatus.Deploying} data-testid='uni-status' />
  }
  return (
    <ContentWindow>
      {sourceType === 'SNOWFLAKE' &&
        isLakehouseReady &&
        !getSnowflakeConnectionStatus.isLoading &&
        !isSnowflakeConnectionValid && <ConnectionBanner className='m-6 mb-0' uni={uni} />}
      <div className='flex flex-grow flex-col gap-4'>
        <PageHeader
          title={uniAlias!}
          titleAfter={statusPill}
          actions={
            <>
              <Button
                kind='secondary'
                icon='plus-m'
                iconSize={14}
                to='../edit-products'
                disabled={!isLakehouseReady}
                className='mr-2'
              >
                Add data product
              </Button>
              <Button
                kind='secondary'
                icon='sidenav-settings-gear'
                iconSize={14}
                to='../distro-settings'
                disabled={!isLakehouseReady}
              >
                Settings
              </Button>
            </>
          }
          testid='uni-name-header'
        />
        <div className='flex gap-6 p-6'>
          <div className='w-1/3'>
            <Card className='w-full'>
              <div className='flex flex-col gap-4'>
                <div className='flex justify-between pb-2 text-sm font-bold'>
                  <div>Source</div>
                  <div>{sourceNodeRegion}</div>
                </div>
                <CardRule />
                <div className='flex flex-col gap-2 pb-2'>
                  <div className='flex flex-col gap-4'>
                    <div className='flex items-center justify-between'>
                      <SectionHeading>Data source</SectionHeading>
                    </div>
                    {ingestionShareApp.shareAppName === ShareAppName.LakehouseSnowflakeIngestion && (
                      <div className='flex items-center gap-4'>
                        <img src='/images/vendor/snowflake.png' alt='Snowflake logo' className='size-10' />
                        <div className='flex flex-col gap-0.5'>
                          <div className='font-bold'>Snowflake</div>
                          <div className='text-neutral-8 text-xs'>{sourceTableName}</div>
                        </div>
                      </div>
                    )}
                    {ingestionShareApp.shareAppName === ShareAppName.LakehouseClouderaIngestion && (
                      <div className='flex items-center gap-4'>
                        <img src='/images/vendor/cloudera.png' alt='Cloudera logo' className='size-10' />
                        <div className='flex flex-col gap-0.5'>
                          <div className='font-bold'>Cloudera</div>
                          <div className='text-neutral-8 text-xs'>{sourceTableName}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className='border-neutral-2 my-4 border' />
                  <div className='flex flex-col gap-4'>
                    <SectionHeading>Total data products</SectionHeading>
                    <BigMetric>{shareAppConfig.products.length}</BigMetric>
                  </div>
                  <hr className='border-neutral-2 my-4 border' />
                  <div className='flex flex-col gap-4'>
                    <SectionHeading>Total regions</SectionHeading>
                    <BigMetric>{totalRegions}</BigMetric>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className='flex w-2/3 flex-col gap-4'>
            {shareAppConfig.products.map((product, index) => (
              <DataProductCard
                key={product.key}
                uni={uni}
                product={product}
                productIndex={index}
                setShareDataProduct={openShareDataProductModal}
                lakehouseJobsQueryResult={lakehouseJobsQuery}
                isLakehouseReady={isLakehouseReady}
              />
            ))}
          </div>
        </div>
      </div>
      <ShareDataProductModal
        uni={uni}
        isOpen={status === Status.ShareModalOpen}
        onClose={closeShareDataProductModal}
        defaultProductKey={selectedProductToShare}
        shareAppConfig={shareAppConfig}
      />
    </ContentWindow>
  )
}

const SectionHeading = ({ children }: { children: React.ReactNode }) => (
  <h5 className='text-neutral-9 text-xs font-bold'>{children}</h5>
)

const BigMetric = ({ children }: { children: React.ReactNode }) => <div className='text-2xl font-bold'>{children}</div>
