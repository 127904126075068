export default function clearAuth() {
  Object.keys(localStorage)
    .filter((key) => {
      return (
        ['UserAuth', 'lastAccessedProduct', 'uniAccessOrder', 'api-details'].includes(key) ||
        key.startsWith('CognitoIdentity') ||
        key.startsWith('aws.cognito') ||
        key.startsWith('amplify-') ||
        key.startsWith('graphiql:') ||
        key.startsWith('recentlyUploadedFiles') ||
        key.startsWith('recentlyCreatedFolders')
      )
    })
    .forEach((key) => {
      window.localStorage.removeItem(key)
    })
}
