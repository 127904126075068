import clsx from 'clsx'

import MonacoEditor, { MonacoEditorProps } from './monaco-editor'

export type MonacoEditorInputProps = MonacoEditorProps & {
  /**
   * The default language to use for Monaco (eg. json, graphql, javascript)
   */
  language: string
  className?: string
  id?: string
  minHeight?: number
  options?: any
  hasErrors?: boolean
  value?: string
}

/**
 * A react-hook-form connected component for MonacoEditor
 */
export const MonacoEditorInput = ({ language, className, value, hasErrors, ...rest }: MonacoEditorInputProps) => (
  <MonacoEditor
    {...rest}
    className={clsx(
      // NOTE: Used to have max width here to prevent resize bug, but seems ok now...
      'rounded-md border p-1 py-6',
      hasErrors && 'border-error-8 focus:border-error-5 focus:ring-error-5',
      !hasErrors && 'border-black focus:border-gray-500 focus:ring-gray-500',
      className,
    )}
    src={value}
    language={language}
    readOnly={false}
  />
)

export default MonacoEditorInput
