import { DataPolicyColumnEffect } from '@vendia/management-api-types'
import debug from 'debug'
import Card, { CardRule } from 'src/components/containers/card'
import MenuListboxField from 'src/components/fields/menu-listbox.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { EditColumnPolicies } from 'src/components/lakehouse/edit-column-policies'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'

const logger = debug('app:sharingPermissions')

export const StepCreateColumnSharingPermissions: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form

  const { product, flowSteps, currStepIndex, currStep } = form.useStore((state) => state.values)
  const buttonRef = useFirstFocus<HTMLButtonElement>()

  logger('product', {
    product,
    flowSteps,
    currStepIndex,
    currStep,
  })

  return (
    <StepWrapper>
      <StepContentHeader
        centered
        large
        title='Column sharing permissions'
        description='Configure fine grained policies to share data securely'
      />
      <ScrollableStepContent inset className='pt-4'>
        <Card className='mx-2 h-full w-full max-w-7xl rounded-md'>
          <div className='flex w-full flex-col gap-4'>
            <div className='stretch flex w-full justify-between space-x-2'>
              <div>
                <div className='w-full text-lg font-bold'>Column permissions</div>
                <div className='text-neutral-9 w-full text-xs'>
                  Column permissions can be configured when sharing data products externally with your partners or
                  internal departments to ensure compliance as per data privacy laws.
                </div>
              </div>
              <div className='flex justify-end self-center'>
                <MenuListboxField
                  name='product.defaultColumnPolicyEffect'
                  options={[
                    {
                      label: 'All columns are included by default',
                      description: 'You can exclude or mask specific columns by adding policies.',
                      value: DataPolicyColumnEffect.Allow,
                    },
                    {
                      label: 'All columns are excluded by default',
                      description: 'You must provide policies to add columns to your data product.',
                      value: DataPolicyColumnEffect.Exclude,
                    },
                  ]}
                  form={form}
                />
              </div>
            </div>
            <CardRule className='-mx-10' />
            <EditColumnPolicies context={context} policiesForm={context.form} fieldPath='product' />
          </div>
        </Card>
      </ScrollableStepContent>
      <form.Subscribe selector={(state) => [state.canSubmit, state.isPristine]}>
        {([canSubmit, isPristine]) => (
          <StepButtons context={context} nextButtonRef={buttonRef} nextBlocked={!canSubmit || isPristine} />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
