import { useContext } from 'react'
import Button from 'src/components/buttons/button'
import Pill from 'src/components/pills/pill'
import { ArrayEntitySchema, JsonSchema, SchemaPath } from 'src/types/schema'
import { assert } from 'src/utils/assert'
import notify from 'src/utils/notify'

import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'
import { getExistingIndexKey, schemaDefToPillText } from './utils'

/*
  Warning, there are a lot of hardcoded heights and widths in this file. Doing a few notable things:
  - hardcoding height of list item so we can absolute position the remove button (HTML, no nested buttons in buttons)
  - creating the little vertical/horizontal lines to indicate nesting
  - adding small white divs to cover up the bottom portion of the line so we don't have to do anything fancy to
    figure out how tall it needs to be, just cover up ~1/2 of the last thing in the nested list

  Making any changes to the height of list items or add buttons will require updating the hardcoded heights/widths!
  Make sure you check both the way things look in the designer and schema tab (readOnlyMode without add buttons)
*/

export function ListItem({
  field,
  fieldKey,
  fieldParentPath,
  isRequired,
  isUnique,
}: {
  field: JsonSchema
  fieldKey: string
  fieldParentPath: SchemaPath
  isRequired: boolean
  isUnique?: boolean
}) {
  const { setDesignerState, removeField, schema, selectedEntityKey, readOnlyMode, evolveSchemaMode } = useContext(
    SchemaDesignerContext,
  ) as SchemaDesignerContextType
  assert(schema, 'schema must be defined')
  const fieldTitle = field?.title || fieldKey
  const isNestedField = fieldParentPath.length > 3
  const isNestedObject = field?.type === 'object'
  const isNestedArrayObjects = field?.type === 'array' && (field as ArrayEntitySchema).items.type === 'object'
  const hasNestedObjectProperties = isNestedObject && Object.entries(field?.properties ?? {}).length > 0
  const hasNestedArrayItemObjectObjectProperties =
    isNestedArrayObjects && Object.entries((field as ArrayEntitySchema)?.items?.properties ?? {}).length > 0

  let showNestingLines = false
  if (readOnlyMode) {
    showNestingLines = hasNestedObjectProperties || hasNestedArrayItemObjectObjectProperties
  } else {
    showNestingLines = isNestedObject || isNestedArrayObjects
  }
  return (
    <div>
      <div className='bg-white'>
        {/* Using border-t to create horizontal line for nested fields */}
        {isNestedField && <div className='border-information-5 w-8 -translate-x-8 translate-y-10 border-t' />}
        <button
          disabled={readOnlyMode}
          onClick={(e) => {
            e.preventDefault()
            setDesignerState({
              status: Status.SHOW_FIELD_MODAL_EDIT,
              selectedFieldParentPath: fieldParentPath,
              selectedFieldKey: fieldKey,
            })
          }}
          /* Hardcode height so we can absolute position the delete button to look like its inside this button */
          className={`${
            readOnlyMode ? '' : 'hover:bg-neutral-0'
          } h-18 flex w-full items-center justify-between rounded-md border border-gray-500 px-6 py-3 transition-colors`}
        >
          <div className='flex flex-col items-start gap-2'>
            <div>{fieldTitle}</div>
            <div className='flex gap-2'>
              <Pill kind='info' className='text-xs'>
                {schemaDefToPillText(field)}
              </Pill>
              {isRequired && (
                <Pill kind='info' className='text-xs'>
                  Required
                </Pill>
              )}
              {isUnique && (
                <Pill kind='info' className='text-xs'>
                  Unique identifier
                </Pill>
              )}
              {getExistingIndexKey({ schema, entityKey: selectedEntityKey, fieldKey }) && (
                <Pill kind='info' className='text-xs'>
                  Indexed
                </Pill>
              )}
            </div>
          </div>
        </button>
        <div className='relative h-0'>
          {/* HTML outlaws buttons inside buttons; absolute position this so we can continue using actual <buttons> */}
          {readOnlyMode || evolveSchemaMode ? null : (
            <Button
              kind='link'
              icon='trash'
              iconSize={18}
              className='absolute -top-[58px] right-4 !p-2 transition-colors hover:!text-red-700'
              data-testid='delete-field-button'
              onClick={(e) => {
                e.stopPropagation()
                removeField(fieldKey, fieldParentPath)
                notify.success(`The attribute "${fieldTitle}" was removed.`)
              }}
            />
          )}
        </div>
      </div>
      {showNestingLines && (
        <div
          /* Using border-l to create vertical line indicating nested fields */
          /* This ternary is a little confusing, but need to adjust spacing when the only nested child is the add button (vs when there's nested attributes too) */
          className={`border-information-5 ml-4 border-l ${
            hasNestedObjectProperties || hasNestedArrayItemObjectObjectProperties ? 'pt-4' : ''
          }`}
        >
          {/* NESTED OBJECT LIST ITEMS */}
          {isNestedObject && (
            <div className='relative'>
              {hasNestedObjectProperties && (
                <div className='flex flex-col gap-4 pl-8'>
                  {Object.entries(field?.properties ?? {}).map(([childFieldKey, childField], i) => {
                    const childIsRequired = Array.isArray(field.required)
                      ? field.required.includes(childFieldKey)
                      : Boolean(field.required)
                    const childFieldParentPath = [...fieldParentPath, 'properties', fieldKey]
                    const isLastItem = i === Object.entries(field?.properties ?? {}).length - 1
                    return (
                      <div key={childFieldKey} className='relative overflow-y-clip'>
                        {/* white box covers bottom portion of vertical line from parent - only needed for last
                              item in readOnlyMode, otherwise handled in "add attribute to x" button. this one is
                              more complex because child list items can have their own children which extend the height
                              of the container so we can't just make this box relative to the bottom of the container going up
                              a fixed height. Instead, it's relative to the top, pushed down a fixed amount, has a height of 100%
                              which would be too long, but we use overflow-y-clip on parent div to mask the extra portion
                          */}
                        {readOnlyMode && isLastItem && <div className='absolute -left-9 top-10 h-full w-2 bg-white' />}
                        <ListItem
                          field={childField}
                          fieldKey={childFieldKey}
                          fieldParentPath={childFieldParentPath}
                          isRequired={childIsRequired}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
              {readOnlyMode ? null : (
                <div className='relative'>
                  {/* white box covers bottom portion of vertical line from parent */}
                  <div className='absolute -left-1 bottom-0 h-[18px] w-2 bg-white' />
                  {/* horizontal line */}
                  <div className='border-information-5 w-8 translate-y-10 border-t' />
                  <Button
                    className='ml-8 mt-4'
                    kind='secondary'
                    onClick={() => {
                      setDesignerState({
                        status: Status.SHOW_FIELD_MODAL_NEW,
                        selectedFieldParentPath: [...fieldParentPath, 'properties', fieldKey],
                      })
                    }}
                    icon='plus-m'
                    iconSize={14}
                  >
                    Add attribute to {fieldTitle}
                  </Button>
                </div>
              )}
            </div>
          )}
          {/* NESTED ARRAY OF OBJECTS LIST ITEMS */}
          {isNestedArrayObjects && (
            <div className='relative'>
              {hasNestedArrayItemObjectObjectProperties && (
                <div className='flex flex-col gap-4 pl-8'>
                  {Object.entries((field as ArrayEntitySchema)?.items?.properties ?? {}).map(
                    ([childFieldKey, childField], i) => {
                      const childIsRequired = Array.isArray(field?.items?.required)
                        ? field.items.required.includes(childFieldKey)
                        : Boolean(field?.items?.required)
                      const childFieldParentPath = [...fieldParentPath, 'properties', fieldKey, 'items']
                      const isLastItem =
                        i === Object.entries((field as ArrayEntitySchema)?.items?.properties ?? {}).length - 1
                      return (
                        <div key={childFieldKey} className='relative overflow-y-clip'>
                          {/* white box covers bottom portion of vertical line from parent - only needed for last
                              item in readOnlyMode, otherwise handled in "add attribute to x" button. this one is
                              more complex because child list items can have their own children which extend the height
                              of the container so we can't just make this box relative to the bottom of the container going up
                              a fixed height. Instead, it's relative to the top, pushed down a fixed amount, has a height of 100%
                              which would be too long, but we use overflow-y-clip on parent div to mask the extra portion
                         */}
                          {readOnlyMode && isLastItem && (
                            <div className='absolute -left-9 top-10 h-full w-2 bg-white' />
                          )}
                          <ListItem
                            field={childField}
                            fieldKey={childFieldKey}
                            fieldParentPath={childFieldParentPath}
                            isRequired={childIsRequired}
                          />
                        </div>
                      )
                    },
                  )}
                </div>
              )}
              {readOnlyMode ? null : (
                <div className='relative'>
                  {/* white box covers bottom portion of vertical line from parent */}
                  <div className='absolute -left-1 bottom-0 h-5 w-2 bg-white' />
                  {/* horizontal line */}
                  <div className='border-information-5 w-8 translate-y-10 border-t' />
                  <Button
                    className='ml-8 mt-4'
                    kind='secondary'
                    onClick={() => {
                      setDesignerState({
                        status: Status.SHOW_FIELD_MODAL_NEW,
                        selectedFieldParentPath: [...fieldParentPath, 'properties', fieldKey, 'items'],
                      })
                    }}
                    icon='plus-m'
                    iconSize={14}
                  >
                    Add attribute to {fieldTitle}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
