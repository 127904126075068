import { ActionEnum } from '@vendia/management-api-types'

export const getRoleJsonSchema = () => ({
  type: 'array',
  items: {
    type: 'object',
    properties: {
      action: {
        type: 'string',
        enum: Object.values(ActionEnum),
      },
      resources: {
        type: 'array',
        items: {
          type: 'string',
          pattern: '^(OrganizationResource|NameResource|UniResource|VendiaResource)\\(.+\\)$',
        },
      },
    },
    required: ['action', 'resources'],
  },
})
