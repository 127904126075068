// @ts-strict-ignore
import FocusTrap from 'focus-trap-react'
import { MouseEvent, useState } from 'react'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import Icon from 'src/components/icons/icon'
import { smartRender } from 'src/utils/react'

import styles from './tooltip.module.css'

export type TooltipProps = {
  /** Tooltip contents */
  children: any
  size?: number
  className?: string
  content?: any
  focusTrap?: boolean
}

const Tooltip = ({ children, className, focusTrap, size }: TooltipProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  if (!children) {
    throw new Error('Tooltip component must have children')
  }

  function close() {
    setIsPopoverOpen(false)
  }
  function open() {
    setIsPopoverOpen(true)
  }
  function toggle() {
    setIsPopoverOpen(!isPopoverOpen)
  }

  const innerContent = smartRender(children, {
    actions: {
      open,
      close,
      toggle,
    },
  })

  let tooltipContent = (
    <div className={styles.tooltip} onMouseLeave={close}>
      {innerContent}
    </div>
  )

  if (focusTrap) {
    tooltipContent = <FocusTrap paused={false}>{tooltipContent}</FocusTrap>
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['right', 'bottom']}
      onClickOutside={() => setIsPopoverOpen(false)}
      // reposition={true}
      content={({ position, childRect, popoverRect }) => {
        // console.log('Show it', position)
        return (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowSize={10}
            arrowColor='white'
          >
            {tooltipContent}
          </ArrowContainer>
        )
      }}
    >
      <button
        tabIndex={0}
        className={`${styles.tooltipIcon} ${className}`}
        onFocus={open}
        onMouseEnter={open}
        onClick={(e) => {
          e.preventDefault()
          if (isPopoverOpen) return
          open()
        }}
        onMouseLeave={(evt: MouseEvent<HTMLButtonElement>) => {
          const direction = getDirection(evt, evt.currentTarget)
          // console.log('xyz')
          // If mouseLeave into popover keep it open
          if (direction === 'right') {
            return
          }
          // Else close it
          close()
        }}
      >
        <Icon name='question' size={size ?? 12} />
      </button>
    </Popover>
  )
}

const DIRECTION = {
  0: 'top',
  1: 'right',
  2: 'bottom',
  3: 'left',
}

// Get direction data based on element and pointer positions
function getDirection(e: MouseEvent, item: HTMLElement) {
  // Width and height of current item
  const w = item.offsetWidth
  const h = item.offsetHeight
  const position = getPosition(item)
  // Calculate the x/y value of the pointer entering/exiting, relative to the center of the item.
  const x = (e.pageX - position.x - w / 2) * (w > h ? h / w : 1)
  const y = (e.pageY - position.y - h / 2) * (h > w ? w / h : 1)
  // Calculate the angle the pointer entered/exited
  // & convert to clockwise format (top/right/bottom/left = 0/1/2/3)
  // - https://stackoverflow.com/a/3647634
  const d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4
  // console.table([x, y, w, h, e.pageX, e.pageY, item.offsetLeft, item.offsetTop, position.x, position.y])
  return DIRECTION[d as keyof typeof DIRECTION]
}

// Gets an elements position - https://www.kirupa.com/html5/get_element_position_using_javascript.htm
function getPosition(el) {
  let xPos = 0
  let yPos = 0

  while (el) {
    xPos += el.offsetLeft + el.clientLeft
    yPos += el.offsetTop + el.clientTop
    el = el.offsetParent
  }
  return {
    x: xPos,
    y: yPos,
  }
}

export default Tooltip
