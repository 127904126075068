// @ts-strict-ignore
import debug from 'debug'
import gql from 'graphql-tag'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useNodeApi } from 'src/utils/api-provider'
import { pipe, subscribe } from 'wonka'

import { getIdArgumentFromGraphqlMutationString, getMutationKeysFromGraphqlString } from './graphql-utils'

const logger = debug('app:pendingTransactions')

declare global {
  interface Window {
    Cypress: boolean
  }
}

// Websocket connection isn't 100% reliable, so we'll fallback to resolving transactions after some time
const MAX_TIME_TO_WAIT_FOR_TX = 30 * 1000

export const status = {
  RESOLVED: 'resolved',
  RESOLVED_WITH_ERROR: 'resolvedWithError',
  RESOLVED_WITH_TIMEOUT: 'resolvedWithTimeout',
  PENDING: 'pending',
}

type AddPendingTxFunc = (
  transactionId: string,
  displayText: string,
  onResolved?: (txId: string) => void,
  options?: { isFileTaskTxId?: boolean },
) => void

interface PendingTransactionsContextType {
  addPendingTransaction: AddPendingTxFunc
  pendingTransactions: {
    [key: string]: {
      status: string
      displayText: string
      onResolved: (txId: string) => void
      fallbackTimeoutId: any
    }
  }
}

const PendingTransactionsContext = createContext<PendingTransactionsContextType>({
  addPendingTransaction: () => {},
  pendingTransactions: {},
})

export function PendingTransactionsProvider({ children }) {
  const _usePendingTransactionsValue = _usePendingTransactions()
  return (
    <PendingTransactionsContext.Provider value={_usePendingTransactionsValue}>
      {children}
    </PendingTransactionsContext.Provider>
  )
}

export function usePendingTransactions() {
  return useContext(PendingTransactionsContext)
}

const blocksQuery = gql`
  subscription onBlockChanges {
    onAddVendia_Block {
      error
      result {
        status
        transactions {
          _id
          mutations
        }
      }
    }
  }
`

const txQuery = gql`
  subscription onTransactionChanges {
    onUpdateVendia_Transaction {
      transactionId
      mutation
      status
    }
  }
`

const getFileTaskQuery = gql`
  query pollForFileTaskSuccess($id: ID = "") {
    getVendia_FileTask(id: $id) {
      ... on Vendia_FileTask {
        status
        _id
      }
    }
  }
`

// TODO - some way to resolve transactionIds that are added and, for some reason, we
//  never get a websocket event for them.  Fallback to querying recent blocks via listVendia_BlockItems?
//  For now, we just resolve them after 10 seconds no matter what.
const _usePendingTransactions = () => {
  const activeBlockSub = useRef(null)
  const activeTxSub = useRef(null)
  const [pendingTransactions, setPendingTransactions] = useState({})
  const { websocketApiUrl: apiWebSocket, apiKey, urqlClient } = useNodeApi()
  let fallbackTimeoutId
  let removeResolvedTransactionsTimeoutId

  // const returnType = operation.selectionSet.selections[0].type;

  async function getFileTask(id: string) {
    return urqlClient.query(getFileTaskQuery, { id }).toPromise()
  }

  // For some reason, our socket connection never works inside Cypress's electron browser. I've tried a bunch of weird
  // things, seems to be related to Cypress's proxy stuff. For now, just resolve the pending txs display quickly when
  // testing in Cypress
  let WAIT_TIME = MAX_TIME_TO_WAIT_FOR_TX
  if (window.Cypress) {
    WAIT_TIME = 15 * 1000 // 15 seconds
  }

  const addPendingTransaction: AddPendingTxFunc = (
    transactionId,
    displayText,
    onResolved,
    { isFileTaskTxId = false } = {},
  ) => {
    // For now, this will resolve any transactions that are orphaned due to
    // an unstable websocket connection. TODO - something better!
    fallbackTimeoutId = setTimeout(() => {
      logger(`Resolving via ${WAIT_TIME} second timeout`)
      resolveTransactions([{ _id: transactionId, setResolvedStatusTo: status.RESOLVED_WITH_TIMEOUT }])
    }, WAIT_TIME)

    setPendingTransactions((prevTransactions) => {
      return {
        ...prevTransactions,
        [transactionId]: { status: status.PENDING, displayText, onResolved, fallbackTimeoutId, isFileTaskTxId },
      }
    })
  }

  // Either real txs or fake "fallback" txs we pass in to resolve pending txs after a timeout
  type Transactions = {
    _id: string
    mutations?: string[]
    setResolvedStatusTo?: string
  }[]

  // Called with txs from every block added while subbed, if we see a tx we're waiting on, resolve it
  const resolveTransactions = (blockTransactions: Transactions = []) => {
    setPendingTransactions((_pendingTransactions) => {
      // Usually just one transaction, but we can have multiple - check if any have an error
      // We'll do more in the UI with this but for now just show the error notification longer
      const hasAnyErrors = blockTransactions?.find((tx) => tx.setResolvedStatusTo === status.RESOLVED_WITH_ERROR)

      // First set unresolved transactions to resolved
      blockTransactions?.forEach((tx) => {
        const pendingTx = _pendingTransactions[tx._id]
        if (pendingTx?.status === status.PENDING) {
          // This is all specific to files, supports off chain file changes

          // First, we check the mutationKey to see if it's an addFileTask tx
          // TODO: this is only needed as a pseudo-feature-flag until all unis use off chain file updates
          // After that, first mutation from tx id should _always_ be addVendia_FileTask
          const firstMutation = tx.mutations?.[0]
          const mutationKey = firstMutation ? getMutationKeysFromGraphqlString(firstMutation)?.[0] : undefined
          logger(`mutationKey: ${mutationKey}`)

          if (mutationKey === 'addVendia_FileTask' && pendingTx.isFileTaskTxId && !tx.setResolvedStatusTo) {
            logger(`Resolving FileTask tx ${tx._id} via websocket event`)
            // If it's a FileTask tx, we can grab the 0 index mutation, parse out the id argument, add that to the pendingTx
            // Then we poll for the FileTask status and resolve the tx when it's done
            const fileTaskId = firstMutation ? getIdArgumentFromGraphqlMutationString(firstMutation) : undefined
            if (fileTaskId) {
              const pollIntervalTimeoutId = setInterval(async () => {
                const fileTask = await getFileTask(fileTaskId)
                logger(`FileTask ${fileTaskId} status: ${fileTask?.data?.getVendia_FileTask?.status}`)
                if (fileTask?.data?.getVendia_FileTask?.status === 'SUCCESS') {
                  logger(`FileTask ${fileTaskId} success!`)
                  pendingTx.status = status.RESOLVED
                  pendingTx?.onResolved?.(tx._id)
                  // Clear the fallback because we resolved via a websocket event
                  clearTimeout(pendingTx.fallbackTimeoutId)
                  clearInterval(pollIntervalTimeoutId)
                  // Make sure we remove these 4 seconds later - the old setTimeout that does this in
                  // the section below will often run _before_ this polling routine complete successfully
                  setTimeout(() => {
                    logger(`Backup: deleting FileTask tx ${tx._id} from pendingTransactions`)
                    setPendingTransactions((_pendingTransactions) => {
                      delete _pendingTransactions[tx._id]
                      return _pendingTransactions
                    })
                  }, 4 * 1000)
                }
              }, 1000)
              // Stop polling after 30 seconds
              setTimeout(() => {
                clearInterval(pollIntervalTimeoutId)
              }, 30 * 1000)
            } else {
              logger(`FileTask tx ${tx._id} didn't have a fileTaskId in the mutation string: ${firstMutation}`)
            }
            // End FileTask specific stuff
            return
          }

          // Set to RESOLVED unless we've instructed ourselves to set to timeout/error/etc
          const pendingTxStatus = tx.setResolvedStatusTo ?? status.RESOLVED
          pendingTx.status = pendingTxStatus
          pendingTx?.onResolved?.(tx._id)
          // Clear the fallback because we resolved via a websocket event
          clearTimeout(pendingTx.fallbackTimeoutId)
          logger(tx._id, 'resolved with status', pendingTxStatus)
        }
      })

      // Then remove resolved transactions after a delay (so we can show the "resolved" status in the UI for a bit)
      removeResolvedTransactionsTimeoutId = setTimeout(
        () =>
          setPendingTransactions((_pendingTransactions) => {
            blockTransactions?.forEach((tx) => {
              if (
                _pendingTransactions[tx._id]?.status === status.RESOLVED ||
                _pendingTransactions[tx._id]?.status === status.RESOLVED_WITH_ERROR ||
                _pendingTransactions[tx._id]?.status === status.RESOLVED_WITH_TIMEOUT
              ) {
                logger(`Deleting FileTask tx ${tx._id} from pendingTransactions`)
                delete _pendingTransactions[tx._id]
              }
            })
            return { ..._pendingTransactions }
          }),
        // Let errors hang out in the UI for a bit longer
        hasAnyErrors ? 10 * 1000 : 4 * 1000,
      )
      return { ..._pendingTransactions }
    })
  }

  useEffect(() => {
    if (!apiWebSocket || !apiKey || activeBlockSub.current || !urqlClient) {
      return
    }

    async function createSubscription() {
      logger('Creating subscriptions...')

      let blockSubscription
      let txSubscription
      try {
        blockSubscription = pipe<any, any>(
          urqlClient.subscription(blocksQuery, {}),
          subscribe((result) => {
            const transactions = result?.data?.onAddVendia_Block?.result?.transactions
            logger('blockSubscription results:', transactions)
            resolveTransactions(transactions)
          }),
        )
        logger('Blocks subscription created!', blockSubscription)
        activeBlockSub.current = blockSubscription

        // TODO - bring this back when onUpdateVendia_Transaction subscription is fixed - see comment on https://github.com/vendia/vendia/issues/2173
        // txSubscription = pipe(
        //   client.subscription(txQuery),
        //   subscribe((result) => {
        //     // For now, this query only returns FAILED transactions
        //     // We'll mark them with setResolvedStatusTo:status.RESOLVED_WITH_ERROR and show error state in UI
        //     const failedTransaction = result?.data?.onUpdateVendia_Transaction
        //     logger('failedTransaction:', failedTransaction)
        //     const formattedFailedTransaction = {
        //       _id: failedTransaction?.transactionId,
        //       status: failedTransaction?.status,
        //       setResolvedStatusTo: status.RESOLVED_WITH_ERROR,
        //     }
        //     resolveTransactions([formattedFailedTransaction])
        //   }),
        // )
        // logger('Transactions Subscription created!', txSubscription)
        activeTxSub.current = txSubscription
      } catch (err) {
        if (err instanceof CloseEvent) {
          const reason = err.reason ? `: ${err.reason}` : ''
          console.error(new Error(`Socket closed with event ${err.code}${reason}`))
        } else {
          console.error(err)
        }
        activeBlockSub.current = null
        activeTxSub.current = null
      }
    }

    createSubscription()
    return () => {
      logger('unsubscribing')
      activeBlockSub?.current?.unsubscribe()
      activeTxSub?.current?.unsubscribe()
      clearTimeout(fallbackTimeoutId)
      clearTimeout(removeResolvedTransactionsTimeoutId)
    }
  }, [apiWebSocket, apiKey, urqlClient])

  return { addPendingTransaction, pendingTransactions }
}
