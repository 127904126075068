import { useForm } from '@tanstack/react-form'
import { useEffect, useState } from 'react'
import { useLocalstorageState } from 'rooks'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import { ContentWindow } from 'src/components/containers/content-window'
import Form from 'src/components/fields/form'
import MonacoEditorField from 'src/components/fields/monaco-editor.field'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'
import notify from 'src/utils/notify'

// A super simple internal-only page for allowing more fine-grained control over toggling various features on/off
// Has it's own DEV_SETTINGS feature-toggle that gates access to this page from the top-right dropdown menu
// Geared towards technical users, but enabled for any vendia.com email address so SAs can use it, etc.

// TODO: would be cool to do a bit more validation on initial app load and form submission. Thought about trying zod, but
//  the typechecking doesn't work correctly unless the entire project is set to strict mode (or at least strictNullChecks)

interface DevSettings {
  entitlementsUniFlowEnabled: boolean
  entityExplorerNodeLogos: Record<string, string>
  revopsDemoUnis: string[]
  shareAppVersion?: string
}

// Defaults will be set if there's nothing in localstorage
const defaultSettings: DevSettings = {
  // List of fully qualified uni names or "*" for all unis
  entitlementsUniFlowEnabled: false,
  entityExplorerNodeLogos: {},
  revopsDemoUnis: [],
  shareAppVersion: undefined,
}

// Displayed on page as example settings
const exampleSettings: DevSettings = {
  entitlementsUniFlowEnabled: true,
  entityExplorerNodeLogos: {
    'my-test-uni/NodeOne': 'https://investor.vanguard.com/corporate-portal/vanguard.svg',
  },
  revopsDemoUnis: [],
  shareAppVersion: '2.9.1-jake.2',
}

// Use this hook to check the user's current dev settings. I imagine this would typically be used
// in conjunction with the useFeatureToggle hook to gate access to something, eg:
//    if (toggle.isEnabled && devSettings.revopsDemoUnis.includes(uniName)) {
export const useDevSettings = () => {
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const userId = getCurrentVendiaUserQuery.data?.getUser?.userId
  // This starts off with the wrong values if loaded before userId is available
  const [devSettings, setDevSettings] = useLocalstorageState(`dev-settings:${userId}`, defaultSettings)
  const [initialLoad, setInitialLoad] = useState(false)

  // Add convenience methods to returned object if helpful
  const isRevOpsEnabledUni = (uniName: string) => {
    return devSettings?.revopsDemoUnis.includes(uniName) || devSettings.revopsDemoUnis.includes('*')
  }

  // Get settings from local storage after userId is available
  useEffect(() => {
    if (userId) {
      const storedSettings = localStorage.getItem(`dev-settings:${userId}`)
      if (storedSettings) {
        const settings = JSON.parse(storedSettings)
        // If any keys are missing, fill them in with defaults
        const mergedSettings = { ...defaultSettings, ...settings }
        setDevSettings(mergedSettings)
      } else {
        setDevSettings(defaultSettings)
      }
      setInitialLoad(true)
    }
  }, [userId])

  // // Don't return anything until we've loaded the settings
  if (!initialLoad) {
    return { isLoading: true, devSettings, setDevSettings, isRevOpsEnabledUni }
  }

  return {
    isLoading: false,
    devSettings,
    setDevSettings,
    isRevOpsEnabledUni,
  }
}

interface DevSettingsFormValues {
  'settings-editor': string
}

export default function DevSettings() {
  useDocumentTitle(`Developer Settings - ${POSTFIX}`)
  const { isLoading, devSettings, setDevSettings } = useDevSettings()
  const form = useForm<DevSettingsFormValues>({
    defaultValues: {
      'settings-editor': JSON.stringify(devSettings, null, 2),
    },
    onSubmit: async ({ value }) => {
      const settingsStr = value['settings-editor']
      const settings = JSON.parse(settingsStr) as DevSettings
      setDevSettings(settings)
      notify.success('Settings saved!')
    },
  })
  // Reset once devSettings are properly loaded
  useEffect(() => {
    if (!isLoading) {
      form.setFieldValue('settings-editor', JSON.stringify(devSettings, null, 2))
    }
  }, [isLoading, devSettings])

  if (isLoading) {
    return null
  }
  return (
    <ContentWindow>
      <div className='bg-uibg-2 flex flex-grow flex-col p-6'>
        <Form form={form}>
          <Card
            className=''
            title='Configure development settings'
            subTitle='For internal use only. Saved to local storage, keyed by user ID.'
            actions={
              <div>
                <Button type='submit'>Save settings</Button>
              </div>
            }
          >
            <div className='mb-4'>
              <h3 className='text-uifg-2 mb-2 text-sm font-bold'>Example settings:</h3>
              <pre className='text-uifg-2 text-xs'>{JSON.stringify(exampleSettings, null, 2)}</pre>
            </div>
            <MonacoEditorField
              form={form}
              name='settings-editor'
              language='json'
              minHeight={400}
              options={{
                lineNumbers: 'on' as any,
              }}
              validators={{
                onBlur: ({ value }: any) => {
                  try {
                    JSON.parse(JSON.stringify(value))
                  } catch (e) {
                    return 'Invalid JSON'
                  }
                },
              }}
            />
          </Card>
        </Form>
      </div>
    </ContentWindow>
  )
}
