import {
  DeepKeys,
  DeepValue,
  FieldApi,
  FieldValidateAsyncFn,
  FieldValidateFn,
  FieldValidators,
  Validator,
} from '@tanstack/react-form'

export type FieldValidatorProps<
  TParentData,
  TName extends DeepKeys<TParentData>,
  TFieldValidator extends Validator<DeepValue<TParentData, TName>, unknown> | undefined = undefined,
  TFormValidator extends Validator<TParentData, unknown> | undefined = undefined,
  TData extends DeepValue<TParentData, TName> = DeepValue<TParentData, TName>,
> = {
  validator?: FieldValidateFn<TParentData, DeepKeys<TParentData>, TFieldValidator, TFormValidator, TData>
  asyncValidator?: FieldValidateFn<TParentData, DeepKeys<TParentData>, TFieldValidator, TFormValidator, TData>
  listenTo?: DeepKeys<TParentData>[]
  ignoreEvents?: ('blur' | 'change' | 'submit' | 'mount')[]
}

export const createFieldValidator = <
  TParentData,
  TName extends DeepKeys<TParentData>,
  TFieldValidator extends Validator<DeepValue<TParentData, TName>, unknown> | undefined = undefined,
  TFormValidator extends Validator<TParentData, unknown> | undefined = undefined,
  TData extends DeepValue<TParentData, TName> = DeepValue<TParentData, TName>,
>(
  props: FieldValidatorProps<TParentData, TName, TFieldValidator, TFormValidator, TData>,
  options?: {
    shouldValidate?: (fieldApi?: FieldApi<TParentData, TName, TFieldValidator, TFormValidator, TData>) => boolean
  },
) => {
  type FieldEventValidator = FieldValidateFn<TParentData, TName, TFieldValidator, TFormValidator, TData>
  type FieldEventAsyncValidator = FieldValidateAsyncFn<TParentData, TName, TFieldValidator, TFormValidator, TData>

  const fieldValidator = {} as FieldValidators<TParentData, TName, TFieldValidator, TFormValidator, TData>
  const { validator, asyncValidator, listenTo, ignoreEvents } = props
  const shouldValidate = options?.shouldValidate

  if (!ignoreEvents?.includes('blur')) {
    fieldValidator.onBlurListenTo = listenTo
    fieldValidator.onBlurAsyncDebounceMs = asyncValidator ? 500 : undefined

    if (validator) {
      // @ts-expect-error // complicated nested types
      fieldValidator.onBlur = ((props: any) => {
        if (!shouldValidate || shouldValidate(props.fieldApi)) {
          return validator(props)
        }
      }) as FieldEventValidator
    }

    if (asyncValidator) {
      // @ts-expect-error // complicated nested types
      fieldValidator.onBlurAsync = ((props: any) => {
        if (!shouldValidate || shouldValidate(props.fieldApi)) {
          return asyncValidator(props)
        }
      }) as FieldEventAsyncValidator
    }
  }

  if (!ignoreEvents?.includes('change')) {
    fieldValidator.onChangeListenTo = listenTo

    // For onChange, only run the validator if the field is dirty so that
    // we don't show errors on fields that haven't been interacted with yet
    if (validator) {
      // @ts-expect-error // complicated nested types
      fieldValidator.onChange = ((props: any) => {
        if (!shouldValidate || shouldValidate(props.fieldApi)) {
          return validator(props)
        }
      }) as FieldEventValidator
    }

    if (asyncValidator) {
      // @ts-expect-error // complicated nested types
      fieldValidator.onChangeAsync = ((props: any) => {
        if (!shouldValidate || shouldValidate(props.fieldApi)) {
          return asyncValidator(props)
        }
      }) as FieldEventAsyncValidator

      fieldValidator.onChangeAsyncDebounceMs = 500
    }
  }

  if (!ignoreEvents?.includes('submit')) {
    // @ts-expect-error // complicated nested types
    fieldValidator.onSubmit = validator as FieldEventValidator

    // @ts-expect-error // complicated nested types
    fieldValidator.onSubmitAsync = asyncValidator as FieldEventAsyncValidator
  }

  return fieldValidator
}
