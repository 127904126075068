// @ts-strict-ignore
import { getNameResourceFromString } from '@vendia/management-api-rbac'
import { ActionEnum, Capability } from '@vendia/management-api-types'
import { NameResourceRepresentation, RoleTemplate, VendiaResourceRepresentation } from 'src/types/types'

export const DEFAULT_ROLE_TEMPLATE_NAME = 'Default Access'
export const CUSTOM_ROLE_TEMPLATE_NAME = 'Custom (advanced)'

export function nameResourceFromEmail({ email }: { email: string }): NameResourceRepresentation {
  // Returns an empty NameResource to allow building permissions previews for inviting new members
  if (!email) return `NameResource()` as NameResourceRepresentation
  const parts = getNameResourceFromString(email)
  return `NameResource(${parts.name}@${parts.subdomain}.${parts.domain}.${parts.ext})`
}

export function getRoleTemplates({ orgId, orgDomains }: { orgId: string; orgDomains: string[] }): RoleTemplate[] {
  return [
    // We show the "Default" permissions so admins can see what it looks like, though
    // currently the source of truth for this is on the server.
    // TODO: Would be better to allow orgs to configure the default permission set and then return it with the getOrg call.
    {
      name: DEFAULT_ROLE_TEMPLATE_NAME,
      description: `Default permissions for new users don't include any Org-wide capabilities. Users will be able to create and modify their own Unis, but won't be able to see Unis belonging to other Org members.`,
      capabilities: getDefaultRoleCapabilities({ orgId, orgDomains }),
    },
    {
      name: 'Uni Developer (read-only)',
      description: `In addition to the default permissions, gives the user the ability to view all Unis and Nodes in the Org.`,
      capabilities: getUniReadOnlyCapabilities({ orgId, orgDomains }),
    },
    {
      name: 'Uni Developer',
      description: `In addition to the default permissions, gives the user the ability to view all Unis and Nodes in the Org, update Uni settings, perform schema evolution, and write data to Nodes.`,
      capabilities: getUniDeveloperCapabilities({ orgId, orgDomains }),
    },
    {
      name: 'Uni Admin',
      description: `Full permissions to administer all Unis in the org. Can view/update/delete all Unis in the Org. Does not include any additional permissions for inviting/viewing/updating users.`,
      capabilities: getUniAdminCapabilities({ orgId, orgDomains }),
    },
    /*
    Not including User admin for now because current permission boundary enforcement code requires someone to essentially have
    UNI_ALL set to NameResource(*@*.customdomain.com) in order to be able to set NameResources for any UNI_ permissions. If they
    have that then they aren't that different from a Uni admin. Can revisit later.
    */
    // {
    //   name: 'User admin',
    //   description: `Full permissions to administer all users in the org. Can invite new users to the Org and update all users' permissions. Does not include any additional permissions for viewing/updating Unis.`,
    //   capabilities: getUserAdminCapabilities({ orgId, orgDomains, email }),
    // },
    {
      name: 'Org Admin',
      description: `Full permissions to administer all users and Unis in the org. Can invite new users to the Org and update all users' permissions. Can view and update/delete all Unis in the Org.`,
      capabilities: getOrgAdminCapabilities({ orgId, orgDomains }),
    },
    {
      name: 'Restricted Access',
      description: `An extremely limited set of permissions. Users retain read-only access to Unis they've created.`,
      capabilities: getRestrictedAccessCapabilities({ orgId }),
    },
    {
      name: CUSTOM_ROLE_TEMPLATE_NAME,
      description: `Customize the role with a text editor`,
    },
  ]
}

function getRestrictedAccessCapabilities({ orgId }) {
  return [...getOrgCapabilities({ orgId, isRestricted: true }), ...getGetOwnedUnisOnly()]
}

function getDefaultRoleCapabilities({ orgId, orgDomains }) {
  return [
    ...getOrgCapabilities({ orgId, isAdmin: false }),
    ...getUniCreateJoin({ orgDomains }),
    ...getCommonCapabilities(),
  ]
}

function getUniDeveloperCapabilities({ orgId, orgDomains }) {
  const capabilities = getDefaultRoleCapabilities({ orgId, orgDomains })
  const actionsToUpgrade = ['UNI_GET', 'UNI_QUERY', 'UNI_MUTATE', 'UNI_EVOLVE_SCHEMA']
  actionsToUpgrade.forEach((action) => {
    const capability = capabilities.find((c) => c.action === action)
    capability.resources = [...capability.resources, ...getStarUniResourcesForOrgDomains({ orgDomains })]
  })
  return capabilities.concat(getDataAllCapabilities({ orgId }))
}

function getUniReadOnlyCapabilities({ orgId, orgDomains }) {
  const capabilities = getDefaultRoleCapabilities({ orgId, orgDomains })
  const uniActionsToUpgrade = ['UNI_GET', 'UNI_QUERY']
  uniActionsToUpgrade.forEach((action) => {
    const capability = capabilities.find((c) => c.action === action)
    capability.resources = [...capability.resources, ...getStarUniResourcesForOrgDomains({ orgDomains })]
  })
  return capabilities.concat(getDataReadOnlyCapabilities({ orgId }))
}

function getDataReadOnlyCapabilities({ orgId }): Capability[] {
  return [
    {
      action: ActionEnum.DataRead,
      resources: [getStarVendiaResourceForOrg({ orgId })],
    },
  ]
}

function getDataAllCapabilities({ orgId }): Capability[] {
  return [
    {
      action: ActionEnum.DataAll,
      resources: [getStarVendiaResourceForOrg({ orgId })],
    },
  ]
}

function getUniAdminCapabilities({ orgId, orgDomains }) {
  return [
    ...getOrgCapabilities({ orgId, isAdmin: false }),
    ...getAdminUniAllCapabilities({ orgDomains }),
    ...getUniCreateJoin({ orgDomains }),
    ...getCommonCapabilities(),
    ...getDataAllCapabilities({ orgId }),
  ]
}

function getUserAdminCapabilities({ orgId, orgDomains }) {
  return [
    ...getOrgCapabilities({ orgId, isAdmin: false }),
    ...getUserAllCapabilities({ orgDomains }),
    ...getUniCreateJoin({ orgDomains }),
    ...getCommonCapabilities(),
  ]
}

function getOrgAdminCapabilities({ orgId, orgDomains }) {
  return [
    ...getOrgCapabilities({ orgId, isAdmin: true }),
    ...getOrgAdminRoleCapabilities({ orgId }),
    ...getUserAllCapabilities({ orgDomains }),
    ...getAdminUniAllCapabilities({ orgDomains }),
    ...getUniCreateJoin({ orgDomains }),
    ...getCommonCapabilities(),
    ...getDataAllCapabilities({ orgId }),
  ]
}

function getOrgCapabilities({ orgId, isAdmin = false, isRestricted = false }): Capability[] {
  if (isRestricted) {
    return [
      {
        action: ActionEnum.OrgGet,
        resources: [`OrganizationResource(${orgId})`],
      },
    ]
  }
  if (isAdmin) {
    return [
      {
        action: ActionEnum.OrgAll,
        resources: [`OrganizationResource(${orgId})`],
      },
    ]
  }
  return [
    {
      action: ActionEnum.OrgGet,
      resources: [`OrganizationResource(${orgId})`],
    },
    {
      action: ActionEnum.OrgListUsers,
      resources: [`OrganizationResource(${orgId})`],
    },
  ]
}

function getOrgAdminRoleCapabilities({ orgId }): Capability[] {
  return [
    {
      action: ActionEnum.RoleAll,
      resources: [`OrganizationResource(${orgId})`],
    },
  ]
}

function getStarUniResourcesForOrgDomains({ orgDomains }) {
  return orgDomains.map((d) => `UniResource(*.*.${d}#*)`)
}

function getStarNameResourcesForOrgDomains({ orgDomains }) {
  return orgDomains.map((d) => `NameResource(*@*.${d})`)
}

function getStarVendiaResourceForOrg({ orgId }): VendiaResourceRepresentation {
  return `VendiaResource(${orgId}/*)`
}

function getAdminUniAllCapabilities({ orgDomains }): Capability[] {
  return [
    {
      action: ActionEnum.UniAll,
      resources: [
        // Needed to be able to set user's UNI_ permissions to UniResource(*.*.customdomain.com#*)
        ...getStarUniResourcesForOrgDomains({ orgDomains }),
        // Needed to be able to set user's UNI_ permissions to NameResource(someuser@*.customdomain.com) without exceeding permission boundary
        // Typically used to give user's the ability to mutate their own Unis, eg UNI_RESET set to NameResource(me@*.customdomain.com)
        ...getStarNameResourcesForOrgDomains({ orgDomains }),
      ],
    },
  ]
}

function getUniCreateJoin({ orgDomains }): Capability[] {
  return [
    {
      action: ActionEnum.UniJoin,
      resources: getStarUniResourcesForOrgDomains({ orgDomains }),
    },
    {
      action: ActionEnum.UniCreate,
      resources: getStarUniResourcesForOrgDomains({ orgDomains }),
    },
  ]
}

function getUserAllCapabilities({ orgDomains }): Capability[] {
  return [
    {
      action: ActionEnum.UserAll,
      resources: getStarNameResourcesForOrgDomains({ orgDomains }),
    },
  ]
}

function getGetOwnedUnisOnly(): Capability[] {
  return [
    {
      action: ActionEnum.UniGet,
      resources: ['VendiaResource(owned)'],
    },
  ]
}

function getCommonCapabilities(): Capability[] {
  const ownedResources = 'VendiaResource(owned)'
  return [
    {
      action: ActionEnum.UniGet,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UniQuery,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UniMutate,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UniEvolveSchema,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UniInvite,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UniReset,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UniDelete,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UniDeleteNode,
      resources: [ownedResources],
    },
    {
      action: ActionEnum.UserInvite,
      resources: ['NameResource(*@*.*.*)'],
    },
  ]
}
