import { Vendia_File } from '@vendia/client'
import { createContext, Dispatch, SetStateAction } from 'react'

export type VendiaFileUpload = Vendia_File & {
  __local?: boolean
  name?: string
  progress?: number
  isFolder?: boolean
  files?: VendiaFileUpload[]
}

export type UploadFileStatusContextType = {
  uploadingFiles: VendiaFileUpload[]
  setUploadingFiles: Dispatch<SetStateAction<VendiaFileUpload[]>>
}

export const UploadFileStatusContext = createContext<UploadFileStatusContextType>({
  uploadingFiles: [],
  setUploadingFiles: () => {},
})
