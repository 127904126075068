import { useContext } from 'react'
import Button from 'src/components/buttons/button'
import notify from 'src/utils/notify'
import { validateSchema } from 'src/utils/validate-schema'

import { SchemaDesignerContext, SchemaDesignerContextType, SchemaDesignerState } from './schema-designer-context'

export const ToggleDesignerButton = ({ kind }: { kind?: string }) => {
  const { schema, setDesignerState, tempJsonEditorSchema, selectedEntityKey, showJsonEditor } =
    useContext(SchemaDesignerContext)

  return (
    <Button
      icon={showJsonEditor ? 'wand' : 'json'}
      iconSize={16}
      kind={(kind as any) ?? 'link'}
      className='!text-neutral-9 hover:!text-neutral-7 !stroke-neutral-9 ml-4 w-44'
      onClick={async () => {
        if (showJsonEditor) {
          // Try to show Designer if JSON is valid
          const [error, validSchema] = await validateSchema(tempJsonEditorSchema)
          if (error) {
            if (error?.name === 'SyntaxError') {
              return notify.error(
                `Can't switch to Designer when there are syntax errors in the JSON. Please fix any errors highlighted in red first.`,
              )
            }
            return notify.error(`Can't switch to Designer when there are problems with the JSON.<br/><br/>${error}`)
          }
          const newDesignerState: Partial<SchemaDesignerState> = {
            showJsonEditor: false,
            schema: validSchema,
          }
          // Check validSchema to see if selectedEntityKey still exists in the new schema; if not, set it to null
          const selectedEntityKeyExists = Object.keys(validSchema?.properties ?? {}).includes(
            selectedEntityKey as string,
          )
          if (!selectedEntityKeyExists) {
            newDesignerState.selectedEntityKey = null
          }
          setDesignerState(newDesignerState)
        } else {
          setDesignerState({ showJsonEditor: true, tempJsonEditorSchema: JSON.stringify(schema, null, 2) })
        }
      }}
    >
      {showJsonEditor ? `Use Designer` : `Use JSON editor`}
    </Button>
  )
}
