import { Role } from '@vendia/management-api-types'
import { useRecoilState } from 'recoil'

import { selectedRoleState } from '../state'
import useGetCurrentVendiaUserQuery from './use-current-vendia-user-query'

export const useSelectedRole = (): Role | undefined => {
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const [selectedRole] = useRecoilState(selectedRoleState)
  const user = getCurrentVendiaUserQuery?.data?.getUser
  const currentlySelectedRole = user?.roles?.find((r) => r.name === selectedRole.name)

  if (!currentlySelectedRole) {
    return user?.roles?.find((r) => r.isDefault || r.name === 'default')
  }

  return currentlySelectedRole
}
