import debug from 'debug'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import useListUnis from 'src/utils/hooks/use-list-unis'

import { CreateFlow } from '../config'

const logger = debug('app:selectUniType')

export const StepSelectUniType: StepComponent = ({ context }) => {
  const { listUnisQuery } = useListUnis()
  const isFirstUni = listUnisQuery?.data?.length === 0
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const navigate = useNavigate()

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-1 flex-col items-center justify-center p-4 lg:px-8'>
          <StepContentHeader
            centered
            large
            title={isFirstUni ? 'Create your first data sharing application' : 'Create your data sharing application'}
            description={'How do you want to share data on Vendia?'}
          />
          <div className='flex flex-col items-center gap-8 p-16 md:flex-row'>
            <button
              onClick={() => {
                setSearchParams({ flow: CreateFlow.STANDARD })
              }}
            >
              <UniTypeCard
                title='Create a data exchange network'
                description='Connect, sync, and collaborate securely across multiple systems and parties'
                imgSrc='/images/uni-type-standard.png'
                imgAlt='Multi-party data sharing illustration'
              />
            </button>
            <button
              onClick={() => {
                setSearchParams({ flow: CreateFlow.DISTRO })
              }}
            >
              <UniTypeCard
                title='Distribute data to other parties'
                description='Securely package, monetize, and deliver datasets to any receiving party or system'
                imgSrc='/images/uni-type-lakehouse.png'
                imgAlt='Entitlements data sharing illustration'
              />
            </button>
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <Button kind='secondary' className='w-28' onClick={() => navigate('/')} data-testid='cancel-button'>
          Cancel
        </Button>
      </StepButtonsWrapper>
    </StepWrapper>
  )
}

const UniTypeCard = ({
  title,
  description,
  imgSrc,
  imgAlt,
}: {
  title: string
  description: string
  imgSrc: string
  imgAlt: string
}) => {
  return (
    <Card onWhiteBg className='hover:bg-neutral-0 transition-colors'>
      <div className='flex size-72 flex-col gap-3'>
        <div className='flex h-2/3 w-full items-center justify-center'>
          <img src={imgSrc} alt={imgAlt} className='size-36' />
        </div>
        <div className='text-sm font-bold'>{title}</div>
        <div className='text-balance text-xs'>{description}</div>
      </div>
    </Card>
  )
}
