import { ForwardedRef, forwardRef } from 'react'

import MenuListboxInput, { MenuListboxInputProps } from '../inputs/menu-listbox.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, useRemoveFieldOnUnmount } from './utils'

export type MenuListboxFieldProps<ValueType = string> = Omit<MenuListboxInputProps<ValueType>, 'onChange'> &
  FormFieldProps<ValueType> & {
    onClose?: () => void
  }

function MenuListboxField<ValueType = string>(
  {
    form,
    name,
    wrapperClassName,
    validators,
    isArrayField,
    arrayFieldName,
    arrayFieldIndex,
    defaultValue,
    onChange,
    onBlur,
    onClose,
    removeFieldOnUnmount,
    ...rest
  }: MenuListboxFieldProps<ValueType>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      {...rest}
    >
      {(hasErrors) => (
        <form.Field name={name} validators={validators} defaultValue={defaultValue}>
          {(field) => (
            <MenuListboxInput
              {...rest}
              name={name}
              value={field.state.value}
              onBlur={onBlurHandler(field, onBlur)}
              onClose={onClose}
              onChange={(value: ValueType) => {
                if (onChange) onChange(value, field.state.value, field)
                field.handleChange(value)
              }}
              hasErrors={hasErrors}
              ref={ref}
            />
          )}
        </form.Field>
      )}
    </FieldSet>
  )
}

export default forwardRef<HTMLButtonElement, MenuListboxFieldProps>(MenuListboxField)
