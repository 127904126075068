import { StepId } from 'src/pages/uni-create/config'
import { isRequiredValidator } from 'src/utils/form/validation'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'

import TextField from '../fields/text.field'
import { StepComponent } from '../flows/types'
import ExternalLink from '../navigation/external-links'

export const SnowflakeConnection: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const prefix = 'source.snowflake'
  const accountRef = useFirstFocus()
  const connection = form.useStore((s) => s.values.source.connection)
  form.useField({ name: `${prefix}.authType`, defaultValue: 'PASSWORD' })

  return (
    <div className='mt-4 w-full max-w-4xl'>
      <div className='flex w-full flex-col gap-4'>
        <TextField
          ref={accountRef}
          name={`${prefix}.account`}
          label='Snowflake Account Identifier'
          description={
            <span>
              The account identifier is the first part of your Snowflake account&apos;s URL, eg.
              &lt;account-identifier&gt;.snowflakecomputing.com.{' '}
              <ExternalLink
                className=''
                iconSize='xxs'
                href='https://docs.snowflake.com/en/user-guide/admin-account-identifier'
              >
                Learn more
              </ExternalLink>
            </span>
          }
          className='!w-max flex-1 !rounded-none !border-l-0 !border-r-0'
          leftOfInput={
            <div className='border-neutral-9 bg-uibg-1 select-none truncate rounded-md rounded-r-none border p-2'>
              https://
            </div>
          }
          rightOfInput={
            <div className='border-neutral-9 bg-uibg-1 select-none truncate rounded-md rounded-l-none border p-2'>
              .snowflakecomputing.com
            </div>
          }
          placeholder='ABC123.us-east-1 or ORG123.ABC123'
          form={form}
          useNestedLabel
          validators={lakehouseFlowFieldValidator({
            stepId: StepId.ConnectSnowflake,
            listenTo: ['source.connection'],
            validator: isRequiredValidator(null, connection === 'snowflake'),
          })}
        />

        <TextField
          name={`${prefix}.warehouse`}
          label='Warehouse'
          description='The warehouse that should be used to execute queries'
          form={form}
          useNestedLabel
          validators={lakehouseFlowFieldValidator({
            stepId: StepId.ConnectSnowflake,
            listenTo: ['source.connection'],
            validator: isRequiredValidator(null, connection === 'snowflake'),
          })}
        />

        <TextField
          name={`${prefix}.database`}
          label='Database'
          description='The database containing the tables you want to share'
          form={form}
          useNestedLabel
          validators={lakehouseFlowFieldValidator({
            stepId: StepId.ConnectSnowflake,
            listenTo: ['source.connection'],
            validator: isRequiredValidator(null, connection === 'snowflake'),
          })}
        />

        <TextField
          name={`${prefix}.username`}
          label='Username'
          description='The username for the Snowflake account you want to connect with'
          form={form}
          useNestedLabel
          validators={lakehouseFlowFieldValidator({
            stepId: StepId.ConnectSnowflake,
            listenTo: ['source.connection'],
            validator: isRequiredValidator(null, connection === 'snowflake'),
          })}
        />

        <TextField
          name={`${prefix}.password`}
          label='Password'
          description='The password for the Snowflake account you want to connect with'
          form={form}
          type='password'
          useNestedLabel
          validators={lakehouseFlowFieldValidator({
            stepId: StepId.ConnectSnowflake,
            listenTo: ['source.connection'],
            validator: isRequiredValidator(null, connection === 'snowflake'),
          })}
        />

        <TextField
          name={`${prefix}.schema`}
          label='Schema'
          description='The schema where your tables are located. "PUBLIC" schema will be used by default'
          type='text'
          form={form}
          useNestedLabel
          validators={lakehouseFlowFieldValidator({
            stepId: StepId.ConnectSnowflake,
            listenTo: ['source.connection'],
            validator: isRequiredValidator(null, connection === 'snowflake'),
          })}
        />

        <TextField
          name={`${prefix}.role`}
          label='Role'
          description="The name of the role to use when connecting to Snowflake. If not provided, the user's default role will be used."
          form={form}
          useNestedLabel
          validators={lakehouseFlowFieldValidator({
            stepId: StepId.ConnectSnowflake,
            listenTo: ['source.connection'],
            validator: isRequiredValidator(null, connection === 'snowflake'),
          })}
        />
      </div>
    </div>
  )
}
