import React from 'react'

import MonacoEditorInput, { MonacoEditorInputProps } from '../inputs/monaco-editor.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { useRemoveFieldOnUnmount } from './utils'

export type MonacoEditorFieldProps = MonacoEditorInputProps & FormFieldProps

const MonacoEditorField = ({
  id,
  form,
  name,
  validators,
  language,
  minHeight,
  defaultValue,
  className,
  onChange,
  onBlur,
  removeFieldOnUnmount,
  ...rest
}: MonacoEditorFieldProps) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet name={name} form={form} {...rest}>
      {(hasErrors) => (
        <form.Field name={name} validators={validators} defaultValue={defaultValue}>
          {(field) => (
            <MonacoEditorInput
              {...rest}
              id={id}
              value={
                typeof field.state.value !== 'string' ? JSON.stringify(field.state.value, null, 2) : field.state.value
              }
              language={language}
              minHeight={minHeight}
              className={className}
              onChange={(value, editor) => {
                field.handleChange(value)
                // Do this 2nd so we can override the value if needed
                if (onChange) onChange(value, editor)
              }}
              hasErrors={hasErrors}
            />
          )}
        </form.Field>
      )}
    </FieldSet>
  )
}

export default MonacoEditorField
