import clsx from 'clsx'

import styles from './progress-bar.module.css'

export type ProgressBarProps = {
  value: number
  color?: string
  style?: React.CSSProperties
  isStriped?: boolean
  isDone?: boolean
  className?: string
}

const ProgressBar = ({ value, color, style, isStriped = true, isDone, className }: ProgressBarProps) => {
  let progressBarClassNames = styles.progressBar

  if (isStriped && !isDone) {
    progressBarClassNames = clsx(progressBarClassNames, styles.stripeProgress)
  }

  // @TODO make animated <progress> element when animations work https://codepen.io/ayrtonvwf/pen/JZWENR
  return (
    <div className={`${styles.progressContainer} ${className}`} style={style}>
      <div
        className={progressBarClassNames}
        style={{
          width: `${value}%`,
          backgroundColor: color,
        }}
      />
    </div>
  )
}

export default ProgressBar
