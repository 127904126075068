import RadioGroupInput, { RadioGroupInputProps } from '../inputs/radio-group.input'
import FieldSet from './field-set'
import { FormFieldProps } from './types'
import { onBlurHandler, onChangeHandler, useRemoveFieldOnUnmount } from './utils'

export type RadioGroupFieldProps<T = string> = RadioGroupInputProps & FormFieldProps<T>

const RadioGroupField = ({
  form,
  name,
  wrapperClassName,
  labelClassName,
  descriptionClassName,
  validators,
  isArrayField,
  arrayFieldName,
  arrayFieldIndex,
  defaultValue,
  label,
  description,
  onChange,
  onBlur,
  removeFieldOnUnmount,
  ...rest
}: RadioGroupFieldProps) => {
  useRemoveFieldOnUnmount(form, name, removeFieldOnUnmount)

  return (
    <FieldSet
      name={name}
      form={form}
      wrapperClassName={wrapperClassName}
      labelClassName={labelClassName}
      descriptionClassName={descriptionClassName}
      arrayFieldIndex={arrayFieldIndex}
      arrayFieldName={arrayFieldName}
      isArrayField={isArrayField}
      {...rest}
    >
      {(hasErrors) => (
        <form.Field name={name} defaultValue={defaultValue} validators={validators}>
          {(field) => (
            <RadioGroupInput
              {...rest}
              name={name}
              label={label}
              description={description}
              value={field.state.value}
              onBlur={onBlurHandler(field, onBlur)}
              onChange={onChangeHandler(field, onChange)}
              hasErrors={hasErrors}
            />
          )}
        </form.Field>
      )}
    </FieldSet>
  )
}

export default RadioGroupField
