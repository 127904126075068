import Icon from '../icons/icon'

const ExternalLink = ({
  href,
  children,
  className,
  iconSize,
}: {
  href: string
  children: React.ReactNode
  className?: string
  iconSize?: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | number | string
}) => (
  <a href={href} target='_blank' rel='noopener noreferrer' className={className}>
    <span className='text-link inline-flex items-center gap-1'>
      {children}
      <Icon size={iconSize ?? 'xs'} className='-ml-0.5 mt-0.5' name='external' />
    </span>
  </a>
)

export default ExternalLink
