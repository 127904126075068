import Auth from '@aws-amplify/auth'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import analytics, { EVENTS } from 'src/utils/analytics'
import { authContext, getStoredUserAuth } from 'src/utils/auth/auth-context'
import { getLoggedInNode, getNodeSignInLink } from 'src/utils/auth/logged-in-node'
import { UserData, userState } from 'src/utils/state'
// TODO: see TODO note below...
// import { COGNITO_IDENTITY_KEY } from 'src/utils/hooks/use-api'

export default function LogoutPage() {
  const { setUnauthStatus, unsetSignedInNodeStatus } = React.useContext(authContext)
  const [_user, setUserState] = useRecoilState(userState)

  useEffect(() => {
    analytics.track(EVENTS.USER_SIGN_OUT)
    const session = getStoredUserAuth()

    const cleanUp = () => {
      setUnauthStatus()
      setUserState({} as UserData)

      // NOTE: Node login page needs a page refresh, otherwise it's broken due to the redirect-on-unauth logic
      const isSignedIntoNode = getLoggedInNode()

      if (isSignedIntoNode) {
        unsetSignedInNodeStatus()
        const signInLink = getNodeSignInLink({ withOrigin: false })
        window.location.href = signInLink
        return
      }
      window.location.href = '/login'
    }

    if (session.type === 'COGNITO') {
      Auth.signOut().then(cleanUp)
    } else {
      cleanUp()
    }
  }, [])

  return null
}
