import { DialogTitle } from '@headlessui/react'
import { useRef } from 'react'

import HubspotMeetingForm from '../forms/hubspot-meeting-form'
import Icon from '../icons/icon'
import Modal from './modal'

const ENTERPRISE_TIER_CAPABILITIES = [
  'Immutable and verifiable ledger of all transactions',
  'Unlimited Database, File storage, and transactions',
  'Real-time notifications and support for GraphQL subscriptions',
  'Guaranteed single tenancy cloud account for each node',
  'Role-based access control, including support for administrative roles',
  'SOC2 and PCI compliance, including yearly audits and security note generation on demand',
  'Built-in point-in-time recovery for ledger and scalar data',
  'Organization access and management',
]

const ENTERPRISE_TIER_BILLING = [
  'Discounted rates for bulk storage and operation pre-purchase',
  'Flexible billing, including invoicing for partners',
]

const ENTERPRISE_TIER_SUPPORT = [
  'Dedicated service channel on Slack',
  '99.9% Availability SLA',
  'Access to dedicated Vendia Solution Architect hours',
]

export interface Form {
  name: string
  expiration: {
    label: string
    value: string
  }[]
  customExpiration?: string
}

const EnterpriseModal = ({
  isOpen,
  isTrialExpired,
  onClose,
}: {
  isOpen: boolean
  isTrialExpired: boolean
  onClose?: () => void
}) => {
  const ref = useRef()

  return (
    <Modal
      isOpen={isOpen}
      onClose={
        onClose ??
        function noop() {
          return null
        }
      }
      initialFocusRef={ref}
    >
      <div
        className='inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:align-middle'
        data-testid='enterprise-upgrade-form'
      >
        <div className='sm:flex sm:items-start'>
          <div className='mt-3 flex w-full flex-col text-center sm:mt-0 sm:text-left'>
            <div className='border-neutral-3 bg-neutral-0 w-full border-b p-6'>
              <DialogTitle as='span' className='text-lg font-medium leading-6 text-gray-900'>
                <div className='flex justify-between'>
                  <h2 className='m-0 text-2xl font-semibold'>Upgrade to Enterprise</h2>
                  {onClose && <Icon name={'close'} size='xxs' onClick={onClose} />}
                </div>
              </DialogTitle>
            </div>
            <div className='grid gap-6 p-6 md:grid-cols-2'>
              {isTrialExpired && (
                <div className='col-span-2 flex flex-row rounded border border-gray-400 p-4'>
                  <Icon name='upgrade-rocket' className='mr-4' size='48px' />
                  <div className='flex flex-col'>
                    <div className='text-lg font-bold'>
                      <span data-testid='trial-expired'>Your free trial has expired</span>
                    </div>
                    <div className='text-sm'>
                      <p>To continue using Vendia, please schedule some time with your account representative. </p>
                      Upgrading your account unlocks dedicated support, organization access, SOC2 compliance and much
                      more.
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div className='text-sm'>Upgrade your account to</div>
                <h2 className='m-0 mb-5 text-2xl font-semibold'>Enterprise</h2>
                <p className='pb-4 text-sm'>
                  We understand that Enterprise use cases are very unique. For this reason we would like to schedule
                  some time discuss your business needs. Leave us your information and we will reach out shortly.
                </p>
                <h3 className='m-0 mb-4 text-xl font-semibold'>Capabilities</h3>
                <ul>
                  {ENTERPRISE_TIER_CAPABILITIES.map((feature, i) => (
                    <li key={feature} className='flex items-center gap-1 pb-4 text-sm'>
                      <Icon name='list' size={18} />
                      {feature}
                    </li>
                  ))}
                </ul>
                <h3 className='m-0 mb-4 text-xl font-semibold'>Billing and Invoicing</h3>
                <ul>
                  {ENTERPRISE_TIER_BILLING.map((feature, i) => (
                    <li key={feature} className='flex items-center gap-1 pb-4 text-sm'>
                      <Icon name='list' size={18} />
                      {feature}
                    </li>
                  ))}
                </ul>
                <h3 className='m-0 mb-4 text-xl font-semibold'>Support</h3>
                <ul>
                  {ENTERPRISE_TIER_SUPPORT.map((feature, i) => (
                    <li key={feature} className='flex items-center gap-1 pb-4 text-sm'>
                      <Icon name='list' size={18} />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <HubspotMeetingForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EnterpriseModal
