import { useSearchParams } from 'react-router-dom'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import useFeatureToggle, { Features } from 'src/utils/hooks/use-feature-toggle'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'

import { CreateFlow, StepId } from './config'
import LakehouseFlow from './flow-lakehouse/lakehouse.flow'
import { StepSelectUniType } from './flow-select/select-uni-type.step'
import StandardFlow from './flow-standard/standard.flow'

export default function UniCreate() {
  useDocumentTitle(`Create Uni - ${POSTFIX}`)
  const [searchParams, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const selectedFlow = searchParams.get('flow') as CreateFlow

  const { isLoading, isEnabled: lakehouseUniFlowEnabled } = useFeatureToggle(Features.DATA_DISTRO)

  if (isLoading) {
    return null
  }

  switch (true) {
    case lakehouseUniFlowEnabled && selectedFlow === 'lakehouse':
      return <LakehouseFlow />
    case selectedFlow === 'select-type':
      return (
        <MultiStepFlow
          key='select-type'
          hideStepNav
          flowSteps={[
            {
              id: StepId.SelectUniType,
              navTitle: 'Select Uni Type',
              StepComponent: StepSelectUniType,
            },
          ]}
        />
      )
    default:
      return <StandardFlow />
  }
}

/* WIP, finish after react-router 6.4 upgrade and move to "data APIs" (createBrowerRouter) */
/* AND/OR serialize and save state to localStorage so we can restore where they left off */
/* 
<BasicModal
  title='Are you sure you want to leave?'
  isOpen={blocker.state === 'blocked'}
  onClose={() => {
    blocker.reset()
  }}
  buttons={
    <div className='flex gap-2'>
      <Button kind={'secondary'} onClick={() => blocker.reset()}>
        Cancel
      </Button>
      <Button
        kind={'primary'}
        onClick={() => {
          blocker.proceed()
        }}
      >
        Yes, I'd like to leave
      </Button>
    </div>
  }
>
  <p>You have unsaved changes. Are you sure you want to leave?</p>
</BasicModal> 
*/
