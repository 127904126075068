import { Uni } from '@vendia/management-api-types'
import { useState } from 'react'
import { EditConnectionModal } from 'src/pages/uni-view/lakehouse/lakehouse-settings.page'

import Button from '../buttons/button'
import Card from '../containers/card'
import Icon from '../icons/icon'

const ConnectionBanner = ({ className, uni, ...params }: { uni: Uni; className?: string }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Card className={className} {...params} data-testid='connection-banner'>
        <div className='flex flex-row justify-between gap-4'>
          <div className='flex'>
            <Icon name='error' className='mr-4' size='48px' />
            <div className='flex flex-col'>
              <div className='text-lg font-bold'>The connection to your source is currently unavailable</div>
              <div className='max-w-lg text-sm'>
                Please select <strong>Fix connection</strong> to update your source connection settings and restore
                access to your application.
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center'>
            <Button
              kind='primary'
              size='small'
              className='ml-4'
              onClick={() => {
                setOpen(true)
              }}
            >
              Fix connection
            </Button>
          </div>
        </div>
      </Card>
      <EditConnectionModal isOpen={open} onClose={() => setOpen(false)} uni={uni} />
    </>
  )
}

export default ConnectionBanner
