// @ts-strict-ignore
import { UseQueryResult } from '@tanstack/react-query'
import { createVendiaClient, VendiaClient } from '@vendia/client'
import { createContext, PropsWithChildren, ReactNode, useContext, useEffect, useState } from 'react'
import { getStoredUserAuth } from 'src/utils/auth/auth-context'
import { Client } from 'urql'

import useApi from './hooks/use-api'
import { useCurrentRoute } from './hooks/use-current-route'
import { useGetUni } from './hooks/use-get-uni'
import { useSelectedRole } from './hooks/use-selected-role'
import { makeUrqlFetchClient } from './misc/clients/urql'

const UniApiContext = createContext<useProvideNodeApiResult>(null)

export type ProvideUniApiProps = PropsWithChildren<{
  vdlName?: string
  uniName?: string
  nodeName?: string
}>

export function ProvideUniApi({ children, vdlName, uniName, nodeName }: ProvideUniApiProps) {
  const api = useProvideNodeApi({ vdlName, uniName, nodeName: nodeName! })
  return <UniApiContext.Provider value={api}>{children}</UniApiContext.Provider>
}

export function useNodeApi() {
  return useContext(UniApiContext)
}

/*
  This hook can be used as an alternative to `useNodeApi` whenever the node in the context
  isn't necessarily the one that's needed. This is useful for uni level pages that have a toggle
  to switch between nodes.
*/
interface useProvideNodeApiProps {
  vdlName?: string
  uniName?: string
  nodeName: string
}

interface useProvideNodeApiResult {
  uni?: any
  node?: any
  apiUrl?: string
  websocketApiUrl?: string
  apiKey?: string
  urqlClient?: Client
  client?: VendiaClient
  getUniQ?: UseQueryResult<any, unknown>
  uniName?: string
  vdlName?: string
  nodeName?: string
  api?: any
  loading?: boolean
}

export function useProvideNodeApi({ vdlName, uniName, nodeName }: useProvideNodeApiProps) {
  const route = useCurrentRoute()
  const api = useApi()
  const [urqlClient, setUrqlClient] = useState<Client | undefined>()
  const [client, setClient] = useState<VendiaClient | undefined>()
  const selectedRole = useSelectedRole()

  const getUniQ = useGetUni()
  const loading = getUniQ.isLoading
  const uni = getUniQ?.data?.getUni
  const node = uni?.nodes?.find((n) => n.name === nodeName)
  const apiUrl = node?.resources?.graphqlApi?.httpsUrl
  const websocketApiUrl = node?.resources?.graphqlApi?.websocketUrl
  const apiKey = node?.resources?.graphqlApi?.apiKey

  useEffect(() => {
    const asyncUseEffect = async () => {
      if (!apiUrl || !websocketApiUrl || !apiKey) {
        return
      }
      try {
        // Using Urql client
        const newUrqlClient = await makeUrqlFetchClient({
          graphqlApi: apiUrl,
          graphqlApiKey: apiKey,
          websocketApi: websocketApiUrl,
          route,
          selectedRoleName: selectedRole?.name,
        })
        setUrqlClient(newUrqlClient)
      } catch (err) {
        console.error('Urql err', err)
      }

      try {
        // Using @vendia/client
        const client = createVendiaClient({
          // TODO: could add all the token refresh logic junk here, as well
          getCredentials: async () => ({ token: getStoredUserAuth()?.idToken }),
          apiUrl: apiUrl,
          // Custom fetch function to add headers
          fetch: (url: string, options: RequestInit) =>
            // @ts-ignore - this is fine
            fetch(url, {
              ...options,
              headers: {
                ...options.headers,
                'x-vendia-client': 'app',
                'x-vendia-feature': route,
                'x-vendia-role-name': selectedRole?.name,
              },
            }),
        }) as VendiaClient
        setClient(client)
      } catch (err) {
        console.error('Error creating @vendia/client instance', err)
      }
    }
    asyncUseEffect()
  }, [apiUrl, websocketApiUrl, apiKey, route, selectedRole?.name])

  return {
    uni,
    node,
    apiUrl,
    websocketApiUrl,
    apiKey,
    urqlClient,
    client,
    getUniQ,
    uniName,
    vdlName,
    nodeName,
    api,
    loading,
  }
}
