import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Invite, UniSkuEnum } from '@vendia/management-api-types'
import debug from 'debug'
import { useContext, useState } from 'react'
import { useRecoilValue } from 'recoil'
import safe from 'safe-await'
import Button from 'src/components/buttons/button'
import MultiStepFlow from 'src/components/flows/multi-step-flow'
import BasicModal from 'src/components/modals/modal.basic'
import { SchemaDesignerContext } from 'src/components/schema-designer/schema-designer-context'
import analytics, { EVENTS } from 'src/utils/analytics'
import inputValidation from 'src/utils/form/input.validation'
import useApi from 'src/utils/hooks/use-api'
import { refetchUnis } from 'src/utils/hooks/use-list-unis'
import { captureException } from 'src/utils/misc/sentry'
import notify from 'src/utils/notify'
import { generateShortId } from 'src/utils/short-id'
import { userState } from 'src/utils/state'
import { validateSchemaAndDisplayErrors } from 'src/utils/validate-schema'

import { StepId } from '../config'
import { buildUniFqn, buildUniNameFromAlias, generateNodesForApi } from '../utils'
import { StepAddNodes } from './add-nodes.step'
import { StepConfirmation } from './confirmation.step'
import { StepCreateUni } from './create-uni.step'
import { StepInvitePartners } from './invite-partners.step'
import { StepSchemaDesigner } from './schema-designer.step'
import { StepSchemaEditMode } from './schema-edit-mode.step'

const logger = debug('app:standard-flow')

type CreateErrorInfo = {
  title: string
  subTitle: string
  lines: string[]
} | null

export type UniCreateFormValues = {
  randomSuffix: string
  invites: { email: string }[]
  nodes: any[]
  uniName: string
  uniNamespace: string
  schema: string
  enableFinancialServices?: boolean
  uniJoinMode?: boolean
}

const StandardFlow = () => {
  const [createErrorInfo, setCreateErrorInfo] = useState<CreateErrorInfo>(null)
  const userData = useRecoilValue<any>(userState)
  const queryClient = useQueryClient()
  const api = useApi()
  const inviteMutation = useMutation({
    mutationFn: ({ uni, userId }: { uni: string; userId: string }) => api.invite({ uni, userId }),
  })
  const { schema, selectedDemoTemplate } = useContext(SchemaDesignerContext)

  async function onSubmit({ value, formApi }: any) {
    logger('createUni!')
    logger('value:', value)

    const { uniName, uniNamespace, nodes, enableFinancialServices, invites, randomSuffix } = value

    // The "name" user entered is actually stored as the alias
    const alias = uniName
    const generatedUniName = buildUniNameFromAlias(alias, randomSuffix)
    const name = buildUniFqn(generatedUniName, uniNamespace)

    // This SHOULD be valid, but let's check once more here (plus we want a few details for analytics)
    const newSchema = JSON.stringify(schema)
    const [e] = await validateSchemaAndDisplayErrors(newSchema)
    if (e) throw e

    const nodesForApi = generateNodesForApi(userData.email, nodes)
    logger({ nodesForApi })

    let results: any[] = []
    const [error, resp] = await safe(
      api.register({
        name,
        alias,
        schema: newSchema,
        initState: '{}',
        nodes: nodesForApi,
        sku: enableFinancialServices ? UniSkuEnum.ShareFinancialServices : UniSkuEnum.Share,
        // asRole
      }),
    )

    if (error) {
      logger('API err', error)
      // Capture api errors
      captureException(error)
    }

    results = results.concat(resp)
    logger('Creation results:', results)

    const apiErrors = results
      .filter((res) => {
        return res.errors
      })
      .reduce((acc, curr) => {
        logger('Creation errors', curr.errors)
        acc = acc.concat(curr.errors)
        return acc
      }, [])

    if (apiErrors.length) {
      logger('apiErrors', apiErrors)
      const plural = apiErrors.length > 1 ? 's' : ''

      const errorsFromApi = apiErrors.map((apiError: Error) => apiError.message)
      setCreateErrorInfo({
        title: 'Uni creation errors',
        subTitle: `Encountered the following error${plural} while attempting to deploy this Uni:`,
        lines: errorsFromApi,
      })

      logger('API errors', errorsFromApi)

      // Track failure. @TODO send error messages through?
      analytics.track(EVENTS.UNI_CREATE_FAILED)

      // exit early
      throw new Error('Uni creation failed')
    }

    // Success continue
    analytics.track(EVENTS.UNI_CREATED, {
      uniName: name,
      schemaTemplate: selectedDemoTemplate, // eg, "Shopping List"
      schemaTitle: schema?.title,
      schemaEntityCount:
        typeof schema?.properties === 'object' && schema?.properties !== null
          ? Object.keys(schema.properties).length
          : null,
      nodeCount: nodesForApi.length,
      authType: nodesForApi.map((node) => node?.settings?.apiSettings?.auth?.authorizerType),
    })

    // Invalidate queries
    refetchUnis({ queryClient })

    // Send out Uni invites (if any)
    invites.forEach(async (invite: { email: string }) => {
      /* We allow empty values for invite email so they can just skip */
      if (inputValidation.isEmail.pattern.test(invite.email) === false) {
        logger('Skipping sending invite to empty/invalid email', invite.email)
        return
      }
      const response = await inviteMutation.mutateAsync({
        uni: name,
        userId: invite.email,
      })
      if (response?.errors?.length) {
        logger('Invite error', response.errors)
        notify.error(
          `There was a problem sending an invite to ${invite.email}.<br/><br/>${response.errors?.[0]?.message}`,
        )
      } else {
        //track successful invite
        analytics.track(EVENTS.UNI_INVITE)
      }
    })
    logger('on submit completed')
  }

  return (
    <>
      <MultiStepFlow<UniCreateFormValues>
        key='standard'
        onSubmit={onSubmit}
        initialFlowState={{
          // So the flow will have a stable random uni name suffix we can show them in naming
          // step and use in the final step to generate the uni
          randomSuffix: generateShortId({ existingIds: [] }),
          invites: [],
        }}
        confirmationStep={StepId.Confirmation}
        flowSteps={[
          {
            id: StepId.CreateNetwork,
            navTitle: 'Create Uni',
            StepComponent: StepCreateUni,
          },
          {
            id: StepId.AddNodes,
            navTitle: 'Add a node',
            StepComponent: StepAddNodes,
          },
          {
            id: StepId.SchemaEditMode,
            navTitle: 'Create data model',
            StepComponent: StepSchemaEditMode,
          },
          {
            id: StepId.SchemaDesigner,
            navTitle: '', // Not displayed
            showInStepNav: false,
            StepComponent: StepSchemaDesigner,
          },
          {
            id: StepId.InvitePartners,
            navTitle: 'Invite partners',
            StepComponent: StepInvitePartners,
          },
          {
            id: StepId.Confirmation,
            navTitle: '',
            showInStepNav: false,
            StepComponent: StepConfirmation,
          },
        ]}
      />

      <BasicModal
        title={createErrorInfo?.title}
        isOpen={createErrorInfo !== null}
        onClose={() => setCreateErrorInfo(null)}
        buttons={
          <Button
            key='cancel'
            kind='secondary'
            onClick={() => {
              setCreateErrorInfo(null)
            }}
          >
            Close
          </Button>
        }
      >
        <p className='mb-2 font-bold'>{createErrorInfo?.subTitle}</p>
        <ul>
          {createErrorInfo?.lines?.map((line, i) => {
            return <li key={i}>{line}</li>
          })}
        </ul>
      </BasicModal>
    </>
  )
}

export default StandardFlow
