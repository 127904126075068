import { Description, Label, Radio, RadioGroup } from '@headlessui/react'
import clsx from 'clsx'
import debug from 'debug'
import { PropsWithChildren } from 'react'

import Card, { CardRule } from '../containers/card'
import { FormInputProps } from '../fields/types'

const logger = debug('app:RadioGroup')

export type RadioGroupInputProps = FormInputProps & {
  onChange?: (value: any) => void
  options: {
    value: any
    label: string | JSX.Element
    description?: string | JSX.Element
  }[]
  value?: string
  isWrapped?: boolean
  dividerClassName?: string
}

function RadioGroupInput({
  label,
  description,
  value,
  hasErrors,
  options,
  isWrapped,
  dividerClassName,
  ...rest
}: RadioGroupInputProps) {
  return (
    <RadioGroup
      {...rest}
      onClick={(e) => {
        // Clicks were triggering form submission
        e.preventDefault()
      }}
      defaultValue={value}
      className={clsx('flex flex-col gap-4', rest.className)}
    >
      {(label || description) && (
        <div>
          {label && (
            <Label className='flex flex-col gap-1'>
              <div className='text-sm font-semibold'>{label}</div>
            </Label>
          )}
          {description && <Description className='text-neutral-8 text-xs'>{description}</Description>}
        </div>
      )}
      <Wrapper isWrapped={isWrapped} className='flex flex-col gap-4'>
        {options.map((option, index) => (
          <Radio key={JSON.stringify(option.value)} value={option.value} className='w-full'>
            {({ checked }) => {
              return (
                <div className='flex flex-col gap-4'>
                  {index > 0 && <CardRule className={clsx(!dividerClassName && '-mx-10', dividerClassName)} />}
                  <Label className='flex items-center gap-2'>
                    <div className='mr-2'>
                      {checked ? (
                        <div className='ring-information-10 h-6 w-6 rounded-full ring-8 ring-inset' />
                      ) : (
                        <div
                          className={clsx(
                            'h-6 w-6 rounded-full ring-1 ring-inset',
                            hasErrors ? 'ring-error-8' : 'ring-information-10',
                          )}
                        />
                      )}
                    </div>
                    <div>
                      <div className='flex items-baseline text-sm font-semibold'>{option.label}</div>
                      {option?.description && (
                        <Description className={'text-neutral-8 text-xs'}>{option.description}</Description>
                      )}
                    </div>
                  </Label>
                </div>
              )
            }}
          </Radio>
        ))}
      </Wrapper>
    </RadioGroup>
  )
}

const Wrapper = ({
  children,
  isWrapped,
  className,
}: PropsWithChildren<{ isWrapped?: boolean; className?: string }>) => {
  if (isWrapped) {
    return (
      <Card padding='none' className={clsx('rounded-md', className)}>
        {children}
      </Card>
    )
  }
  return <div className={className}>{children}</div>
}

export default RadioGroupInput
