import { useContext } from 'react'
import Button from 'src/components/buttons/button'
import { VendiaUniqueKey } from 'src/types/schema'

import { EntityDetailsColumn } from './entity-details-column'
import { ListItem } from './list-item'
import { SchemaDesignerContext, SchemaDesignerContextType } from './schema-designer-context'
import { Status } from './status'
import { createArrayOfObjectsEntityObjectDefinitionPath } from './utils'

export const EntityAttributesList = () => {
  const { setDesignerState, selectedEntity, selectedEntityKey, selectedEntityFieldKeys, readOnlyMode } = useContext(
    SchemaDesignerContext,
  ) as SchemaDesignerContextType

  if (selectedEntity == null || selectedEntityKey == null) {
    return null
  }

  return (
    <div className='flex'>
      <div className='flex w-3/4 flex-col gap-4'>
        {selectedEntityFieldKeys.map((fieldKey) => {
          const selectedField = selectedEntity?.items?.properties?.[fieldKey]
          if (!selectedField) {
            return null
          }
          const isRequired = selectedEntity?.items?.required?.includes(fieldKey) ?? false
          const isUnique = selectedEntity?.items?.[VendiaUniqueKey]?.includes(fieldKey) ?? false
          const fieldParentPath = createArrayOfObjectsEntityObjectDefinitionPath(selectedEntityKey)
          return (
            <div key={fieldKey}>
              <ListItem
                field={selectedField}
                fieldKey={fieldKey}
                fieldParentPath={fieldParentPath}
                isRequired={isRequired}
                isUnique={isUnique}
              />
            </div>
          )
        })}
        <div>
          {readOnlyMode ? null : (
            <Button
              kind='secondary'
              onClick={() => {
                setDesignerState({
                  status: Status.SHOW_FIELD_MODAL_NEW,
                  selectedFieldParentPath: createArrayOfObjectsEntityObjectDefinitionPath(selectedEntityKey),
                })
              }}
              icon='plus-m'
              iconSize={14}
            >
              Add another attribute
            </Button>
          )}
        </div>
      </div>
      <EntityDetailsColumn />
    </div>
  )
}
