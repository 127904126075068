import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import Button from 'src/components/buttons/button'
import Card from 'src/components/containers/card'
import Icon from 'src/components/icons/icon'
import { refetchCurrentVendiaUser } from 'src/utils/hooks/use-current-vendia-user-query'
import { useGetOrg } from 'src/utils/hooks/use-get-org'
import notify from 'src/utils/notify'
import { Tier } from 'src/utils/subscription'

import SubscriptionTiers, { ENTERPRISE_TIER_FEATURES } from './subscription-tiers'

export default function Subscription() {
  enum Status {
    INITIALIZED,
    CANCEL_REQUESTED,
  }
  const queryClient = useQueryClient()
  const [status, setStatus] = useState(Status.INITIALIZED)

  // We don't want to rely on cached data in case user recently cancelled or upgraded
  useEffect(() => {
    refetchCurrentVendiaUser({ queryClient })
  }, [])
  const getOrg = useGetOrg()

  const subscription = getOrg?.data?.getOrganization?.subscription
  const { tier } = subscription || {}

  function renderView() {
    switch (tier) {
      case Tier.FREE:
        return <SubscriptionTiers tiers={[Tier.ENTERPRISE]} />
      case Tier.INDIVIDUAL_TRIAL:
        return <SubscriptionTiers tiers={[Tier.ENTERPRISE]} />
      case Tier.ENTERPRISE:
        return <EnterpriseTier />
      case Tier.ENTERPRISE_TRIAL:
        return <SubscriptionTiers tiers={[Tier.ENTERPRISE]} />
      default:
        return null
    }
  }
  const onCancel = () => {
    refetchCurrentVendiaUser({ queryClient })
    notify.success('Subscription cancelled')
    setStatus(Status.INITIALIZED)
  }
  return (
    <>
      <Card title='Subscription'>
        <div>{renderView()}</div>
      </Card>
    </>
  )
}

function EnterpriseTier() {
  const allFeatures = [...ENTERPRISE_TIER_FEATURES]
  return (
    <Card className='m-2 border-2 border-gray-200 bg-gray-50 p-6 !shadow-none'>
      <>
        <div className='flex items-center justify-between'>
          <div>
            <div className='text-sm'>Your current subscription</div>
            <h2 className='m-0 mb-5 text-2xl font-semibold'>Enterprise</h2>
          </div>
        </div>
        <div>
          <ul>
            {allFeatures.map((feature) => (
              <li className='flex items-center gap-1 pb-4 text-sm' key={feature}>
                <Icon name='list' size={18} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <div className='mt-4'>
          <span className='text-sm font-thin italic'>
            To make changes to your subscription, please contact support at support@vendia.com.
          </span>
        </div>
      </>
    </Card>
  )
}
