import { TextInputProps } from '../inputs/text.input'
import FieldList, { FieldListProps, FieldListRenderProps } from './field-list'
import TextField from './text.field'

export type TextListFieldProps = FieldListProps<TextInputProps, string[]>

function TextListField(props: TextListFieldProps) {
  return (
    <FieldList {...props}>
      {({ fieldRef, ...renderProps }: FieldListRenderProps<HTMLInputElement, string[]>) => {
        return (
          <TextField
            {...renderProps}
            ref={fieldRef}
            form={props.form}
            isArrayField
            useNestedLabel
            wrapperClassName='mb-2 flex-grow mr-2'
          />
        )
      }}
    </FieldList>
  )
}

export default TextListField
