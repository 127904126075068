import Auth from '@aws-amplify/auth'
import { useForm } from '@tanstack/react-form'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { authContext } from 'src/utils/auth/auth-context'
import { isRequiredOnBlur } from 'src/utils/form/validation'
import notify from 'src/utils/notify'

import Button from '../buttons/button'
import Form from '../fields/form'
import TextField from '../fields/text.field'

function ForceResetForm(props: { user: any }) {
  const navigate = useNavigate()
  const { setAuthStatus } = React.useContext(authContext)
  const passwordInput = useRef<any>(null)

  useEffect(() => {
    if (passwordInput && passwordInput.current?.textInput) {
      setTimeout(() => {
        window.location.hash = ''
        passwordInput.current.focus()
      }, 10)
    }
  }, [])

  const form = useForm<any>({
    onSubmit: async ({ value }: any) => {
      if (!value.password) {
        notify.error('Please input a new password to continue')
        passwordInput.current?.focus()
        return
      }

      if (!value.confirm) {
        notify.error('Please confirm the new password to continue')
        return
      }

      if (value.password !== value.confirm) {
        notify.error('Password and confirm password fields do not match. Please update these fields')
        return
      }

      try {
        await Auth.completeNewPassword(props.user, value.password)
        const currentSession = (await Auth.currentSession()) as any
        console.log('auth result handleForcedReset', currentSession)
        setAuthStatus({
          userId: currentSession.idToken.payload.sub,
          idToken: currentSession.idToken.jwtToken,
          authenticated: true,
        } as any)
        // Redirect to dashboard
        navigate('/')
      } catch (completeNewPasswordError) {
        console.log('Force Reset Error', completeNewPasswordError)
        notify.error('Error Resetting password. Please contact support')
      }
    },
  })

  return (
    <div>
      <p>A reset of your password is required.</p>
      <p>Please enter a new password to continue</p>
      <Form form={form}>
        <TextField
          ref={passwordInput}
          name='password'
          label='New Password'
          placeholder='Enter password'
          type='password'
          form={form}
          validators={isRequiredOnBlur}
        />

        <TextField
          name='confirm'
          label='Confirm Password'
          placeholder='Verify password'
          type='password'
          form={form}
          validators={{
            onChangeListenTo: ['password'],
            onChange: ({ value, fieldApi }: any) => {
              if (value !== fieldApi.form.getFieldValue('password')) {
                return 'Passwords do not match'
              }
            },
            ...isRequiredOnBlur,
          }}
        />
        <form.Subscribe selector={(state) => state.isSubmitting}>
          {(isLoading) => (
            <Button
              className='mt-4'
              type='submit'
              iconProps={{
                isSpinning: isLoading,
                size: 16,
              }}
            >
              {isLoading ? 'Confirming...' : 'Confirm new password'}
            </Button>
          )}
        </form.Subscribe>
      </Form>
    </div>
  )
}

export default ForceResetForm
