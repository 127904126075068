// @ts-strict-ignore
import { getOrgResourceFromString, Resource, rolePermitsClient } from '@vendia/management-api-rbac'
import { ActionEnum, Role } from '@vendia/management-api-types'
import debug from 'debug'
import { useRecoilValue } from 'recoil'

import { selectedRoleState } from '../state'
import useGetCurrentVendiaUserQuery from './use-current-vendia-user-query'

const logger = debug('useRolePermits')

type UseRolePermitsProps = {
  action: ActionEnum
  // Resource isn't necessary for ORG resources - will use your own org
  resource?: Resource
}

export default function useRolePermits({ action, resource }: UseRolePermitsProps) {
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()

  const selectedRole = useRecoilValue(selectedRoleState)
  const role: Role = getCurrentVendiaUserQuery?.data?.getUser?.roles?.find((role) => role.name === selectedRole.name)
  logger('selectedRole', selectedRole)
  logger('role', role)
  if (!role) return false

  // ORG actions
  if (
    action &&
    (action.startsWith('ORG') ||
      action.startsWith('ROLE') ||
      action.startsWith('APICREDS') ||
      action.startsWith('WORKFLOWS'))
  ) {
    if (resource) {
      return rolePermitsClient({ role, action, resource })
    }
    // If it's an Org action without resource argument, we'll create the org resource
    const orgId = getCurrentVendiaUserQuery?.data?.getUser?.orgId
    if (!orgId) return false
    const orgResource = getOrgResourceFromString(orgId)
    return rolePermitsClient({ role, action, resource: orgResource })
  }

  if (!resource) {
    logger('Resource is required for non-ORG actions')
    return false
  }

  // UNI/USER/ROLE/DATA, etc. actions
  return rolePermitsClient({ role, action, resource, callerId: getCurrentVendiaUserQuery?.data?.getUser?.userId })
}
