import debug from 'debug';
import { createCoreClient } from './create-core-client';
import { createSubscriptionClient } from './create-subscription-client';
import { getBlocks, getContracts, getDeployments, getEntities, getFiles, getFileTasks, getFolders, getSettings, getSharingPolicies, getUniInfo,
// @ts-ignore - this import statement will always be broken prior to codegen
 } from './generated';
export const createVendiaClient = (options) => {
    const _logger = debug('@vendia/client');
    if (options.debug) {
        _logger('Debug enabled via options');
        debug.enable('@vendia/client');
    }
    const { _subscribe } = createSubscriptionClient(options, { _logger });
    const { _gqlClient, _requestWrapper, request } = createCoreClient(options, { _logger });
    const getSdkOptions = { _gqlClient, _requestWrapper, _subscribe };
    const blocks = getBlocks(getSdkOptions);
    const contracts = getContracts(getSdkOptions);
    const deployments = getDeployments(getSdkOptions);
    const entities = getEntities(getSdkOptions);
    const uniInfo = getUniInfo(getSdkOptions);
    const settings = getSettings(getSdkOptions);
    const folders = getFolders(getSdkOptions);
    const files = getFiles(getSdkOptions);
    const fileTasks = getFileTasks(getSdkOptions);
    const sharingPolicies = getSharingPolicies(getSdkOptions);
    const storage = {
        files,
        folders,
        fileTasks,
    };
    return {
        request,
        deployments,
        blocks,
        storage,
        contracts,
        uniInfo,
        settings,
        entities,
        sharingPolicies,
    };
};
