import { DialogTitle } from '@headlessui/react'
import React, { ReactNode, useRef, useState } from 'react'

import Button from '../buttons/button'
import EnterpriseModal from './enterprise-modal'
import Modal from './modal'

interface PaywallModalProps {
  isOpen: boolean
  message?: string | ReactNode
  // Omit onClose to make the modal "undismissiable"
  onClose?: () => void
}

enum Status {
  SHOW_PAYWALL,
  ENTERPRISE_INIT_SUCCESS,
}

export const PaywallModal = ({ isOpen, onClose, message }: PaywallModalProps) => {
  const [status, setStatus] = useState<Status>(Status.SHOW_PAYWALL)
  const onOpenUpgradeModal = async () => {
    setStatus(Status.ENTERPRISE_INIT_SUCCESS)
  }

  const resetStatusAndCallOnClose = () => {
    setStatus(Status.SHOW_PAYWALL)
    onClose?.()
  }

  const ref = useRef<HTMLButtonElement | null>(null)
  return (
    <>
      <Modal
        isOpen={isOpen && status === Status.SHOW_PAYWALL}
        onClose={resetStatusAndCallOnClose}
        initialFocusRef={ref}
      >
        <div className='inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:align-middle xl:max-w-6xl'>
          <div className='sm:flex sm:items-start'>
            <div className='mt-3 flex w-full flex-col text-center sm:mt-0 sm:text-left'>
              <div className='flex w-full gap-12 p-12'>
                <div className='w-1/3 px-12'>
                  <img src='/images/rocket.svg' alt='rocket illustration' className='mt-8' />
                </div>
                <div className='flex w-2/3 flex-col justify-center gap-8'>
                  <DialogTitle as='span' className='text-lg font-medium leading-6 text-gray-900'>
                    <h2 className='m-0 mb-5 text-5xl font-semibold'>Upgrade to Enterprise</h2>
                  </DialogTitle>
                  <div className='text-sm'>{message}</div>
                  <div className='flex items-center gap-2'>
                    {onClose && (
                      <Button
                        ref={ref}
                        kind='secondary'
                        onClick={() => {
                          onClose()
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      kind='secondary'
                      href='https://www.vendia.com/pricing'
                      target='_blank'
                      icon='external'
                      iconSize={16}
                    >
                      View all Pro features
                    </Button>
                    <Button onClick={onOpenUpgradeModal}>Upgrade</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <EnterpriseModal
        isOpen={isOpen && status === Status.ENTERPRISE_INIT_SUCCESS}
        isTrialExpired={false}
        onClose={resetStatusAndCallOnClose}
      />
    </>
  )
}
