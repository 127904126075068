import { DialogTitle } from '@headlessui/react'
import { ReactNode, useRef, useState } from 'react'
import TextInput from 'src/components/inputs/text.input'

import Button from '../buttons/button'
import Modal from './modal'

interface ConfirmationModalProps {
  title: ReactNode
  children: ReactNode
  isSubmitting: boolean
  onClose: () => void
  onSubmit: () => void
  isOpen: boolean
  actionButtonText?: string
  actionButtonType?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link' | 'toggle'
  confirmationType?: string
  confirmationText?: string | null
  fullConfirmationPrompt?: string | ReactNode
  fullConfirmationPlaceholder?: string
}

const ConfirmationModal = ({
  title,
  children,
  isSubmitting,
  onClose,
  onSubmit,
  isOpen,
  actionButtonText,
  actionButtonType,
  confirmationType,
  confirmationText,
  fullConfirmationPrompt,
  fullConfirmationPlaceholder,
}: ConfirmationModalProps) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const [confirmationState, setConfirmationState] = useState<string | number>()

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={cancelButtonRef}>
      <div className='inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:align-middle'>
        <div className='sm:flex sm:items-start'>
          <div className='mt-3 flex w-full flex-col text-center sm:mt-0 sm:text-left'>
            <div className='border-neutral-3 bg-neutral-0 w-full border-b p-6'>
              <DialogTitle as='span' className='text-lg font-medium leading-6 text-gray-900'>
                <h2 className='m-0 text-xl font-semibold'>{title}</h2>
              </DialogTitle>
            </div>
            <div className='p-6'>
              {children}
              {(confirmationType || fullConfirmationPrompt) && (
                <div className='mt-4 text-sm'>
                  <p>
                    {fullConfirmationPrompt ? (
                      <strong>{fullConfirmationPrompt}</strong>
                    ) : (
                      <strong>Type the name of the {confirmationType} to confirm</strong>
                    )}
                  </p>
                  <div className='mb-2 mt-1'>
                    <p
                      style={{
                        userSelect: 'none',
                        border: '1px solid #B9BDCC',
                        color: '#B9BDCC',
                        background: '#f8f9fa',
                        borderRadius: 4,
                        fontSize: 16,
                        paddingTop: 11,
                        paddingBottom: 11,
                        paddingLeft: 13,
                        paddingRight: 13,
                      }}
                    >
                      {confirmationText}
                    </p>
                  </div>
                  <div>
                    <TextInput
                      name='confirmation'
                      aria-label='Confirmation Text'
                      onChange={setConfirmationState}
                      value={confirmationState as string}
                      placeholder={fullConfirmationPlaceholder ?? `Enter ${confirmationType} name to confirm`}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='border-neutral-3 bg-neutral-0 flex items-center justify-end gap-2 border-t p-6'>
          <Button kind='secondary' onClick={onClose} className='mt-3 sm:mt-0' ref={cancelButtonRef}>
            Cancel
          </Button>
          <Button
            kind={actionButtonType ?? 'danger'}
            onClick={onSubmit}
            disabled={
              isSubmitting || (!!(confirmationType || fullConfirmationPrompt) && confirmationState !== confirmationText)
            }
            icon={isSubmitting ? 'refresh' : null}
            iconProps={{
              isSpinning: isSubmitting,
              size: 15,
            }}
          >
            {actionButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
