import _config from 'src/config'

export const getProvidersForDomain = async (domain: string) => {
  let providers = []

  try {
    const response = await fetch(_config.domainApi, {
      method: 'POST',
      body: JSON.stringify({
        domain,
      }),
    })
    const result = await response.json()
    providers = result.providers
  } catch (e) {
    console.info('Error caught fetching providers for domain', e)
  }

  return providers
}
