import LoginForm from 'src/components/forms/login-form'
import { Illustration } from 'src/layouts/illustration.layout'
import { POSTFIX, useDocumentTitle } from 'src/utils/hooks/use-title'

export const LoginOverImageContent = () => {
  return (
    <div className='flex flex-col gap-8'>
      <h1 className='pt-4 text-5xl font-bold leading-tight'>
        Data automation,
        <br />
        simplified.
      </h1>
      <p className='max-w-lg text-balance text-xl'>
        Connect any system, unlock insights, and drive action — all with Vendia's all-in-one platform.
      </p>
    </div>
  )
}

export default function Login() {
  useDocumentTitle(`Log in - ${POSTFIX}`)

  return (
    <Illustration overImageContent={<LoginOverImageContent />}>
      <LoginForm />
    </Illustration>
  )
}
