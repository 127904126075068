import hubSpotPlugin from '@analytics/hubspot'
import AmplifyAuth from '@aws-amplify/auth'
import { attributionPlugin } from '@vendia/analytics-attribution'
import Analytics from 'analytics'
import debug from 'debug'

import config from '../../config'
import enrichHubSpotPayload from './plugins/enrich-hubspot'
/* local plugins */
import eventPrefixerPlugin from './plugins/event-prefixer'
import eventValidationPlugin from './plugins/event-validation'
import loggerPlugin from './plugins/logger'
import { getRefData } from './utils/get-ref-data'

const { isLocalHost, isBrowser, isProd, isStaging, isDev } = config
const logger = debug('analytics:init')

const APP_VERSION = '0.0.1'
// If we are in browser and (stage === staging) OR (stage === prod) + is prod domain
const shouldLoadPlugins = isBrowser && !isLocalHost && (isProd || isStaging)

/* Telemetry events */
const EVENTS = {
  /* Global events */
  // page view
  PAGE_VIEW: 'global.page_viewed',
  // form conversion
  FORM_SUBMITTED: 'global.form_submitted',

  /* User specific events */
  // User sign up
  USER_SIGN_UP: 'app.user_signUp',
  // User sign in
  USER_SIGN_IN: 'app.user_signIn',
  // User sign out
  USER_SIGN_OUT: 'app.user_signOut',
  // User reset their password
  USER_PASSWORD_RESET: 'app.user_passwordReset',
  // Error with user login
  USER_AUTH_ERROR: 'app.user_authError',
  // User requested change email
  USER_EMAIL_CHANGE_START: 'app.user_emailChangeRequested',
  // User completed changing their email
  USER_EMAIL_CHANGE_COMPLETED: 'app.user_emailChangeConfirmed',
  // User verified email
  USER_EMAIL_CONFIRMED: 'app.user_emailVerified',
  // User has enabled 2 factor auth
  USER_2FA_ENABLED: 'app.user_2faEnabled',
  // User has disable 2 factor auth
  USER_2FA_DISABLED: 'app.user_2faDisabled',

  /* Role specific events */
  // Role created
  ROLE_CREATED: 'app.role_created',
  // Role updated
  ROLE_UPDATED: 'app.role_updated',
  // Role deactiavted
  ROLE_DEACTIVATED: 'app.role_deactivated',

  /* Uni specific events */
  // User created a new uni
  UNI_CREATED: 'app.uni_created',
  // User attempted to create new uni but it failed
  UNI_CREATE_FAILED: 'app.uni_createFailed',
  //User invited partner
  UNI_INVITE: 'app.uni_invite',
  /* @TODO
  UNI_DESTROYED: 'app:uni_destroyed',
  UNI_DESTROY_FAILED: 'app.uni_destroyFailed',
  UNI_INVITE_FAILED: 'app.uni_inviteFailed',
  UNI_JOIN: 'app.uni_join',
  UNI_JOIN_FAILED: 'app.uni_joinFailed',
  */

  /* Uni Node specific events */
  NODE_SETTINGS_UPDATED: 'app.node_settingsUpdated',
  NODE_ARCHIVED: 'app.node_archived',
  NODE_DELETED: 'app.node_deleted',

  /* BILLING EVENTS */
  BILLING_PLAN_SELECTED: 'app.billing_planSelected',
  BILLING_SELECT_UNIS_SUBMITTED: 'app.billing_selectUnisSubmitted',
  BILLING_ADDRESS_SUBMITTED: 'app.billing_addressSubmitted',
  BILLING_PAYMENT_SUBMITTED: 'app.billing_paymentSubmitted',
  BILLING_CONTACT_FORM_SUBMITTED: 'app.billing_contactFormSubmitted',
  BILLING_PAYMENT_SUCCESS: 'app.billing_paymentSuccess',
  // Changing sub:
  BILLING_CHANGE_UNIS_SUBMITTED: 'app.billing_changeUnisSubmitted',
  BILLING_CHANGE_UNIS_SUCCESS: 'app.billing_changeUnisSuccess',
  // Cancelling sub:
  BILLING_CANCEL_SUBMITTED: 'app.billing_cancelSubmitted',
  // Any error along the way:
  BILLING_ERROR: 'app.billing_error',
}

const pageKey = 'app.pageViewCount'
const sessionKey = 'app.sessionCount'

/* Analytic plugins */
const plugins = [
  /* Save initial landing page & referrer */
  attributionPlugin(),
  /* Automatically prefix all event names */
  eventPrefixerPlugin(['app.', 'global.'], 'app.'),
  /* Validate all outgoing requests */
  eventValidationPlugin({
    // Namespace of current application
    context: ['app', 'global'],
    // Allowed objects
    objects: [
      'test',
      'uni', // example app.uni_created, app.uni_destroyed
      'node', // example app.node_created, app.node_destroyed, app.node_settingsUpdated
      'user', // example app.user_signup, app.user_login
      'page', // example global.page_viewed
      'form', // example global.form_submitted
      'billing', // example app.billing_planSelected
      'role', //example app.role_created
    ],
  }),
  /* Automatically format payloads for HubSpot */
  enrichHubSpotPayload(),
  /* Load analytics logger. Logs only if localStorage.debug set */
  loggerPlugin,
  /* Load Analytic endpoints */
  ...(!shouldLoadPlugins
    ? []
    : [
        /* Load HubSpot */
        hubSpotPlugin({
          portalId: config.hubspotId,
          // Hubspot's tracking code requires a page view to be fired for the identify information to be sent
          flushOnIdentify: true,
        }),
      ]),
]

logger('plugins', plugins)

/* Initialize analytics instance */
const analytics = Analytics({
  app: 'vendia',
  version: APP_VERSION,
  debug: isDev,
  plugins: plugins,
})

/* expose analytics to window for debugging. @TODO remove */
if (isBrowser) {
  window.analytics = analytics
}

/* Check referral data */
getRefData().then((leadSrc) => {
  logger('referral', leadSrc)
})

/* export analytics instance for usage in app */
export default analytics

/* export analytics event constants for usage in app */
export { EVENTS }
