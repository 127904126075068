import debug from 'debug'

const log = debug('analytics:logger')
// __debug.enable("analytics*")

const loggerPlugin = {
  name: 'debug-logger',
  track: (pluginApi) => {
    const { payload } = pluginApi
    log(`track "${payload.event}"`)
    log('track payload', payload.properties)
  },
  page: (pluginApi) => {
    const { payload } = pluginApi
    log(`page "${payload.properties.path}"`)
    log('page payload', payload.properties)
  },
  identify: ({ payload }) => {
    log(`identify "${payload.userId}"`)
    log('identify traits', payload.traits)
  },
}

export default loggerPlugin
