import { useEffect } from 'react'

export default function HubspotMeetingForm() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    document.body.appendChild(script)
  }, [])

  return <div className='meetings-iframe-container' data-src='https://meetings.hubspot.com/bd-at-vendia?embed=true' />
}
