// Dev test schema to help quickly test schema designer after hard reload, error, etc.
export const testSchemas = [
  {
    $schema: 'http://json-schema.org/draft-07/schema#',
    title: 'undefined - JSON schema',
    description: 'Vendia Share JSON schema',
    type: 'object',
    properties: {
      ComplexEntity: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            Name: {
              type: 'string',
              description: 'user name',
              title: 'Name',
            },
            age: {
              type: 'integer',
            },
            NestedObject: {
              type: 'object',
              properties: {
                String: {
                  type: 'string',
                  title: 'String',
                },
                DeeperObject: {
                  type: 'object',
                  properties: {
                    NestedString: {
                      type: 'string',
                      title: 'Nested String',
                    },
                    Aaaa: {
                      title: 'aaaa',
                      type: 'string',
                    },
                  },
                  title: 'Deeper Object',
                  required: [],
                },
                ABoolean: {
                  title: 'A boolean',
                  type: 'boolean',
                },
              },
              required: ['String'],
              title: 'Nested Object',
            },
            AnArrayOfScalars: {
              type: 'array',
              items: {
                type: 'string',
              },
              title: 'An array of scalars',
            },
            NestedArrayOfObjects: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    description: 'user name',
                  },
                  age: {
                    type: 'integer',
                  },
                  NestedObj: {
                    type: 'object',
                    properties: {
                      String: {
                        type: 'string',
                        title: 'String!',
                      },
                      DeepObj: {
                        type: 'object',
                        properties: {
                          DeepString: {
                            type: 'string',
                            title: 'Deep string...',
                          },
                          ABoolean: {
                            title: 'A boolean',
                            description: 'sdfsdfsdf sdf sdf sdf',
                            type: 'boolean',
                          },
                        },
                        required: [],
                        title: 'Deep Obj!',
                      },
                      AnotherBoolean: {
                        title: 'Another boolean',
                        type: 'boolean',
                      },
                    },
                    required: ['AnotherBoolean'],
                    title: 'Nested obj!',
                  },
                  NestedArrayOfScalars: {
                    type: 'array',
                    items: {
                      type: 'string',
                    },
                    title: 'Nested array of scalars',
                  },
                  AFloat: {
                    title: 'A float',
                    type: 'number',
                  },
                },
                required: ['AFloat'],
              },
              title: 'Nested array of objects',
              description: 'nested!',
            },
          },
          required: ['NestedObject'],
        },
        description: 'Great for testing!',
        title: 'A complex entity',
      },
      Empty: {
        type: 'array',
        items: {
          type: 'object',
          properties: {},
          required: [],
        },
        title: 'Empty entity',
      },
      SingleScalar: {
        type: 'string',
        title: 'Single scalar entity',
      },
      SingleObject: {
        type: 'object',
        properties: {
          AString: {
            type: 'string',
            title: 'A string',
          },
        },
      },
      ArrayOfScalars: {
        type: 'array',
        items: {
          type: 'string',
        },
        title: 'Array of scalars entity',
      },
    },
    'x-vendia-indexes': {},
    'x-vendia-acls': {
      'Empty-acls': {
        type: 'Empty',
      },
      'ComplexEntity-acls': {
        type: 'ComplexEntity',
      },
    },
  },
  {
    $schema: 'http://json-schema.org/draft-07/schema#',
    title: 'flat',
    description: 'Vendia Share JSON schema',
    type: 'object',
    properties: {
      Test: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            Id: {
              title: 'id',
              type: 'string',
            },
            Quantity: {
              title: 'quantity',
              type: 'integer',
            },
            SaleItem: {
              title: 'saleItem',
              type: 'boolean',
            },
            Price: {
              title: 'price',
              type: 'number',
            },
            Color: {
              title: 'color',
              type: 'string',
            },
            Name: {
              title: 'name',
              type: 'string',
            },
            Description: {
              title: 'description',
              type: 'string',
            },
          },
          required: ['Id', 'Quantity', 'SaleItem', 'Price', 'Color', 'Name', 'Description'],
        },
        title: 'test',
      },
      Test2: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            Id: {
              title: 'id',
              type: 'string',
            },
            CustomerID: {
              title: 'Customer ID',
              type: 'string',
            },
            CustomerDescription: {
              title: 'Customer Description',
              type: 'string',
            },
            CustomerEmail: {
              title: 'Customer Email',
              type: 'string',
            },
            Plan: {
              title: 'Plan',
              type: 'string',
            },
            Product: {
              title: 'Product',
              type: 'string',
            },
            Quantity: {
              title: 'Quantity',
              type: 'integer',
            },
            Interval: {
              title: 'Interval',
              type: 'string',
            },
            Amount: {
              title: 'Amount',
              type: 'integer',
            },
            Status: {
              title: 'Status',
              type: 'string',
            },
            CreatedUTC: {
              title: 'Created (UTC)',
              type: 'string',
            },
            StartUTC: {
              title: 'Start (UTC)',
              type: 'string',
            },
            StartDateUTC: {
              title: 'Start Date (UTC)',
              type: 'string',
            },
            CurrentPeriodStartUTC: {
              title: 'Current Period Start (UTC)',
              type: 'string',
            },
            CurrentPeriodEndUTC: {
              title: 'Current Period End (UTC)',
              type: 'string',
            },
            TrialStartUTC: {
              title: 'Trial Start (UTC)',
              type: 'string',
            },
            TrialEndUTC: {
              title: 'Trial End (UTC)',
              type: 'string',
            },
            ApplicationFeePercent: {
              title: 'Application Fee Percent',
              type: 'string',
            },
            Coupon: {
              title: 'Coupon',
              type: 'string',
            },
            TaxPercent: {
              title: 'Tax Percent',
              type: 'string',
            },
            CanceledAtUTC: {
              title: 'Canceled At (UTC)',
              type: 'string',
            },
            CancelAtPeriodEnd: {
              title: 'Cancel At Period End',
              type: 'boolean',
            },
            EndedAtUTC: {
              title: 'Ended At (UTC)',
              type: 'string',
            },
            ExclusiveTaxRate: {
              title: 'Exclusive Tax Rate',
              type: 'string',
            },
            InclusiveTaxRate: {
              title: 'Inclusive Tax Rate',
              type: 'string',
            },
            CustomerName: {
              title: 'Customer Name',
              type: 'string',
            },
            AutomaticTaxEnabled: {
              title: 'Automatic Tax Enabled',
              type: 'boolean',
            },
            EmailMetadata: {
              title: 'email (metadata)',
              type: 'string',
            },
            TierMetadata: {
              title: 'tier (metadata)',
              type: 'string',
            },
            UniQuantityMetadata: {
              title: 'uni_quantity (metadata)',
              type: 'string',
            },
            NodeQuantityMetadata: {
              title: 'node_quantity (metadata)',
              type: 'string',
            },
            M3terIdMetadata: {
              title: 'm3ter_id (metadata)',
              type: 'string',
            },
          },
          required: [
            'Id',
            'CustomerID',
            'CustomerDescription',
            'CustomerEmail',
            'Plan',
            'Product',
            'Quantity',
            'Interval',
            'Amount',
            'Status',
            'CreatedUTC',
            'StartUTC',
            'StartDateUTC',
            'CurrentPeriodStartUTC',
            'CurrentPeriodEndUTC',
            'TrialStartUTC',
            'TrialEndUTC',
            'ApplicationFeePercent',
            'Coupon',
            'TaxPercent',
            'CanceledAtUTC',
            'CancelAtPeriodEnd',
            'EndedAtUTC',
            'ExclusiveTaxRate',
            'InclusiveTaxRate',
            'CustomerName',
            'AutomaticTaxEnabled',
            'EmailMetadata',
            'TierMetadata',
            'UniQuantityMetadata',
            'NodeQuantityMetadata',
            'M3terIdMetadata',
          ],
        },
        description: 'test2 description',
        title: 'test2',
      },
      Test3: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            Test: {
              title: 'test',
              description: 'test test test',
              type: 'string',
            },
          },
          required: ['Test'],
        },
        title: 'test3',
      },
    },
    'x-vendia-indexes': {},
    'x-vendia-acls': {
      'Test-acls': {
        type: 'Test',
      },
      'Test2-acls': {
        type: 'Test2',
      },
      'Test3-acls': {
        type: 'Test3',
      },
    },
  },
]
