// @ts-strict-ignore
import { OrgOnboardingStatus } from '@vendia/management-api-types'
import { useLocation } from 'react-router'
import { GetOrg } from 'src/utils/hooks/use-get-org'

import Button from '../buttons/button'
import BasicModal from '../modals/modal.basic'
import { ModalState, OrgModalInner, OrgOnboardingStep } from './org-onboarding-modal'

export function StepIntro({
  org,
  emailDomain,
  setModalState,
  dismissModal,
}: {
  org?: GetOrg
  emailDomain: string
  setModalState: (state: Partial<ModalState>) => void
  dismissModal: () => void
}) {
  const location = useLocation()
  const isUniJoin = location.pathname.endsWith('/join')
  const showWelcomeMessage = org.onboardingStatus === OrgOnboardingStatus.OnboardingNotNotified

  // Default: they've clicked the button on settings page to create an Org themselves
  let intro = `Let's set up your Vendia Organization!`
  if (isUniJoin) {
    // They're trying to join a Uni, but need to create an Org first
    intro = `You'll need to set up an Organization before you can join this Uni. It will only take a few minutes!`
  } else if (showWelcomeMessage) {
    // We're showing them this modal as soon as they log in to Share for the first time
    intro = `Welcome to Vendia! We recommend taking a few minutes to set up an Organization.`
  }

  return (
    <>
      <OrgModalInner>
        <div className='mb-6 flex max-w-4xl flex-col items-center justify-center gap-8 sm:p-6'>
          <div className='flex flex-col gap-3'>
            <p className='font-bold'>{intro}</p>
          </div>
          <div className='flex gap-12 text-center'>
            <div className='flex w-full flex-col items-center justify-center gap-3'>
              <img
                className='h-40 w-40'
                src='/images/orgs-people-connected.svg'
                alt='stylized network of users connected by dotted lines'
              />
              <div>Easily invite coworkers sharing your company email domain.</div>
            </div>
            <div className='flex w-full flex-col items-center justify-center gap-3'>
              <img className='h-40 w-40' src='/images/name-tag.svg' alt='stylized name tag' />
              <div>Collaborate on data safely with role-based access controls.</div>
            </div>
            <div className='flex w-full flex-col items-center justify-center gap-3'>
              <img className='h-40 w-40' src='/images/handshake.svg' alt='stylized handshake' />
              <div>Securely share data with partners in other companies.</div>
            </div>
          </div>
        </div>
      </OrgModalInner>
      <BasicModal.Footer>
        <Button key='close' kind='secondary' className='w-36' onClick={dismissModal}>
          Maybe later...
        </Button>
        <Button
          key='create'
          kind='primary'
          className='w-40'
          onClick={() => setModalState({ step: OrgOnboardingStep.SET_NAME })}
        >
          Create Organization
        </Button>
      </BasicModal.Footer>
    </>
  )
}
