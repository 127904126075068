import { Uni } from '@vendia/management-api-types'
import { ReactNode, Suspense, useContext } from 'react'
import { matchPath, Outlet, useLocation, useParams } from 'react-router-dom'
import DefaultLayout from 'src/layouts/default.layout'
import OrgHeader from 'src/pages/org/org-header'
import OrgNav from 'src/pages/org/org-nav'

import { ContentWindow } from '../components/containers/content-window'
import { PageLoader } from '../components/loaders/page-loader'
import { UniSideNav } from '../components/navigation/side-nav-uni'
import PageHeader from '../components/page-header'
import TransactionNotifications from '../pages/files/transaction-notifications'
import UniSettingsNav from '../pages/uni-settings/uni-settings-nav'
import { UniPendingPlaceholder } from '../pages/uni-view/uni-pending-placeholder'
import { useGetUni } from '../utils/hooks/use-get-uni'
import { isUniOrNodePending } from '../utils/uni'

export const asyncLoader = (Component: (props: any) => ReactNode) => {
  return function asyncLoader(props: any) {
    const loader = (
      <div className='grid place-items-center'>
        <PageLoader />
      </div>
    )
    return (
      <Suspense fallback={loader}>
        <Component {...props} />
      </Suspense>
    )
  }
}

export function LayoutWithNavbar() {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  )
}

export function LayoutWithNavbarNoUniDropdown() {
  return (
    <DefaultLayout showUniDropdown={false}>
      <Outlet />
    </DefaultLayout>
  )
}

export function LayoutNoNavbar() {
  return (
    <DefaultLayout showNavBar={false}>
      <Outlet />
    </DefaultLayout>
  )
}

export const UniSideNavLayout = () => {
  const { node: nodeName } = useParams()
  const getUniQuery = useGetUni()
  const uni = getUniQuery?.data?.getUni as Uni
  const node = uni?.nodes?.find((n) => n.name === nodeName)
  const isPending = isUniOrNodePending(uni, node)
  if (!uni) {
    return (
      <ContentWindow sideNav={<UniSideNav />}>
        <div className='grid place-items-center'>
          <PageLoader />
        </div>
      </ContentWindow>
    )
  }
  if (isPending) {
    return (
      <ContentWindow sideNav={<UniSideNav />}>
        <UniPendingPlaceholder uni={uni} node={node} />
      </ContentWindow>
    )
  }
  return (
    <ContentWindow sideNav={<UniSideNav />}>
      <Outlet />
    </ContentWindow>
  )
}

export default function UniSettingsLayout() {
  const { node: nodeName } = useParams()
  const getUniQuery = useGetUni()
  const uni = getUniQuery?.data?.getUni as Uni
  const node = uni?.nodes?.find((n) => n.name === nodeName)
  const isPending = isUniOrNodePending(uni, node)

  const usableWhilePending = ['/uni/:id/:nodeId/settings/schema', '/uni/:id/:nodeId/settings/uni-settings']
  const { pathname } = useLocation()
  const isUsableWhilePending = usableWhilePending.some((path) => Boolean(matchPath(path, pathname)))

  if (!uni) {
    return (
      <ContentWindow sideNav={<UniSettingsNav uniPending />}>
        <div className='grid place-items-center'>
          <PageLoader />
        </div>
      </ContentWindow>
    )
  }

  return (
    <ContentWindow sideNav={<UniSettingsNav uniPending={isPending} />}>
      {isPending && !isUsableWhilePending ? (
        <UniPendingPlaceholder uni={uni} node={node} />
      ) : (
        <>
          <PageHeader title='Settings' />
          <div className={'flex flex-grow'}>
            <div className='flex flex-grow flex-col overflow-x-auto p-6'>
              <Outlet />
            </div>
          </div>
          <TransactionNotifications />
        </>
      )}
    </ContentWindow>
  )
}

export function OrgsLayout() {
  return (
    <ContentWindow sideNav={<OrgNav />}>
      <OrgHeader />
      <div className={'flex flex-grow'}>
        <div className='flex flex-grow flex-col p-6'>
          <Outlet />
        </div>
      </div>
    </ContentWindow>
  )
}
