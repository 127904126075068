import { FieldApi, FieldValidators, FormApi, ReactFormApi, useField } from '@tanstack/react-form'
import clsx from 'clsx'
import { ReactElement, ReactNode, RefObject, useRef } from 'react'
import Button from 'src/components/buttons/button'

import { FormFieldProps } from './types'

export type FieldListProps<TProps, TValue> = TProps &
  Omit<FormFieldProps<TValue>, 'defaultValue' | 'children'> & {
    fieldLabel?: string
    addNewText?: string | ReactNode
    fieldNameKey?: string
    fieldValidators?: FieldValidators<any, any, any, any, TValue>
    buttonClassName?: string
    defaultValue?: TValue
  }

export type FieldListRenderProps<TElement, TValue> = {
  name: string
  label: ReactNode
  fieldRef?: RefObject<TElement>
  arrayFieldIndex: number
  arrayFieldName: string
  validators?: FieldValidators<any, any, any, any, TValue>
}

function FieldList<TElement, TProps, TValue>({
  name,
  fieldNameKey,
  form,
  label,
  fieldLabel,
  validators,
  fieldValidators,
  description,
  defaultValue = [] as TValue,
  addNewText,
  wrapperClassName,
  buttonClassName,
  children,
}: { children: (p: FieldListRenderProps<TElement, TValue>) => ReactElement<any, any> } & FieldListProps<
  TProps,
  TValue
>) {
  const inputRef = useRef<TElement>(null)

  const field = useField({ name, form, defaultValue, mode: 'array', validators })

  // TODO - add default validators
  // if (hasDuplicates(value, currentValues)) {
  //   return {
  //     isValid: false,
  //     message: `Duplicate value ${value}`,
  //   }
  // }

  // function hasDuplicates(value: string) {
  //   const hasDupes = field.state.value.filter(
  //     (item: string, index: number) => field.state.value.indexOf(item) !== index,
  //   )
  //   return hasDupes.includes(value)
  // }

  async function addField() {
    await field.validate('change')
    if (field.state.meta.errors?.length === 0) {
      field.pushValue('')
    }
    setTimeout(() => (inputRef.current as any)?.focus(), 200)
  }

  async function removeField(index: number) {
    field.removeValue(index)
    setTimeout(() => (inputRef.current as any)?.focus(), 200)
  }

  const lastIndex = field.state.value?.length - 1

  return (
    <div className={wrapperClassName}>
      {label && <h2 className='mb-2 font-semibold capitalize'>{label}</h2>}
      {description && <p className='mb-2 text-sm text-gray-500'>{description}</p>}
      {field.state.value?.map((val: string, fieldIndex: number) => {
        return (
          <div key={fieldIndex} className='flex w-full items-center'>
            {children({
              label: fieldLabel ?? label,
              fieldRef: fieldIndex === lastIndex ? inputRef : undefined,
              name: fieldNameKey ? `${name}[${fieldIndex}].${fieldNameKey}` : `${name}[${fieldIndex}]`,
              arrayFieldName: name,
              arrayFieldIndex: fieldIndex,
              validators: fieldValidators,
            })}
            <Button kind='link' icon='trash' onClick={() => removeField(fieldIndex)} />
          </div>
        )
      })}
      <Button
        kind='secondary'
        onClick={addField}
        className={clsx('flex-start align-center text-lg! mt-4 flex w-full py-1', buttonClassName)}
      >
        {addNewText || label || 'Add'}
      </Button>
    </div>
  )
}

export default FieldList
