import { ReactElement, useState } from 'react'

import { UploadFileStatusContext, VendiaFileUpload } from './upload-file-status-context'

const UploadFileStatusProvider = ({ children }: { children: ReactElement }) => {
  const [uploadingFiles, setUploadingFiles] = useState<VendiaFileUpload[]>([])

  return (
    <UploadFileStatusContext.Provider value={{ uploadingFiles, setUploadingFiles }}>
      {children}
    </UploadFileStatusContext.Provider>
  )
}

export default UploadFileStatusProvider
