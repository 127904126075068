import cx from 'clsx'
import React, { ComponentProps } from 'react'

import styles from './alert.module.css'

export default function Alert({ className, title, children, ...rest }: ComponentProps<'div'>) {
  return (
    <div className={cx(styles.alert, className)} {...rest}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.body}>{children}</div>
    </div>
  )
}

Alert.displayName = 'Alert'
