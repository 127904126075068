// Copied and updated from @analytics/router-utils
export function onRouteChange(callback: (newRoutePath: string) => void) {
  if (typeof document === 'undefined') return
  var _window = window,
    addEventListener = _window.addEventListener,
    history = _window.history,
    location = _window.location // Set initial pathname

  var previousPath = location.pathname // Observe native navigation

  addEventListener('popstate', function () {
    callback(location.pathname)
  }) // Observe manual navigation

  var methods = ['push', 'replace']
  methods.map(function (type) {
    var state = `${type}State` as keyof History
    var historyState = history[state]

    // @ts-expect-error works fine
    history[state] = function () {
      var _arguments = arguments
      var updatedPath = trimPath(arguments[2])

      if (previousPath !== updatedPath) {
        // Set updated pathname
        previousPath = updatedPath // Debounce delay for SPA

        setTimeout(function () {
          return callback(_arguments[2])
        }, 0)
      }

      return historyState.apply(history, arguments)
    }
  })
} // Trim hash and params off partial url path

function trimPath(urlPath: string) {
  var hasParam = urlPath.indexOf('?')
  if (hasParam > -1) urlPath = urlPath.slice(0, hasParam)
  var hasHash = urlPath.indexOf('#')
  if (hasHash > -1) urlPath = urlPath.slice(0, hasHash)
  return urlPath
}
