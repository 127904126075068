import config from 'src/config'

const { isProd } = config
/*
Automatically set user_id and anon_id for HubSpot identify
*/
export default function enrichHubSpotCalls() {
  return {
    name: 'hubspot-enrich',
    // Enrich payload for only HubSpot plugin identify
    'identify:hubspot': ({ payload, instance }) => {
      const { traits, userId } = payload
      // console.log('hubspot payload', payload)
      const anonId = traits['custom:anonId'] || instance.user('anonymousId')
      const id = userId || traits.sub || instance.user('userId')
      return {
        ...payload,
        traits: {
          ...traits,
          ...{
            // Mapped HubSpot Values
            user_id: id,
            anon_id: anonId,
          },
        },
      }
    },
    /* Send customer lead src info to HubSpot after login */
    identifyEnd: ({ payload }) => {
      const { traits } = payload
      if (traits && traits.email) {
        sendLeadInfoToHubSpot(traits.email, traits)
      }
    },
  }
}

function formatHubSpotDate(iso) {
  if (typeof iso !== 'string') return iso
  // Convert ISO date to midnight for hubspot "date picker" field... https://developers.hubspot.com/docs/api/faq
  const date = new Date(iso.substring(0, 10))
  const ms = date.getTime()
  return ms
}

/**
 * Format properties into shape HubSpot understands
 * @param {object} data - lead traits
 * @returns {object}
 */
export function formatLeadSourceFields(data = {}) {
  const {
    firstName,
    firstname,
    lastName,
    lastname,
    jobTitle,
    jobtitle,
    company,
    phoneNumber,
    phone,
    custom_first_visitor_source,
    custom_first_visitor_date,
    custom_first_visitor_entry,
  } = data

  return {
    ...(custom_first_visitor_source ? { custom_first_visitor_source } : {}),
    ...(custom_first_visitor_date ? { custom_first_visitor_date: formatHubSpotDate(custom_first_visitor_date) } : {}),
    ...(custom_first_visitor_entry ? { custom_first_visitor_entry } : {}),
    // Mapped to hubspot field names https://app.hubspot.com/property-settings/8766854/properties
    ...(firstName || firstname ? { firstname: firstName || firstname } : {}),
    ...(lastName || lastname ? { lastname: lastName || lastname } : {}),
    ...(company ? { company } : {}),
    ...(phoneNumber || phone ? { phone: phoneNumber || phone } : {}),
    ...(phoneNumber || phone ? { phone: phoneNumber || phone } : {}),
  }
}

export function sendLeadInfoToHubSpot(email, traits) {
  if (!traits || !Object.keys(traits).length) {
    return
  }
  const leadSrcTraits = formatLeadSourceFields(traits)
  // console.log('leadSrcTraits', leadSrcTraits)
  if (!Object.keys(leadSrcTraits).length) {
    return
  }

  if (!isProd) {
    console.log(`HubSpot email`, email)
    console.log(`HubSpot traits`, leadSrcTraits)
    return
  }

  // TODO change to /api/hs after first deployment
  return fetch('https://4cia6q5l75.execute-api.us-east-1.amazonaws.com/prod/set-contact-details', {
    method: 'POST',
    body: JSON.stringify({
      email,
      properties: leadSrcTraits,
    }),
  })
}
