const WILDCARD = '*' as const

export interface UniResource {
  name: string
  subdomain: string
  domain: string
  ext: string
  nodeName: string
}

export const getUniResourceFromString = (string: string): UniResource => {
  const segments = string.split('#')
  if (segments.length <= 0 || segments.length > 2) {
    throw new Error(`Invalid argument — malformed Node name specifier: ${string}`)
  }

  const uniChunk = segments.at(0)
  const nodePart = segments.at(-1)
  const nodeName = segments.length === 2 ? nodePart ?? WILDCARD : WILDCARD

  const uniParts = uniChunk?.split('.')
  if (!uniParts || uniParts.length < 3 || uniParts.length > 4) {
    throw new Error(`Invalid argument — Uni has invalid domain ${uniChunk}`)
  }

  let subdomain
  let domain
  let ext
  const name = uniParts.at(0)
  if (uniParts.length === 3) {
    subdomain = WILDCARD
    domain = uniParts.at(1)
    ext = uniParts.at(2)
  } else {
    subdomain = uniParts.at(1)
    domain = uniParts.at(2)
    ext = uniParts.at(3)
  }
  return {
    name: name ?? WILDCARD,
    subdomain: subdomain ?? WILDCARD,
    domain: domain ?? WILDCARD,
    ext: ext ?? WILDCARD,
    nodeName,
  }
}

export const namespaceToString = (namespace?: UniResource | null): string => {
  if (!namespace) {
    return ''
  }
  if (namespace.subdomain === '*') {
    return `.${namespace.domain}.${namespace.ext}`
  }
  return `.${namespace.subdomain}.${namespace.domain}.${namespace.ext}`
}
