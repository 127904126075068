import { useUpdateOrgLogo } from 'src/pages/org/org-overview.page'
import { GetOrg } from 'src/utils/hooks/use-get-org'

import Button from '../buttons/button'
import DevOnly from '../dev/dev-only'
import Icon from '../icons/icon'
import OrgLogoBadge from '../messages/org-logo-badge'
import BasicModal from '../modals/modal.basic'
import { ModalState, OrgModalInner, OrgOnboardingStep } from './org-onboarding-modal'

export function StepOrgLogo({
  org,
  setModalState,
  dismissModal,
}: {
  org?: GetOrg
  setModalState: (state: Partial<ModalState>) => void
  dismissModal: () => void
}) {
  const { uploadLogoMutation, dropzone } = useUpdateOrgLogo({
    onSuccess: () => setModalState({ step: OrgOnboardingStep.WRAP_UP }),
  })

  return (
    <>
      <OrgModalInner>
        <div className='flex flex-col items-center gap-4'>
          <div className='flex max-w-4xl flex-col gap-2'>
            Next, upload a logo to help identify your organization&apos;s nodes in shared Unis. If possible, we
            recommend selecting a version of your logo that is optimized for display inside of a circle-shaped badge - a
            1:1 aspect ratio is ideal!
          </div>
          <div className='flex justify-center gap-16 p-4 px-8'>
            <div className='flex flex-col items-center gap-2'>
              <div className='font-bold'>Good</div>
              <div>
                <OrgLogoBadge src='/logo.svg' alt='Good logo example' />
              </div>
            </div>
            <div className='flex flex-col items-center gap-2'>
              <div className='font-bold'>Better!</div>
              <div>
                <OrgLogoBadge src='/logo-symbol.svg' alt='Better logo example' />
              </div>
            </div>
          </div>
          <div
            {...dropzone.getRootProps()}
            className='border-purple-1300 bg-uibg-0 flex w-2/3 items-center justify-center rounded-lg border-2 border-dashed p-6'
          >
            <div className='flex flex-col items-center'>
              <div className='mb-2 w-16'>
                <img src='/images/upload-cloud.svg' alt='Upload file' />
              </div>
              <input {...dropzone.getInputProps()} disabled={uploadLogoMutation.isPending} />
              {/* Somehow this works perfectly when these labels target an input id that doesn't exist..? */}
              {/* Adding an id to the input causes a bug where onDrop is not called the 2nd time a file is dropped */}
              {/* https://github.com/react-dropzone/react-dropzone/issues/1097 */}
              <label htmlFor={'csv-file-input'} className='text-center'>
                Drag and drop your image file here
              </label>
              <span className='my-2 uppercase'>or</span>
              <label
                htmlFor={'csv-file-input'}
                className='bg-purple-1200 flex cursor-pointer items-center rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-800'
              >
                {uploadLogoMutation.isPending && <Icon name='refresh' size={'xxs'} isSpinning className='mr-2' />}
                Upload image
              </label>
            </div>
          </div>
        </div>
      </OrgModalInner>

      <BasicModal.Footer>
        <Button
          kind='secondary'
          size={'small'}
          disabled={uploadLogoMutation.isPending}
          className='w-36'
          onClick={dismissModal}
        >
          Cancel
        </Button>
        <DevOnly>
          <Button
            kind='secondary'
            size={'small'}
            className='w-36'
            onClick={() => setModalState({ step: OrgOnboardingStep.SET_NAME })}
          >
            Previous
          </Button>
        </DevOnly>
        <Button
          kind='secondary'
          size={'small'}
          className='w-36'
          disabled={uploadLogoMutation.isPending}
          onClick={() => setModalState({ step: OrgOnboardingStep.WRAP_UP })}
        >
          Skip this step
        </Button>
      </BasicModal.Footer>
    </>
  )
}
