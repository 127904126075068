const inputValidation = {
  isEmail: {
    pattern: /^([\w_\.\-\+])+@([\w\-]+\.)+([\w]{2,10})+$/,
    message: 'Please enter a valid email address',
  },
  isPhone: {
    pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3,4})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
    message: 'Please enter a valid phone number',
  },
  isURL: {
    pattern: /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[:?\d]*)\S*$/,
    message: 'Please enter a valid url',
  },
  isDomain: {
    pattern: /^[a-zA-Z0-9_-]+\.[.a-zA-Z0-9_-]+$/,
    message: 'Please enter a valid domain',
  },
  isZipCode: {
    pattern: /^\d{5}([ \-]?\d{4})?$/,
    message: 'Please enter a valid zip code',
  },
  isUserName: {
    pattern: /^[\w\-]{4,18}$/,
    message: 'Username must be between 4 and 18 characters',
  },
  // A bit arbitrary, but want to prevent empty/1-2 character names that are generally not a great idea
  isRoleName: {
    pattern: /^.{3,76}$/,
    message: 'Role name must be between 3 and 36 characters long',
  },
  isFullName: {
    pattern:
      /(^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+)\s([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+)$/,
    message: 'Please enter your first and last name',
  },
  // TODO: not using this now that we convert to valid key, but maybe
  // we'll let them customize the keys as an advanced option later?
  // Ported from validate_json_schema_keys python compiler code
  isJsonSchemaKey: {
    pattern: /^[A-Za-z][0-9A-Za-z]+$/,
    message: 'Names must begin with a letter and can only include letters and numbers',
  },
  isGenericNonEmptyString: {
    pattern: /^.{1,255}$/,
    message: 'Please enter a valid value (255 characters max)',
  },
  // future use https://github.com/jonschlinkert/is-git-url/blob/master/index.js
} as { [key: string]: { pattern: RegExp; message: string } }

export default inputValidation
