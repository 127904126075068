import { useMutation, UseQueryResult } from '@tanstack/react-query'
import { LakehouseDataProductInput, Uni } from '@vendia/management-api-types'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ButtonIconAction } from 'src/components/buttons/button-icon-action'
import Card, { CardRule } from 'src/components/containers/card'
import { ProductPolicies } from 'src/components/lakehouse/product-policies'
import Alert from 'src/components/messages/alert'
import HoverableTooltip from 'src/components/messages/hoverable-tooltip'
import ConfirmationModal from 'src/components/modals/confirmation-modal'
import { AWS_REGIONS } from 'src/utils/csp/regions'
import useApi from 'src/utils/hooks/use-api'
import { LakehouseProduct } from 'src/utils/lakehouse/types'
import notify from 'src/utils/notify'

export type ExecutionStatus = 'RUNNING' | 'SUCCEEDED' | 'FAILED' | 'TIMED_OUT' | 'ABORTED' | 'PENDING_REDRIVE'

const failureStatuses = ['FAILED', 'TIMED_OUT', 'ABORTED'] as ExecutionStatus[]

export interface Job {
  source: JobStatus
  regions: {
    [key: string]: JobStatus
  }
}

// Copy/pasted from API
export interface JobStatus {
  status: ExecutionStatus
  startTime: string
  stopTime: string
  error?: string
  errorType?: string
}

// organize executions by data product key
export interface JobsByDataProductKey {
  [key: string]: {
    source: JobStatus
    regions: {
      [key: string]: JobStatus
    }
  }[]
}

export const DataProductCard = ({
  uni,
  product,
  productIndex,
  setShareDataProduct,
  lakehouseJobsQueryResult,
  isLakehouseReady,
}: {
  uni: Uni
  product: LakehouseDataProductInput
  productIndex: number
  setShareDataProduct: (index: number) => void
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
  isLakehouseReady: boolean
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showSyncModal, setShowSyncModal] = useState(false)
  const jobs = lakehouseJobsQueryResult.data?.getLakehouseJobs?.jobs?.[product.key]
  const dataProduct = { ...product, ...product.tableDefinitions?.[0] } as LakehouseProduct
  const api = useApi()

  const { refetch: refetchLakehouseJobs } = lakehouseJobsQueryResult

  const lakehouseIngestionSFNMutation = useMutation({
    mutationFn: ({ uniName, dataProductKey }: { uniName: string; dataProductKey: string }) =>
      api.invokeLakehouseIngestionSFN({ uniName, dataProductKey }),

    onError: (error) => notify.error(`Error syncing data product: ${error}`),
    onSuccess: (response) => {
      if (response.errors) {
        notify.error(`${response.errors[0].message}`)
        return
      }
      if (!response.invokeLakehouseIngestionSFN) {
        notify.error('Error syncing data product')
        return
      }
      notify.success(`Successfully initiated sync for: ${product.name}`)
      setShowSyncModal(false)
      refetchLakehouseJobs()
    },
  })

  return (
    <Card key={product.key} className='w-full' padding='none'>
      <div className='flex w-full justify-between gap-6 p-6'>
        <div className='flex flex-grow flex-col gap-4'>
          <div className='flex items-center gap-6'>
            <img src='/images/data-product.svg' alt='Data set illustration' className='size-10' />
            <div className='flex flex-col gap-1'>
              {isLakehouseReady ? (
                <Link to={`../product/${product.name}`} className='text-link text-lg font-bold'>
                  {product.name}
                </Link>
              ) : (
                <div className='text-lg font-bold text-gray-400'>{product.name}</div>
              )}
              <div className='text-neutral-10 text-balance text-xs'>
                {product.regions
                  .map((r) => AWS_REGIONS.find((region) => region.value === r)?.displayAlt || r)
                  .join(', ')}
              </div>
              <div className='mt-1'>
                <DataProductSyncStatus
                  product={product}
                  jobs={jobs}
                  isLakehouseReady={isLakehouseReady}
                  lakehouseJobsQueryResult={lakehouseJobsQueryResult}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex items-center gap-2'>
          <ButtonIconAction
            icon={'refresh'}
            label='Sync data product'
            onClick={() => setShowSyncModal(true)}
            disabled={
              !isLakehouseReady ||
              jobs?.[0]?.source.status === 'RUNNING' ||
              product.regions.some((r) => jobs?.[0]?.regions[r]?.status === 'RUNNING')
            }
          />
          <ButtonIconAction
            icon={'share'}
            label='Share data product'
            onClick={() => {
              setShareDataProduct(productIndex)
            }}
            disabled={!isLakehouseReady}
          />
          <ButtonIconAction
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            label='Toggle sharing rule details'
            onClick={() => setExpanded(!expanded)}
          />
        </div>
      </div>
      {expanded ? (
        <>
          <CardRule />
          <ProductPolicies product={dataProduct} canEdit={true} isLakehouseReady={isLakehouseReady} />
        </>
      ) : null}
      <ConfirmationModal
        isOpen={showSyncModal}
        onClose={() => setShowSyncModal(false)}
        title={`Confirm sync for ${product?.name}`}
        onSubmit={() => lakehouseIngestionSFNMutation.mutate({ uniName: uni.name, dataProductKey: product.key })}
        isSubmitting={lakehouseIngestionSFNMutation.isPending}
        actionButtonType={`primary`}
        actionButtonText={`Sync`}
        confirmationText={'sync'}
      >
        <div className='grid gap-4'>
          <p>
            Immediately begin syncing the latest data from your configured source to the <strong>{product.name}</strong>{' '}
            data product.
          </p>
          <Alert className='text-sm italic text-gray-500'>
            Note: Once started, the sync process cannot be cancelled or undone.
          </Alert>
        </div>
      </ConfirmationModal>
    </Card>
  )
}

const DataProductSyncStatus = ({
  product,
  jobs,
  isLakehouseReady,
  lakehouseJobsQueryResult,
}: {
  product: LakehouseDataProductInput
  jobs?: JobsByDataProductKey[string]
  isLakehouseReady: boolean
  lakehouseJobsQueryResult: UseQueryResult<{ getLakehouseJobs: { jobs?: JobsByDataProductKey } }, unknown>
}) => {
  const lastSync = jobs?.[0]

  const isLastSyncRunning =
    lastSync?.source.status === 'RUNNING' || product.regions.some((r) => lastSync?.regions[r]?.status === 'RUNNING')

  const isLastSyncSuccess =
    !isLastSyncRunning &&
    lastSync?.source.status === 'SUCCEEDED' &&
    product.regions.every((r) => lastSync.regions[r]?.status === 'SUCCEEDED')

  let lastSuccessfulSync = lastSync
  if (!isLastSyncSuccess) {
    lastSuccessfulSync = jobs?.find((job) => {
      return job.source.status === 'SUCCEEDED' && product.regions.every((r) => job.regions[r]?.status === 'SUCCEEDED')
    })
  }

  const lastSuccessfulSyncTime = lastSuccessfulSync
    ? Object.values(lastSuccessfulSync?.regions).reduce((acc, region) => {
        if (dayjs(region.stopTime).isAfter(dayjs(acc))) {
          return region.stopTime
        }
        return acc
      }, lastSuccessfulSync.source.stopTime)
    : null

  const syncfailureTime =
    lastSync && !isLastSyncSuccess
      ? Object.values(lastSync?.regions).reduce((acc, region) => {
          if (failureStatuses.includes(region.status) && dayjs(region.stopTime).isBefore(dayjs(acc))) {
            return region.stopTime
          }
          return acc
        }, lastSync.source.stopTime)
      : null

  return (
    <div className='text-neutral-10 flex flex-col gap-1 text-xs'>
      {lakehouseJobsQueryResult.isLoading ? (
        <>
          <div className='flex gap-1'>
            <div>Last successful sync: </div>
            <div className='text-gray-500'>loading...</div>
          </div>
          <div className='flex gap-1'>
            <span>Last sync status: </span>
            <span className='text-gray-500'>loading...</span>
          </div>
        </>
      ) : (
        <>
          {isLakehouseReady && (
            <div className='flex gap-1'>
              <div>Last successful sync: </div>
              {lastSuccessfulSyncTime ? (
                <HoverableTooltip
                  align='start'
                  content={
                    <span className='font-semibold'>
                      Sync succeeded on {dayjs(lastSuccessfulSyncTime).toDate().toLocaleString()}
                    </span>
                  }
                  side='bottom'
                  sideOffset={10}
                  isModern
                >
                  <span className='font-semibold'>{dayjs(lastSuccessfulSyncTime).fromNow()}</span>
                </HoverableTooltip>
              ) : (
                <div className='font-semibold'>Never</div>
              )}
            </div>
          )}
          {lastSync && isLastSyncSuccess ? (
            <div className='text-success-13 font-semibold'>
              <span>Last sync status: </span>
              <span className='text-success-12 font-bold'>All regions synced</span>
            </div>
          ) : null}
          {lastSync && isLastSyncRunning ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1'>
                  <div className='mb-2 font-semibold'>Sync progress</div>
                  <div className='flex justify-between gap-8'>
                    <div>Syncing latest data from source:</div>
                    {lastSync?.source.status === 'RUNNING' ? (
                      <span className='text-information-10 font-semibold'>Syncing...</span>
                    ) : (
                      <span className='text-success-13 font-semibold'>Complete!</span>
                    )}
                  </div>
                  {Object.entries(lastSync?.regions).map(([region, status]) => {
                    return (
                      <div key={region} className='flex justify-between gap-8 text-wrap'>
                        <div>
                          Syncing your data product to <strong>{region}</strong>:
                        </div>
                        {status.status === 'RUNNING' ? (
                          <span className='text-information-10 font-semibold'>Syncing...</span>
                        ) : (
                          <span className='text-success-13 font-semibold'>Complete!</span>
                        )}
                      </div>
                    )
                  })}
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-information-13 animate-pulse font-semibold'>
                <span>Last sync status: </span>
                <span className='text-information-12 font-bold'>Syncing...</span>
              </div>
            </HoverableTooltip>
          ) : null}
          {lastSync && !isLastSyncRunning && !isLastSyncSuccess ? (
            <HoverableTooltip
              align='start'
              content={
                <div className='flex max-w-xl flex-col gap-1 text-wrap'>
                  {syncfailureTime ? (
                    <div className='mb-2 font-semibold'>Sync failed {dayjs(syncfailureTime).fromNow()}</div>
                  ) : null}
                  {lastSync?.source.error && (
                    <div>
                      {/* we could parse errors from source.error and show certain messages if any prove to be useful */}
                      An error occurred during our attempt to pull from your data source.
                    </div>
                  )}
                  {Object.entries(lastSync?.regions).map(([region, status]) => {
                    if (failureStatuses.includes(status.status)) {
                      return (
                        <div key={region}>
                          A problem occurred during our attempt to update your data product in <strong>{region}</strong>
                          .
                        </div>
                      )
                    }
                  })}
                </div>
              }
              side='bottom'
              sideOffset={10}
              isModern
            >
              <div className='text-error-8'>
                <span>Last sync status: </span>
                {lastSync?.source.error ? (
                  <span className='text-error-8 font-bold'>Source sync failed</span>
                ) : (
                  <span className='text-error-8 font-bold'>1 or more regions failed</span>
                )}
              </div>
            </HoverableTooltip>
          ) : null}
        </>
      )}
    </div>
  )
}
