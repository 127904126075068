import { getUniResourceFromString, UniResource } from '@vendia/management-api-rbac'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import StepButtons from 'src/components/flows/step-buttons'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import { isRequiredValidator } from 'src/utils/form/validation'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import { useFirstFocus } from 'src/utils/hooks/use-first-focus'
import { LakehouseFlowStepValues } from 'src/utils/lakehouse/types'
import { lakehouseFlowFieldValidator } from 'src/utils/lakehouse/validators'
import { selectedRoleState } from 'src/utils/state'

import { CreateFlow, StepId } from '../config'
import { namespaceToString } from '../uni-resource'

export const StepNameApplication: StepComponent<LakehouseFlowStepValues> = ({ context }) => {
  const form = context.form
  const uniNameRef = useFirstFocus()
  const [, setSearchParams] = useSearchParams({ flow: 'select-type' })
  const [eligibleNamespaces, setEligibleDomains] = useState<UniResource[]>([])
  const [selectedRole] = useRecoilState(selectedRoleState)
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()

  useEffect(() => {
    const role = getCurrentVendiaUserQuery?.data?.getUser?.roles?.find((r) => r.name === selectedRole.name)

    if (!role) {
      getCurrentVendiaUserQuery?.data?.getUser?.roles?.find((r) => r.isDefault || r.name === 'default')
    }

    if (role) {
      const eligibleUniResources: UniResource[] = role.capabilities
        // grab all create capabilities
        .filter((capability) => ['UNI_ALL', 'UNI_CREATE'].includes(capability.action))
        // transform UniResource(*.unis.domain.com#*) into .unis.domain.com
        .map((capability) => {
          return capability.resources
            .filter((resource) => resource.startsWith('UniResource'))
            .map((resource) => resource.replace('UniResource(', ''))
            .map((resource) => resource.replace(')', ''))
        })
        // smoosh arrays
        .flat()
        // remove duplicates
        .reduce((deduplicatedArray, currentItem) => {
          if (deduplicatedArray.includes(currentItem)) {
            return deduplicatedArray
          }
          return deduplicatedArray.concat([currentItem])
        }, [] as string[])
        // sort to ensure that custom namespaces show up first
        .sort((a, b) => {
          if (a.includes('vendia.net')) {
            return 1
          } else if (b.includes('vendia.net')) {
            return -1
          }
          return 0
        })
        .map((resourceString) => getUniResourceFromString(resourceString))
      setEligibleDomains(eligibleUniResources)
    }
  }, [getCurrentVendiaUserQuery?.data?.getUser?.roles, selectedRole.name])

  const hasOnlyAccessToMultiTenantNamespace =
    eligibleNamespaces.length === 1 &&
    eligibleNamespaces[0].subdomain &&
    eligibleNamespaces[0].domain === 'vendia' &&
    eligibleNamespaces[0].ext === 'net'

  const hasOnlyAccessToSingleNamespace =
    eligibleNamespaces.length === 1 && eligibleNamespaces[0].subdomain && eligibleNamespaces[0].domain !== 'vendia'

  if (hasOnlyAccessToSingleNamespace) {
    form.setFieldValue('namespace', namespaceToString(eligibleNamespaces[0]))
  }

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='flex w-full flex-1 flex-col items-center p-4 lg:px-8'>
          <StepContentHeader
            hasMarginY
            centered
            large
            title='Name your application'
            description='You will need to name this set of data products so you can easily distribute it to clients and customers. You can update this name anytime.
'
          />
          <div className='mb-8 mt-16 flex w-full max-w-4xl flex-col justify-center gap-6'>
            <TextField
              name='alias'
              label='Application Name'
              description='The name of the application.'
              ref={uniNameRef}
              validators={lakehouseFlowFieldValidator({
                stepId: StepId.CreateLakehouse,
                validator: isRequiredValidator(),
              })}
              useNestedLabel
              form={context.form}
            />
            {(!hasOnlyAccessToMultiTenantNamespace || !hasOnlyAccessToSingleNamespace) && (
              <ListboxField
                name='namespace'
                label='Namespace'
                description='Namespace options for this application. Using a custom namespace guarantees single tenancy.'
                options={eligibleNamespaces.map((namespace) => ({
                  label: namespaceToString(namespace),
                  value: namespaceToString(namespace),
                }))}
                validators={lakehouseFlowFieldValidator({
                  stepId: StepId.CreateLakehouse,
                  validator: isRequiredValidator(),
                })}
                useNestedLabel
                form={context.form}
              />
            )}
          </div>
        </div>
      </ScrollableStepContent>

      <form.Subscribe selector={(state) => [state.canSubmit, state.isPristine]}>
        {([canSubmit, isPristine]) => (
          <StepButtons
            context={context}
            nextBlocked={!canSubmit || isPristine}
            onPrevious={() => {
              context.form.reset()
              setSearchParams({ flow: CreateFlow.SELECT_TYPE })
            }}
          />
        )}
      </form.Subscribe>
    </StepWrapper>
  )
}
