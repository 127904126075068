import clsx from 'clsx'

import Icon from '../icons/icon'
import Button, { ButtonProps } from './button'

export type ButtonPencilProps = ButtonProps & {
  label: string
  kind?: 'primary' | 'secondary' | 'secondary-alt' | 'tertiary'
  iconSize?: string | number
  pencilColor?: string
}

export const ButtonPencil = ({
  label,
  pencilColor = 'text-link',
  kind = 'tertiary',
  iconSize = 'xs',
  className,
  ...rest
}: ButtonPencilProps) => {
  const classes = clsx('!rounded-full !p-1', `!${pencilColor}`, className)
  return (
    <Button kind={kind} className={classes} aria-label={label} {...rest}>
      <Icon name='pencil' size={iconSize} />
    </Button>
  )
}
