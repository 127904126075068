// @ts-strict-ignore
import debug from 'debug'
import { Kind, parse } from 'graphql'

const logger = debug('graphql-utils')

export function getMutationKeysFromGraphqlString(graphqlString: string): string[] {
  logger('graphqlString', graphqlString)

  // Affordance for the way we used to store mutations in blocks
  let patchedGraphqlString = graphqlString
  if (!graphqlString.startsWith('mutation')) {
    patchedGraphqlString = `mutation m { ${graphqlString} }`
  }

  const ast = parse(patchedGraphqlString)
  const keys = []
  ast.definitions.forEach((definition) => {
    if (definition.kind === Kind.OPERATION_DEFINITION) {
      definition.selectionSet.selections.forEach((selection) => {
        if (selection.kind === Kind.FIELD) {
          keys.push(selection.name.value)
        }
      })
    }
  })
  logger(`${keys.length} Mutation key(s): ${keys.join(', ')}`)
  return keys
}

// Could be refactored to be more generic if other use cases arise
export function getIdArgumentFromGraphqlMutationString(graphqlString: string) {
  logger('graphqlString', graphqlString)

  // Affordance for the way we used to store mutations in blocks
  let patchedGraphqlString = graphqlString
  if (!graphqlString.startsWith('mutation')) {
    patchedGraphqlString = `mutation m { ${graphqlString} }`
  }

  const ast = parse(patchedGraphqlString)
  const args: Record<string, any> = {}
  ast.definitions.forEach((definition) => {
    if (definition.kind === Kind.OPERATION_DEFINITION) {
      definition.selectionSet.selections.forEach((selection) => {
        logger('selection', selection)
        if (selection.kind === Kind.FIELD) {
          selection.arguments.forEach((argument) => {
            logger('argument', argument)
            // @ts-ignore string args will have value.value here
            args[argument.name.value] = argument.value?.value
          })
        }
      })
    }
  })
  logger(`${Object.keys(args).length} Argument(s): ${JSON.stringify(args)}`)
  return args?.id
}
