import { useEffect } from 'react'
import { NavigateOptions, useNavigate } from 'react-router'
import { VendiaFormApi } from 'src/components/fields/form'

export const useDelayedNavigate = (
  form: VendiaFormApi<any>,
  path: string,
  options?: NavigateOptions,
  delay: number = 6000,
) => {
  const navigate = useNavigate()
  const isSubmitted = form.useStore((s) => s.isSubmitted)
  useEffect(() => {
    if (isSubmitted) {
      const timeout = setTimeout(() => {
        navigate(path, options)
      }, delay)

      return () => clearTimeout(timeout)
    }
  }, [isSubmitted, path, options, delay])
}
