import clsx from 'clsx'
import { useContext, useEffect, useState } from 'react'
import Icon from 'src/components/icons/icon'
import { status, usePendingTransactions } from 'src/utils/pending-transactions'

import { UploadFileStatusContext } from './upload-file-status-context'

// Quick hack because our usual icon is red and fill override doesn't work - this is a temporary design so we'll change it
const WhiteErrorIcon = () => (
  <Icon name='white-error-icon' size='s'>
    <svg width='24' height='24' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12Z'
          fill='white'
        />
        <path
          d='M3.48444 3.48452C3.51927 3.4496 3.56065 3.42189 3.60621 3.40298C3.65177 3.38408 3.70061 3.37435 3.74994 3.37435C3.79926 3.37435 3.8481 3.38408 3.89366 3.40298C3.93922 3.42189 3.9806 3.4496 4.01544 3.48452L5.99994 5.46977L7.98444 3.48452C8.0193 3.44965 8.0607 3.42199 8.10625 3.40313C8.15181 3.38426 8.20063 3.37454 8.24994 3.37454C8.29925 3.37454 8.34807 3.38426 8.39363 3.40313C8.43918 3.42199 8.48057 3.44965 8.51544 3.48452C8.5503 3.51938 8.57796 3.56078 8.59683 3.60633C8.6157 3.65188 8.62541 3.70071 8.62541 3.75002C8.62541 3.79933 8.6157 3.84815 8.59683 3.89371C8.57796 3.93926 8.5503 3.98065 8.51544 4.01552L6.53019 6.00002L8.51544 7.98452C8.5503 8.01938 8.57796 8.06078 8.59683 8.10633C8.6157 8.15188 8.62541 8.20071 8.62541 8.25002C8.62541 8.29933 8.6157 8.34815 8.59683 8.39371C8.57796 8.43926 8.5503 8.48065 8.51544 8.51552C8.48057 8.55038 8.43918 8.57804 8.39363 8.59691C8.34807 8.61578 8.29925 8.62549 8.24994 8.62549C8.20063 8.62549 8.15181 8.61578 8.10625 8.59691C8.0607 8.57804 8.0193 8.55038 7.98444 8.51552L5.99994 6.53027L4.01544 8.51552C3.98057 8.55038 3.93918 8.57804 3.89363 8.59691C3.84807 8.61578 3.79925 8.62549 3.74994 8.62549C3.70063 8.62549 3.6518 8.61578 3.60625 8.59691C3.5607 8.57804 3.5193 8.55038 3.48444 8.51552C3.44957 8.48065 3.42191 8.43926 3.40305 8.39371C3.38418 8.34815 3.37446 8.29933 3.37446 8.25002C3.37446 8.20071 3.38418 8.15188 3.40305 8.10633C3.42191 8.06078 3.44957 8.01938 3.48444 7.98452L5.46969 6.00002L3.48444 4.01552C3.44952 3.98068 3.42181 3.9393 3.4029 3.89374C3.384 3.84818 3.37427 3.79934 3.37427 3.75002C3.37427 3.70069 3.384 3.65185 3.4029 3.60629C3.42181 3.56073 3.44952 3.51935 3.48444 3.48452Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </Icon>
)

// TODO - deleted transaction ids will instantly disappear from the UI rather than fade out
//  if there's more than one shown in the UI (only the entire widget has nice fade in/out, not each individual transaction)
const TransactionNotifications = () => {
  const { pendingTransactions } = usePendingTransactions()
  const { uploadingFiles } = useContext(UploadFileStatusContext)

  // Delay hiding the notification for a couple seconds after the last txId is resolved
  // But cancel that timeout and set visible again if another unresolved txId is added
  const shouldHide = !Object.values(pendingTransactions).find((tx) => tx.status === status.PENDING)
  const [visible, setVisible] = useState(false)
  const [tid, setTid] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    let newTid: NodeJS.Timeout
    if (shouldHide && visible) {
      newTid = setTimeout(() => {
        setVisible(false)
      }, 2000)
      setTid(newTid)
    } else if (!shouldHide && !visible) {
      clearTimeout(tid!)
      setVisible(true)
    }
    return () => {
      clearTimeout(newTid)
      clearTimeout(tid!)
    }
  }, [visible, shouldHide])

  const classes = clsx(
    'bg-primaryCore fixed bottom-12 right-10 rounded-md p-4 pb-5 text-white transition duration-1000',
    'flex flex-col gap-3',
    visible && 'translate-y-2 opacity-0',
  )

  if (Object.keys(pendingTransactions).length === 0 || uploadingFiles?.length > 0) {
    return null
  }
  return (
    <div className={classes} data-testid={'transaction-notifications'}>
      <div className={'px-2 font-bold text-white'}>Pending Transactions</div>
      {Object.entries(pendingTransactions)
        .reverse()
        .map(([txId, txInfo]) => (
          <div key={txId} className={'flex items-center justify-between gap-3'}>
            <div className={'pl-2 text-xs'}>
              <div className='font-bold'>{txInfo.displayText}</div>
              <div>{txId}</div>
            </div>
            <div className='w-6'>
              {txInfo.status === status.PENDING && <Icon isSpinning name='refresh' size={'xs'} className='ml-1' />}
              {txInfo.status === status.RESOLVED_WITH_TIMEOUT && (
                <Icon name='search-not-found' fill='white' size={'xs'} />
              )}
              {txInfo.status === status.RESOLVED_WITH_ERROR && <WhiteErrorIcon />}
              {txInfo.status === status.RESOLVED && <Icon name='check-circle' size={'s'} />}
            </div>
          </div>
        ))}
    </div>
  )
}

export default TransactionNotifications
