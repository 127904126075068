import { useField } from '@tanstack/react-form'
import debug from 'debug'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Balancer from 'react-wrap-balancer'
import Button from 'src/components/buttons/button'
import { VendiaFormApi } from 'src/components/fields/form'
import ListboxField from 'src/components/fields/listbox.field'
import TextField from 'src/components/fields/text.field'
import TextAreaField from 'src/components/fields/text-area.field'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { CancelButton, NextButton, PreviousButton } from 'src/components/flows/step-buttons'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepContentHeader } from 'src/components/flows/step-header'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent, StepComponentProps } from 'src/components/flows/types'
import Loader from 'src/components/loaders/page-loader'
import { PaywallModal } from 'src/components/modals/paywall-modal'
import { AWS_REGIONS, AZURE_REGIONS } from 'src/utils/csp/regions'
import useGetCurrentVendiaUserQuery from 'src/utils/hooks/use-current-vendia-user-query'
import { useGetOrg } from 'src/utils/hooks/use-get-org'
import useListUnis from 'src/utils/hooks/use-list-unis'
import { getOwnedNodeCount, Tier, TRIAL_NODE_LIMIT, TRIAL_TIERS } from 'src/utils/subscription'

import { UniCreateFormValues } from './standard.flow'

const logger = debug('app:add-nodes')

export const NODE_NAME_PATTERN = /^[a-zA-Z][a-zA-Z0-9-]{0,1023}$/

// TODO: Select closest region by default with Geolocation API?
// One option is to match against https://github.com/turnkeylinux/aws-datacenters/blob/a7106906e60969e917e555fd770454f72c78bb75/output/countries.index
// Alternatively, deploy and invoke a Lambda@Edge and get the region from that, or use https://api.cloudping.co/averages

export const DEFAULT_CSP = 'aws'
const AVAILABLE_CSPS = [
  { value: 'aws', label: 'AWS' },
  { value: 'azure', label: 'Azure (Preview)' },
]

const numbersToWords = {
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
}

export const DEFAULT_REGION: Record<string, string> = {
  aws: 'us-west-2',
  azure: 'westus2',
}

const AVAILABLE_REGIONS: Record<string, any> = {
  aws: AWS_REGIONS,
  azure: AZURE_REGIONS,
}

export enum PaywallStatus {
  NONE,
  SHOW_NODE_COUNT,
  SHOW_CROSS_CLOUD,
}

function getNodeNameFromOrgName(orgName: string) {
  // replace spaces with dashes, remove all other nonconforming characters
  return orgName
    ?.trim()
    .replaceAll(' ', '-')
    .replaceAll(/[^a-zA-Z0-9-]/g, '')
}

export const StepAddNodes: StepComponent<UniCreateFormValues> = ({ context }) => {
  const form = context.form
  const navigate = useNavigate()
  const uniJoinMode = form.getFieldValue('uniJoinMode')

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <StepAddNodesContent uniJoinMode={uniJoinMode} form={form} />
      </ScrollableStepContent>
      <StepButtonsWrapper>
        <div className='flex gap-4'>
          <PreviousButton onClick={() => context.goPrevious()} />
          <CancelButton onClick={() => navigate('/')} />
        </div>
        <NextButton onClick={() => context.goNext()} />
      </StepButtonsWrapper>
    </StepWrapper>
  )
}

type StepAddNodesContentProps = {
  uniJoinMode?: boolean
  form: VendiaFormApi<any>
}
// Used here and by Uni join page
export const StepAddNodesContent = ({ uniJoinMode = false, form }: StepAddNodesContentProps) => {
  const { id: uniName } = useParams()

  const { state, pushValue, removeValue } = useField({ name: 'nodes', form, mode: 'array' })

  const { listUnisQuery } = useListUnis()
  const getOrg = useGetOrg()
  const orgName = getOrg?.data?.getOrganization?.name
  const { data: unis } = listUnisQuery
  const { getCurrentVendiaUserQuery } = useGetCurrentVendiaUserQuery()
  const subscription = getOrg?.data?.getOrganization?.subscription
  const userId = getCurrentVendiaUserQuery?.data?.getUser?.userId

  const handleCloudProviderChange = (provider: string, nodeIndex: number) => {
    /* Set default value if provider changed */
    const defaultRegion = DEFAULT_REGION[provider]
    logger(`Setting default region to ${defaultRegion} for node ${nodeIndex}`)
    // Let new options render first, then select the default
    setTimeout(() => {
      form.setFieldValue(`nodes.${nodeIndex}.region`, defaultRegion)
    }, 0)
  }

  function addNode(name = '') {
    pushValue({
      name,
      csp: DEFAULT_CSP,
      region: DEFAULT_REGION[DEFAULT_CSP],
      description: '',
    })
  }
  // Minor hack - we add a node on first render - checking fields.length
  // prevents rendering any content on first render
  //  so user doesn't see [ + Add Node ] button jumping around
  useEffect(() => {
    if (!state.value || state.value?.length === 0) {
      addNode(getNodeNameFromOrgName(orgName!) ?? 'NodeOne')
    }
  }, [orgName])

  const [showPaywall, setShowPaywall] = useState<PaywallStatus>(PaywallStatus.NONE)
  let paywallMessage = null
  if (showPaywall === PaywallStatus.SHOW_NODE_COUNT) {
    paywallMessage = (
      <div className='flex flex-col gap-4'>
        <p>{`Can't add any more nodes — the maximum node count for the free trial is ${TRIAL_NODE_LIMIT}.`}</p>
        <p>Upgrade your account to create another Universal Application or delete unused Unis/nodes.</p>
      </div>
    )
  }
  if (showPaywall === PaywallStatus.SHOW_CROSS_CLOUD) {
    paywallMessage = (
      <div className='flex flex-col gap-4'>
        <p>All nodes must be deployed to the same cloud service provider in free trial accounts.</p>
        <p>Upgrade your account to Enterprise to enable cross-cloud Unis.</p>
      </div>
    )
  }

  if (!unis || !userId) {
    return (
      <div className='grid place-items-center'>
        <Loader />
      </div>
    )
  }

  return (
    <div className='flex w-full flex-1 flex-col items-center p-4 lg:px-8'>
      {uniJoinMode ? (
        <div className='text-neutral-8 mb-4 grid h-1/6 max-w-4xl place-content-center text-center text-xl font-normal'>
          <div>
            <Balancer>
              You've been invited to join the {uniName} Uni! Adjust the configuration below if needed and then click the
              <strong> Accept invite and join Uni</strong> button.
            </Balancer>
          </div>
        </div>
      ) : (
        <>
          <StepContentHeader
            className='max-w-4xl'
            title={'Set up your node'}
            description={
              <span>
                Next, add your organization's node and location details.
                <p className='mt-2'>
                  Each organization added to the netowrk will be represented by a unique node and connected via a shared
                  data model. For more information on nodes,{' '}
                  <Button kind={'link'} href='https://docs.vendia.com/uni-creation/' target='_blank'>
                    please refer to this resource
                  </Button>
                </p>
                <p className='mt-2'>
                  Please review and update the following information. Keep in mind that node settings cannot be changed
                  following initial configuration.
                </p>
              </span>
            }
          />
        </>
      )}
      {state.value?.length > 0 && (
        <div className={'flex h-5/6 w-full max-w-4xl flex-col gap-8'}>
          {state.value.map((node: any, index: number) => {
            return (
              <div key={index}>
                <div className='flex flex-col gap-4'>
                  <TextField
                    name={`nodes.${index}.name`}
                    defaultValue={node.name}
                    label='Node name'
                    description={'Enter a unique node name without spaces or special characters.'}
                    placeholder='my-new-node'
                    form={form}
                    data-testid='node-name-input'
                    validators={{
                      onBlur: ({ value }: any) => {
                        if (!value) {
                          return 'This field is required.'
                        }
                        if (!NODE_NAME_PATTERN.test(value)) {
                          return 'Name may only include numbers, letters, and dashes, and must start with a letter.'
                        }

                        const nodesWithCurrentValueAsName = state.value
                          ?.map((node: any) => node?.name)
                          .filter((name: string) => name === value)

                        if (nodesWithCurrentValueAsName?.length > 1) {
                          return 'Node name must be unique.'
                        }
                      },
                    }}
                  />
                  <ListboxField
                    name={`nodes.${index}.csp`}
                    defaultValue={node.csp}
                    label='Cloud Service Provider'
                    description={'The cloud environment used to host your node on Vendia.'}
                    form={form}
                    data-testid={'node-csp-select'}
                    options={AVAILABLE_CSPS}
                    validators={{
                      onChange: ({ value }: any) => {
                        if (!value) {
                          return 'This field is required.'
                        }
                        if (value === 'azure') {
                          return 'Azure Nodes are not currently supported for your account plan. Please contact support@vendia.com to enroll in the public preview for Azure.'
                        }
                      },
                    }}
                    onChange={(value) => handleCloudProviderChange(value!, index)}
                  />
                  <ListboxField
                    name={`nodes.${index}.region`}
                    defaultValue={node.region}
                    label='Node location'
                    description='Select the region where your node will be deployed.'
                    form={form}
                    data-testid={'node-region-select'}
                    options={AVAILABLE_REGIONS[form.state.values.nodes[index].csp || DEFAULT_CSP].map(
                      ({ value, label }: { value: string; label: string }) => ({
                        label: `${value} (${label})`,
                        value: value,
                      }),
                    )}
                  />
                  <TextAreaField
                    name={`nodes.${index}.description`}
                    defaultValue={node.description}
                    label='Node description'
                    description='Optional node description.'
                    placeholder='Description of my-new-node...'
                    maxLength={1000}
                    form={form}
                    data-testid='node-description-input'
                    validators={{
                      onBlur: ({ value }: any) => {
                        if (value?.length > 1000) {
                          return 'Description must be 1000 characters or less.'
                        }
                      },
                    }}
                  />
                </div>
                {form.state.values.nodes.length > 1 && (
                  <div className={'mt-4 flex items-center justify-between'}>
                    <Button kind='secondary' icon='trash' onClick={() => removeValue(index)}>
                      Remove Node
                    </Button>
                  </div>
                )}
              </div>
            )
          })}
          {uniJoinMode ? (
            // Just add a little padding to the bottom of container
            <div className='p-6' />
          ) : (
            <div className='-mt-10 flex w-full max-w-4xl pb-6'>
              <Button
                kind={'link'}
                style={{ marginTop: 20 }}
                onClick={(e) => {
                  if (
                    TRIAL_TIERS.includes(subscription?.tier as Tier) &&
                    getOwnedNodeCount({ unis, userId }) + form.state.values.nodes.length >= TRIAL_NODE_LIMIT
                  ) {
                    setShowPaywall(PaywallStatus.SHOW_NODE_COUNT)
                  } else {
                    const key = (state.value.length + 1) as keyof typeof numbersToWords
                    const next = numbersToWords[key]
                    e.preventDefault()
                    const nameRoot = getNodeNameFromOrgName(orgName!) ?? 'Node'
                    addNode(next ? `${nameRoot}${next}` : '')
                  }
                }}
              >
                Click here to add another node owned by your Organization (this is uncommon)
              </Button>
            </div>
          )}
        </div>
      )}
      <PaywallModal
        isOpen={showPaywall === PaywallStatus.SHOW_NODE_COUNT || showPaywall === PaywallStatus.SHOW_CROSS_CLOUD}
        onClose={() => setShowPaywall(PaywallStatus.NONE)}
        message={paywallMessage}
      />
    </div>
  )
}
