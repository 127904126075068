import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import Icon from '../icons/icon'

// forceOpen and forceMountContent can be used within forms
// forceMountContent to make sure hidden inputs remain in the DOM (set to display: none rather than unmounted)
// forceOpen used with whatever logic is needed to open the collapsible when there are errors to display
export type CollapsibleProps = {
  forceOpen?: boolean
  forceMountContent?: true // TODO: bug in types for Collapsible?
  defaultOpen?: boolean
  children: React.ReactNode
  className?: string
  title: string
  description?: string
  disabled?: boolean
}

const Collapsible = ({
  children,
  className,
  title,
  description,
  disabled,
  defaultOpen = false,
  forceOpen,
  forceMountContent,
}: CollapsibleProps) => {
  const classes = clsx('justify-left mb-4 flex cursor-pointer items-center', className)
  const [internalOpen, setInternalOpen] = useState(forceOpen || defaultOpen)

  // If forceOpen is ever set to true, also update internalOpen so that
  // Collapsible doesn't suddenly close when forceOpen is set to false/undefined
  useEffect(() => {
    if (forceOpen) {
      setInternalOpen(true)
    }
  }, [forceOpen])

  const handleClick = () => {
    if (forceOpen) {
      return
    }

    if (!disabled) {
      setInternalOpen(!internalOpen)
    }
  }

  const open = forceOpen || internalOpen

  return (
    <CollapsiblePrimitive.Root open={open} onOpenChange={() => handleClick()} disabled={disabled}>
      <CollapsiblePrimitive.Trigger asChild>
        <div className={classes}>
          {open ? (
            <Icon className='ml-[-6px]' name='caret-down' size={'s'} />
          ) : (
            <Icon className='ml-[-6px]' name='caret-right' size={'s'} />
          )}
          <div className='ml-[10px] inline-flex text-lg'>
            <span className='mr-2 font-bold'>{title}</span>
            <span className='text-neutral7'>{description}</span>
          </div>
        </div>
      </CollapsiblePrimitive.Trigger>
      <CollapsiblePrimitive.Content
        forceMount={forceMountContent}
        style={forceMountContent && !open ? { display: 'none' } : {}}
      >
        {children}
      </CollapsiblePrimitive.Content>
    </CollapsiblePrimitive.Root>
  )
}

export default Collapsible
