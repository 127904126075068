import { ATTR_FIRST, ATTR_LATEST, ATTR_PREVIOUS, getAttribution } from '@vendia/analytics-attribution'

import dotProp from '../../misc/dot-prop'

let refCache = {}
export async function getRefData(key) {
  if (refCache._type) {
    return key ? dotProp(refCache, key) : refCache
  }
  const localData = getAttribution() || {}

  const info = {
    _type: 'local',
    _first: localData.first,
    local: localData,
  }
  // Set cache
  refCache = info
  return key ? dotProp(info, key) : info
}

function getTime(t) {
  return t ? new Date(t).getTime() : Infinity
}
