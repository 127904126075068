import debug from 'debug'
import Balancer from 'react-wrap-balancer'
import { ScrollableStepContent } from 'src/components/flows/scrollable-step-content'
import { StepButtonsWrapper } from 'src/components/flows/step-buttons-wrapper'
import { StepWrapper } from 'src/components/flows/step-wrapper'
import { StepComponent } from 'src/components/flows/types'
import StatusPill from 'src/components/pills/status-pill'
import { useDelayedNavigate } from 'src/utils/hooks/use-delayed-navigate'

import { buildUniFqn, buildUniNameFromAlias } from '../utils'
import { UniCreateFormValues } from './standard.flow'

const logger = debug('app:uni-confirmation')

export const StepConfirmation: StepComponent<UniCreateFormValues> = ({ context }) => {
  const form = context.form

  const { uniName, uniNamespace, randomSuffix } = form.useStore((s) => s.values)
  const generatedUniName = buildUniNameFromAlias(uniName, randomSuffix)
  const uniFqn = buildUniFqn(generatedUniName, uniNamespace)
  useDelayedNavigate(form, `/uni/${uniFqn.includes('unis.vendia.net') ? generatedUniName : uniFqn}`)

  return (
    <StepWrapper>
      <ScrollableStepContent>
        <div className='mt-16 flex w-full flex-col items-center p-4 lg:px-8'>
          <div className='flex flex-col items-center text-center'>
            <div className='text-3xl font-bold'>Success! Your new Uni is deploying! 🚀</div>
            <p className='mt-12 max-w-md text-center'>
              <Balancer>
                Your Uni will display this <StatusPill status={'DEPLOYING'} /> badge for the next 5 to 10 minutes after
                which it will be ready to use.
              </Balancer>
            </p>
            <p className='mt-12 animate-pulse text-center text-sm text-gray-800'>
              Redirecting to Uni page momentarily...
            </p>
          </div>
        </div>
      </ScrollableStepContent>
      <StepButtonsWrapper>{null}</StepButtonsWrapper>
    </StepWrapper>
  )
}
