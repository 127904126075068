// @ts-strict-ignore
import { useEffect, useState } from 'react'

function _decodeURI(s) {
  try {
    return decodeURIComponent(s.replace(/\+/g, ' '))
  } catch (e) {
    return s
  }
}

export function parseHash() {
  const hashParams = {} as Record<string, string>
  if (typeof window === 'undefined') {
    return hashParams
  }
  let e
  const r = /([^&=]+)=?([^&]*)/g
  const q = window.location.hash.substring(1)

  while ((e = r.exec(q))) {
    hashParams[_decodeURI(e[1])] = _decodeURI(e[2])
  }

  if (hashParams.email) {
    // If email, fix + signs in email. E.g. david+dev@vendia.com
    hashParams.email = hashParams.email.replace(/\s/g, '+')
  }

  return hashParams
}

export default function useHashParam() {
  const initialHash = getHashValue()
  const [hashParams, setInnerValue] = useState(initialHash)
  useEffect(() => {
    const handleHashChange = () => {
      setInnerValue(getHashValue())
    }
    handleHashChange()
    window.addEventListener('hashchange', handleHashChange)
    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [])
  return [hashParams, setInnerValue]
}

function getHashValue() {
  const hashes = parseHash()
  return {
    ...decode(hashes),
    ...{
      _: hashes,
    },
  }
}

const DECODE = ['email']

function decode(obj: any) {
  return Object.keys(obj).reduce((acc, curr) => {
    if (DECODE.includes(curr)) {
      acc[curr] = safeDecode(obj[curr])
      return acc
    }
    acc[curr] = obj[curr]
    return acc
  }, {} as any)
}

function safeDecode(value: string) {
  try {
    return window.atob(value)
  } catch (err) {
    return value
  }
}
