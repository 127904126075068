import { ComponentProps } from 'react'
import Pill, { PILL_KINDS, PillKind, PillProps } from 'src/components/pills/pill'

import HoverableTooltip from '../messages/hoverable-tooltip'

const STATUS_MAP = {
  RUNNING: {
    text: 'Running',
    kind: PILL_KINDS.success,
  },
  PENDING_REGISTRATION: {
    text: 'Deploying',
    kind: PILL_KINDS.pending,
  },
  DEPLOYING: {
    text: 'Deploying',
    kind: PILL_KINDS.pending,
  },
  ADDING: {
    text: 'Adding node',
    kind: PILL_KINDS.pending,
  },
  UPDATING: {
    text: 'Updating',
    kind: PILL_KINDS.pending,
  },
  ERROR: {
    text: 'Error',
    kind: PILL_KINDS.warning,
  },
  UNKNOWN: {
    text: 'Unknown',
    kind: PILL_KINDS.warning,
  },
  DESTROYING: {
    text: 'Destroying',
    kind: PILL_KINDS.warning,
  },
  DESTROYED: {
    text: 'Deleted',
    kind: PILL_KINDS.warning,
  },
  STOPPED: {
    text: 'Stopped',
    kind: PILL_KINDS.warning,
  },
  STOP_REQUESTED: {
    text: 'Stop requested',
    kind: PILL_KINDS.pending,
  },
  ARCHIVED: {
    text: 'Archived',
    kind: PILL_KINDS.ARCHIVED,
  },
  BACKFILLING: {
    text: 'Backfilling',
    kind: PILL_KINDS.pending,
  },
}

export type StatusPillProps = {
  status: keyof typeof STATUS_MAP
}

const StatusPill = ({ status, ...rest }: StatusPillProps & PillProps) => (
  <Pill kind={STATUS_MAP[status].kind as PillKind} text={STATUS_MAP[status].text} {...rest} />
)

const MiniStatusBadgeClassesMap = {
  [PILL_KINDS.success]: 'bg-success-2 border-success-6',
  [PILL_KINDS.pending]: 'bg-warning-2 border-warning-6',
  [PILL_KINDS.warning]: 'bg-error-2 border-error-6',
  [PILL_KINDS.ARCHIVED]: 'bg-gray-200 border-gray-400',
}

export const MiniStatusBadge = ({ status, ...rest }: StatusPillProps & ComponentProps<'div'>) => {
  const statusData = STATUS_MAP?.[status]
  return (
    <HoverableTooltip side='top' content={statusData?.text}>
      <div
        className={`h-4 w-4 rounded-full border-2 ${MiniStatusBadgeClassesMap[statusData?.kind]}`}
        {...rest}
        aria-label={statusData?.text}
      />
    </HoverableTooltip>
  )
}

export default StatusPill
