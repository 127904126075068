const schema = `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "$id": "http://vendia.net/schemas/demos/track-and-trace.json",
  "title": "Track and Trace",
  "description": "Track and trace shipments among a manufacturer, shipper, and retailer",
  "x-vendia-indexes": {},
  "x-vendia-acls": {
    "InventoryAcl": {
      "type": "Inventory"
    },
    "ShipmentAcl": {
      "type": "Shipment"
    },
    "OrderAcl": {
      "type": "Order"
    },
    "WarehouseAcl": {
      "type": "Warehouse"
    }
  },
  "type": "object",
  "properties": {
    "Inventory": {
      "description": "Inventory information",
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "name": {
            "description": "Product name",
            "type": "string"
          },
          "sku": {
            "description": "Product SKU",
            "type": "string"
          },
          "warehouse": {
            "description": "Manufacturer warehouse code",
            "type": "string"
          },
          "availableInventory": {
            "description": "Available inventory",
            "type": "integer"
          }
        }
      }
    },
    "Shipment": {
      "description": "Shipment information",
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "originWarehouse": {
            "description": "Shipment origin",
            "type": "string"
          },
          "destinationWarehouse": {
            "description": "Shipment destination",
            "type": "string"
          },
          "orderId": {
            "description": "Order ID",
            "type": "string"
          },
          "created": {
            "description": "When the order was placed",
            "type": "string",
            "format": "date-time"
          },
          "lastUpdated": {
            "description": "When the order was last updated",
            "type": "string",
            "format": "date-time"
          },
          "location": {
            "description": "Current Lat/long of the shipment",
            "type": "array",
            "items": {
              "type": "number"
            },
            "minItems": 2
          },
          "delivered": {
            "description": "Delivery status",
            "type": "boolean"
          }
        }
      }
    },
    "Order": {
      "description": "Order information",
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "orderId": {
            "description": "Order ID",
            "type": "string"
          },
          "items": {
            "type": "array",
            "items": {
              "type": "object",
              "properties": {
                "sku": {
                  "description": "Product SKU in order",
                  "type": "string"
                },
                "quantity": {
                  "description": "Quantity of product in order",
                  "type": "integer"
                }
              }
            }
          },
          "manufacturerWarehouseCode": {
            "description": "Manufacturer warehouse that holds the inventory",
            "type": "string"
          },
          "manufacturerWarehouseLocation": {
            "description": "Lat/long of manufacturer warehouse",
            "type": "array",
            "items": {
              "type": "number"
            },
            "minItems": 2
          },
          "retailerWarehouseCode": {
            "description": "Retailer warehouse that placed the order",
            "type": "string"
          },
          "retailerWarehouseLocation": {
            "description": "Lat/long of retailer warehouse",
            "type": "array",
            "items": {
              "type": "number"
            },
            "minItems": 2
          },
          "delivered": {
            "description": "Order status",
            "type": "boolean"
          },
          "created": {
            "description": "When order was placed",
            "type": "string",
            "format": "date-time"
          },
          "updated": {
            "description": "When order was last updated",
            "type": "string",
            "format": "date-time"
          }
        }
      }
    },
    "Warehouse": {
      "description": "Warehouse information",
      "type": "array",
      "items": {
        "type": "object",
        "properties": {
          "companyName": {
            "description": "Company name",
            "type": "string"
          },
          "code": {
            "description": "Warehouse code",
            "type": "string"
          },
          "street1": {
            "description": "Warehouse Street Address",
            "type": "string"
          },
          "street2": {
            "description": "Warehouse Warehouse Street Address Continued",
            "type": "string"
          },
          "city": {
            "description": "Warehouse City",
            "type": "string"
          },
          "state": {
            "description": "Warehouse State",
            "type": "string"
          },
          "postalCode": {
            "description": "Warehouse Postal Code",
            "type": "string"
          },
          "country": {
            "description": "Warehouse Country Code",
            "type": "string"
          },
          "phone": {
            "description": "Warehouse Phone Number",
            "type": "string"
          },
          "fax": {
            "description": "Warehouse Fax Number",
            "type": "string"
          },
          "created": {
            "description": "When the Warehouse record was created",
            "type": "string",
            "format": "date-time"
          },
          "updated": {
            "description": "When Warehouse record was last updated",
            "type": "string",
            "format": "date-time"
          }
        }
      }
    }
  } 
}
`

export default {
  schema,
}
