import { useForm } from '@tanstack/react-form'
import Button from 'src/components/buttons/button'
import Drawer from 'src/components/containers/drawer'
import Form from 'src/components/fields/form'
import MonacoEditorField from 'src/components/fields/monaco-editor.field'
import Icon from 'src/components/icons/icon'
import notify from 'src/utils/notify'

export type ImportGraphQLDrawerProps = {
  isOpen: boolean
  onClose: () => void
  onSchemaChange: (schema: any) => void
}

const ImportGraphQLDrawer = ({ isOpen, onClose, onSchemaChange }: ImportGraphQLDrawerProps) => {
  const form = useForm({
    defaultValues: {
      schema: `type Todo {
  id: ID!
  message: String!
}

type Query {
  getTodo(id: ID!): Todo
}
`,
    },
    onSubmit: async ({ value }) => {
      try {
        const jsonSchemaResponse = await fetch(`/api/graphql-to-json`, {
          method: 'POST',
          body: JSON.stringify({
            schema: value.schema,
          }),
        })
        const jsonSchema = await jsonSchemaResponse.json()
        onSchemaChange(jsonSchema)
      } catch (e) {
        console.warn('Unable to convert GraphQL schema to JSON', e)
        notify.error('Unable to convert GraphQL schema to JSON')
      }
    },
  })

  return (
    <Drawer open={isOpen} onClose={onClose} direction='right' className='w-screen max-w-2xl'>
      <div className='h-full'>
        <header className='border-neutral-3 bg-neutral-0 border-b p-6'>
          <h2 className='m-0 text-2xl font-semibold'>Import GraphQL Schema</h2>
        </header>
        <Form form={form} className='flex h-full flex-col justify-between'>
          <div className='p-4'>
            <MonacoEditorField
              id='graphQLSchema'
              form={form}
              name='schema'
              language='graphql'
              minHeight={600}
              label='Your GraphQL Schema'
              description="Paste in your GraphQL Schema and we'll convert it to a JSON Schema for your Uni. Note: custom queries and mutations will not be converted."
              validators={{
                onBlur: ({ value }: any) => {
                  if (!value) {
                    return 'This field is required'
                  }
                },
              }}
            />
          </div>
          <div className='border-neutral-3 sticky bottom-0 right-0 flex items-center justify-end gap-2 border-t bg-white p-6 drop-shadow'>
            <Button kind='secondary' type='button' onClick={onClose}>
              Cancel
            </Button>
            <Button kind='primary' type='submit' disabled={form.state.isSubmitting}>
              {form.state.isSubmitting && <Icon isSpinning name='refresh' size={'xxs'} className='mr-1' />}
              <span>Import</span>
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  )
}

export default ImportGraphQLDrawer
